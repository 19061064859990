import { CALL_API } from "../api.middleware";
import {
    CLEAR_TOKEN,
    CREATE_MERCHANT,
    CREATE_MERCHANT_SUCCESS,
    FORGOT_PASSWORD_LOGIN,
    FORGOT_PASSWORD_LOGIN_SUCCESS,
    FORGOT_PASSWORD_VALIDATE_OTP,
    FORGOT_PASSWORD_VALIDATE_OTP_SUCCESS,
    GET_BUSSINESS_CATEGORY,
    GET_BUSSINESS_CATEGORY_SUCCESS,
    LOGIN,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    MERCHANT_REGISTRATION,
    MERCHANT_REGISTRATION_SUCCESS,
    OTP_LOGIN,
    OTP_LOGIN_SUCCESS,
    REMOVE_SCROLL_INTO_VIEW,
    SCROLL_INTO_VIEW,
    SET_PASSWORD,
    SET_PASSWORD_SUCCESS,
    SHOW_TOAST,
    SIGN_UP,
    SIGN_UP_SUCCESS,
    STOP_TOAST,
    TERMS_AND_CONDITIONS,
    TERMS_AND_CONDITIONS_SUCCESS,
    VALIDATE_CODE,
    VALIDATE_CODE_SUCCESS,
    VALIDATE_EXPERIAN,
    VALIDATE_EXPERIAN_SUCCESS,
} from "../types/auth";

export const onLogin = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/login`,
                showLoader: true,
                method: "POST",
                types: [LOGIN, LOGIN_SUCCESS],
                body: { ...body, role: "owner" },
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};

export const onLogout = () => {
    return { type: LOGOUT_SUCCESS };
};

export const createMerchant = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/merchant/create_merchant`,
                showLoader: true,
                method: "POST",
                types: [CREATE_MERCHANT, CREATE_MERCHANT_SUCCESS],
                body,
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};
export const getBusinessCategories = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/categories`,
        showLoader: true,
        method: "GET",
        types: [GET_BUSSINESS_CATEGORY, GET_BUSSINESS_CATEGORY_SUCCESS],
        // showToast: true,
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};
export const signUp = (body) => async (dispatch) => {
    const business = {
        name: body.businessName,
        ein: body.ein,
        category: {
            id: body.category,
        },
        phone: body.mobile,
        owner: {
            firstName: body.firstName,
            lastName: body.lastName,
            dob: body.dob,
            email: body.email,
            ssn: body.ssn,
        },
        address: {
            address1: body.address,
            address2: body.suite,
            city: body.city,
            state: body.state,
            zipCode: body.zipCode,
            country: body.country,
        },
    };

    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/create`,
                showLoader: true,
                method: "POST",
                types: [SIGN_UP, SIGN_UP_SUCCESS],
                body: business,
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};

export const merchantSignUp = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/signup`,
                showLoader: true,
                method: "POST",
                types: [SIGN_UP, SIGN_UP_SUCCESS],
                body: body,
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};

export const merchantSignIn = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/signin`,
                showLoader: true,
                method: "POST",
                types: [OTP_LOGIN, OTP_LOGIN_SUCCESS],
                body: body,
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};



export const forgotPasswordSignIn = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/forgot-password-signin`,
                showLoader: true,
                method: "POST",
                types: [FORGOT_PASSWORD_LOGIN, FORGOT_PASSWORD_LOGIN_SUCCESS],
                body: body,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};


export const forgotPasswordValidateOTP = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/forgot-password-validate-otp`,
                showLoader: true,
                method: "POST",
                types: [FORGOT_PASSWORD_VALIDATE_OTP, FORGOT_PASSWORD_VALIDATE_OTP_SUCCESS],
                body: body,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};




export const validateCode = (otp, otpToken) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/validate-otp`,
                showLoader: true,
                method: "POST",
                types: [VALIDATE_CODE, VALIDATE_CODE_SUCCESS],
                body: {
                    otp,
                    otpToken,
                    loginRole: 'owner'
                },
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};

export const validateExperian = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/experian-verify`,
                showLoader: true,
                method: "POST",
                types: [VALIDATE_EXPERIAN, VALIDATE_EXPERIAN_SUCCESS],
                body,
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};

export const merchantRegister = (token, body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/registration`,
                showLoader: true,
                method: "POST",
                types: [MERCHANT_REGISTRATION, MERCHANT_REGISTRATION_SUCCESS],
                body: body,
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};

export const acceptTnC = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/registration/continue`,
                showLoader: true,
                method: "POST",
                types: [TERMS_AND_CONDITIONS, TERMS_AND_CONDITIONS_SUCCESS],
                body: body,
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};

export const setPassForMerchant = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/registration/continue`,
                showLoader: true,
                method: "POST",
                types: [SET_PASSWORD, SET_PASSWORD_SUCCESS],
                body: body,
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};

export const forgotPassword = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/forgot-password`,
                showLoader: true,
                method: "POST",
                // types: [TERMS_AND_CONDITIONS, TERMS_AND_CONDITIONS_SUCCESS],
                body: body,
                // showToast: true,
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};

export const scrollToView = () => {
    return {
        type: SCROLL_INTO_VIEW,
    };
};

export const removeScrollToView = () => {
    return {
        type: REMOVE_SCROLL_INTO_VIEW,
    };
};

export const sendEmail = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/marketing/support/email`,
                showLoader: true,
                method: "POST",
                body,
                showToast: true,
                toastMessage: 'Email sent'
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};


export const resetPassword = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/businesses/businesses/reset-password`,
                showLoader: true,
                method: "POST",
                body: body,
                showToast: true,
                toastMessage: "Password reset successfully"
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};


export const showToast = (message) => {
    return {
        type: SHOW_TOAST,
        message
    }
}

export const stopToast = () => {
    return {
        type: STOP_TOAST,
    }
}



export const downloadAppSMS = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/consumers/consumers/download-app/sms`,
                showLoader: true,
                method: "POST",
                body: body,
                showToast: true,
                toastMessage: 'Message sent'
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};




export const bookDemo = (body) => async (dispatch) => {
    try {
        return await dispatch({
            [CALL_API]: {
                url: `/marketing/support/email`,
                showLoader: true,
                method: "POST",
                body: body,
                showToast: true,
                toastMessage: 'Message sent'
            },
        });
    } catch (error) {
        console.error(error)
        throw error
    }
};


export const clearToken = () => {
    return {
        type: CLEAR_TOKEN,
    }
  }
