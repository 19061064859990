import { 
    Typography, 
    Button,
    AppBar,
    Toolbar,
    TextField,
    useMediaQuery,
    FormHelperText,
 } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { COLORS } from "../../constants";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { clearToken, forgotPasswordSignIn, forgotPasswordValidateOTP, merchantSignIn, validateCode } from "../../store/actions/auth";
import { useHistory, useLocation } from "react-router";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { getBusiness } from "../../store/actions/rewards";
import OnBoardingHeader from "../../views/landing/onBoardingHeader";


const accountTxt = {
    fontSize: 16,
    color: '#333333',
    textAlign: 'center',
    marginTop: 30,
    fontFamily: "PlusJakartaSans-Regular"
};
const spanTxt = {
    color: COLORS.juma_theme_clr,
    fontSize: 16,
    //fontFamily: "PlusJakartaSans-Bold",
    fontWeight:600,
    marginLeft: 6
};
const emailImg = {
    width: 60,
    display: 'block',
    margin: 'auto'
};
const emailTxt = {
    fontSize: 14,
    fontFamily: "PlusJakartaSans-Regular",
    color: '#282828',
    width: '80%',
    textAlign: 'center',
    margin: '20px auto 40px'
};
const otpTextFeild = {
    width: 45,
    marginRight: 5,
    marginLeft: 5,
};
const validationSchema = yup.object({
    otp: yup.array().of(yup.number()).test('OTP', function(value, context){
      console.log(context)
      const {createError, parent, path} = context
      const nonEmptyField = parent?.otp?.reduce((acc, cur) => {
          if(cur !== undefined) {
            acc += 1
          }
          return acc
      }, 0)
      console.log(nonEmptyField)
      if(nonEmptyField < 6){
        return createError({
          message: 'OTP is required',
          path,
        }); 
      }
      return true;
    })
});

let minutesToExpire = (1 * 60 * 1000)

export default function VerifyEmail() {
    const isXsMobile = useMediaQuery("(max-width : 360px)");
    const isMobile = useMediaQuery("(max-width : 480px)");
    const isTablet = useMediaQuery("(max-width : 768px)");
    const isBigTablet = useMediaQuery("(max-width : 1180px)");

    const signUpContainer = {
      backgroundColor : isMobile || isXsMobile || isTablet ? "#FFFFFF" : "#F4F4F4",
      width: "100%",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: '100vh',
      overflow: 'auto'
  }; 

    const formContainer = {
      backgroundColor: COLORS.default_white,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      boxSizing: "border-box",
      width: isMobile || isXsMobile ? "94%" : isTablet || isBigTablet ? "60%" : "42%",
      maxWidth: 520,
      margin: " 30px auto",
      padding: isMobile || isXsMobile ? "20px 12px" : isTablet || isBigTablet ? 20 : 40,
    };
    const signUpMerchant={
        marginTop: isMobile || isXsMobile || isTablet || isBigTablet ? 80 : 120,
        fontSize: isMobile || isXsMobile || isTablet || isBigTablet ? 24 : 32,
        fontWeight: 600,
        fontStyle: "PlusJakartaSans-Bold",
    };
    const emailInputWrapper = {
        margin: isMobile || isXsMobile || isTablet || isBigTablet ? 10 : "10px 30px",
    };
    const btnContinue ={
      width: isMobile || isXsMobile ? "100%" : isTablet || isBigTablet ? "90%" : "80%",
      background: '#1A7CFA',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0605059)',
      borderRadius: 2,
      color: COLORS.default_white,
      margin: '30px auto',
      display: 'block'
  };

    const [timerExpired, setTimerExpired] = useState(false)
    
    const dispatch = useDispatch();
    const history = useHistory();
    const {state} = useLocation();
    const [otpToken, setOtpToken] = useState(state?.token?.otpToken)
    const [email, setEmail] = useState(state?.email)
    const [action, setAction] = useState(state?.action)

    console.log(otpToken)
    const formik = useFormik({
      initialValues: {
        otp: ["","","","","",""],
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const otpString = values?.otp?.join("");
        if(minutesToExpire !== 0){
        const verifiedEmail = await dispatch(
          (action === 'RESET_PASSWORD') ? 
            forgotPasswordValidateOTP({otp: otpString, otpToken}) : validateCode(otpString, otpToken)
        );

        if(action === 'RESET_PASSWORD'){
          history.replace('reset-password', { token: verifiedEmail?.token })
          return;
        }
        
        switch (verifiedEmail?.status) {
          case "pending-registration-details":
            history.replace("register", { token: verifiedEmail?.token });
            break;
          case "pending-tnc-acceptance":
            history.replace("tandc");
            break;
          case "pending-password-creation":
            history.replace("set-password");
            break;
          case "pending-enigma-verification":
            history.replace("verifying-user");
            break;
          case "enigma-rejected":
            history.replace("declined-user");
            break;

          case "admin-rejected":
            history.replace("declined-user");
            break;

          default:
            history.replace("/portal/admin");
            break;
        }
      }
      },
    });


    const resendOTP = async () => {
      const token = await dispatch(
        (action === 'RESET_PASSWORD') ?
        forgotPasswordSignIn({...email, role: 'owner'}) : merchantSignIn(email)
      );
      if(token?.otpToken){
        setTimerExpired(false);
        setOtpToken(token?.otpToken)
        minutesToExpire = (1 * 60 * 1000)
      }
    }

    const otpRef= useRef([]);
    // console.log(otpRef)
    useEffect(() => {
      otpRef.current[0].focus()
      return () => {
        dispatch(clearToken())
      }
    }, [])

    const onChangeHandler = (value, index) => {
      if (!/^[\d]*$/.test(value)) {
        return;
      }
      if ((value && !formik.values.otp[index].length) || !value) {
        formik.setFieldValue(`otp[${index}]`, value);
      }
  
      if (index < 5 && value) {
        otpRef.current[index + 1].focus();
      }
    }

    const handleKeyPress = (keyCode, index) => {
        if (keyCode === 8) {
            if (!formik.values.otp[index] && index >= 0) {
                otpRef.current[index - 1].focus();
            }
        }
    };

    const hasError=
      formik?.touched?.otp &&
      Boolean(formik?.errors?.otp)
    ;
    const errorText=
      formik?.touched?.otp &&
      formik?.errors?.otp;
    return (
      <div>
        <OnBoardingHeader />
        <div style={signUpContainer} className="createMerchant">
          <Typography style={signUpMerchant}>
            Verify Your Email Account
          </Typography>
          <form onSubmit={formik.handleSubmit} style={formContainer}>
            <div style={emailInputWrapper}>
              <img
                src="/images/verify_email.png"
                style={emailImg}
                alt="verify-email"
              />
              <Typography style={emailTxt}>
                6-digit OTP has been sent to your registered email id.
              </Typography>
              <div className="otpSec">
                <p
                  style={{
                    fontSize: 14,
                    color: "#282828",
                    opacity: 0.5,
                    marginBottom:
                      isMobile || isXsMobile
                        ? 0
                        : isTablet || isBigTablet
                        ? "0px"
                        : "-12px",
                  }}
                >
                  Enter OTP
                </p>
                <div>
                  {formik?.values?.otp?.map((digit, i) => {
                    return (
                      <TextField
                        key={i}
                        size="small"
                        margin="normal"
                        //style={otpTextFeild}
                        id={`otp[${i}]`}
                        name={`otp[${i}]`}
                        className="otpTextField"
                        autoComplete="off"
                        inputRef={ref => (otpRef.current[i] = ref)}
                        onKeyDown={({keyCode}) => handleKeyPress(keyCode, i)}
                        inputProps={{ maxLength: 1, inputMode: "numeric" }}
                        value={digit}
                        onChange={({target: {value}}) => onChangeHandler(value, i)}
                        />
                        );
                      })}
                  </div>
                  {hasError && <FormHelperText style={{color: 'red'}}>{errorText}</FormHelperText>}
                </div>
              {!timerExpired ? (
                <Typography style={accountTxt}>
                  Resend OTP in{" "}
                  <span style={spanTxt}>

                    <Child
                      minutesToExpire={minutesToExpire}
                      setTimerExpired={setTimerExpired}
                    />
                  </span>
                </Typography>
              ) : (
                <Typography style={accountTxt}>
                  {/* OTP expired{" "} */}
                  <Link onClick={resendOTP} className="textUnderline">
                    <span style={spanTxt}>Resend OTP</span>
                  </Link>
                </Typography>
              )}
              <Button
                size="large"
                variant="contained"
                style={btnContinue}
                type="submit"
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
}


const Child = React.memo(function Child({ minutesToExpire, setTimerExpired }) {
  
  return (
    <Countdown
      date={Date.now() + minutesToExpire}
      renderer={({ minutes, seconds }) => {
        return (
          <span>
            {minutes < 10 ? `0${minutes}` : `${minutes}`} :{" "}
            {seconds < 10 ? `0${seconds}` : `${seconds}`}
          </span>
        );
      }}
      onComplete={() => {
        setTimerExpired(true);
        // setMinutesToExpire(0)
        minutesToExpire = 0;
      }}
    ></Countdown>
  );
});