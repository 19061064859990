import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { getConfirmedStatement } from '../store/actions/statement';
import { formatDate, formatTime } from '../utils/commonFunc';

const headers = [
    "Trade Date",
    "Value Date",
    "Payment Id",
    "Description",
    "Type",
    "Amount",
    "Balance",
];

export default function ConfirmedStatement(props) {
    const dispatch = useDispatch();
    const [statementList, setStatementList] = useState([])

    useEffect(() => {
        const fetch = async () => {
            const confirmedStatement = await dispatch(getConfirmedStatement())
            setStatementList(confirmedStatement)
        };
        fetch();
    }, []);
    return (
        <>
            {/* <StatusBar /> */}
            <div>
                <div className='headerText'>Statement</div>
                <Paper sqare className='transactionTable' elevation={0}>
                    <TableContainer component={Paper} className="tableWidth">
                        <Table>
                            <TableHead>
                                <TableRow className="textBold statementTableRow">
                                    {headers.map((header, index) => (
                                        <TableCell>
                                            {header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>{statementList?.map((row, i) => {
                                const { amount, balance, description, paymentId, tradeDate, txnStatus, type, valueDate } = row
                                const _amount = amount?.toString().includes('-') ? `-$${Math.abs(amount).toFixed(2)}` : `$${amount}`
                                const _balance = balance?.toString().includes('-') ? `-$${Math.abs(balance).toFixed(2)}` : `$${balance}`
                                return (
                                    <TableRow key={paymentId} className="statementTableRow">
                                        
                                        <TableCell>{`${formatDate(tradeDate)} ${formatTime(tradeDate)}`}</TableCell>
                                        <TableCell>{`${formatDate(valueDate)} ${formatTime(valueDate)}`}</TableCell>
                                        <TableCell>{paymentId}</TableCell>
                                        <TableCell>{description}</TableCell>
                                        <TableCell>{type}</TableCell>
                                        <TableCell>{`${_amount}`}</TableCell>
                                        <TableCell>{`${_balance}`}</TableCell>
                                    </TableRow>
                                )
                            })

                            }
                            </TableBody>
                        </Table>
                        {(statementList?.length === 0) && <div
                            className='noTransText'
                        >
                            No Confirmed Cash Statement
                        </div>}
                    </TableContainer>
                </Paper>
                <div className="paginationSec">
                    {/* <Pagination count={transactionList?.pages} shape="rounded" defaultPage={parameters?.page} onChange={(event, value) => {
                      dispatch({ type: SET_PARAMETERS, body: { page: value } });
                    }} /> */}
                </div>
            </div>
        </>
    );


}  