import { Toolbar } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import MyDashboardRoutes from "../controllers/dashboardFolder";
import LeftPanel from "../forms/leftnav";
import Header from "../forms/textinput";
import { getBusiness } from "../store/actions/rewards";

const dashboardContainer = {
  width: "100%",
  margin: "auto",
  backgroundColor: "#F4F4F4",
  position: "fixed",
  bottom: 0,
  top: 0,
  //overflow: "scroll",
  overflowY: "auto",
};

export default function Dashboard(props) {
    const { pathname = '' } = props?.location;
    const dispatch = useDispatch()
    useEffect(async() => {
      await dispatch(getBusiness())
    }, [])
    return (
      <div style={dashboardContainer} id="top_header">
        <LeftPanel {...props}></LeftPanel>
        <Toolbar />
        <div className="dashboardMain">
          {pathname === "/login" ? (
            <React.Fragment></React.Fragment>
          ) : (
            <Header {...props} />
          )}
          <div className="dashboardContent">
             <MyDashboardRoutes {...props} />
          </div>
        </div>
      </div>
    );
}
