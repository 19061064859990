import React from 'react';
import {
  Paper,
  Typography,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Box,
  Card,
  CardContent,
  CardActions,
  Divider,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { getStores, disableStaff, disableStore, disableAisle, getAisleToken, unlinkDevice, toggleChat} from '../../store/actions/activateMerchantApp';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from './styles.jsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useRouteMatch } from 'react-router-dom';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import Countdown from "react-countdown";
import CloseIcon from "@material-ui/icons/Close";
import DisableDialogComponent from './DisableDialogComponent';
import StatusBar from '../../controllers/statusBar';
import { showToast, stopToast } from '../../store/actions/auth';

export default function ActivateMerchantApp() {
  const { url } = useRouteMatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const background = location.state && location.state.background;
  const [aisleToken, setAisleToken] = useState('');
  const [minutesToExpire, setMinutesToExpire] = useState(0);
  const [aisleStatus, setAisleStatus] = useState(false);
  const [autoPayment, setAutoPayment] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState('Link');
  const [storeStatus, setStoreStatus] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [aisleId, setAisleId] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDisableDialog, setOpenDisableDialog] = useState(false);
  const [disableState, setDisableState] = useState({switchType: '', params: null});
  const isTablet = useMediaQuery('(max-width:1120px)');
  const storeNameTabletView = useMediaQuery('(max-width: 920px)');
  const partitionTabletView = useMediaQuery('(max-width: 920px)');
  const isMobileView = useMediaQuery('(max-width: 600px)');
  

  const handleClose = () => {
    setOpen(false);
  };


  const stores = useSelector((state) => state.stores);
  const loader = useSelector((state) => state.loader)
  const handleYes = () => {
    if (open.type === 'paymentSwitch') {
      if (autoPayment === false) {
        setAutoPayment(true);
      } else {
        setAutoPayment(false);
      }
    } else if (open.type === 'deviceStatus') {
      if (deviceStatus === 'Linked') {
        setDeviceStatus('Not Linked');
      } else {
        setDeviceStatus('Linked');
      }
    } else if (open.type === 'aisleSwitch') {
      if (aisleStatus === false) {
        setAisleStatus(true);
      } else {
        setAisleStatus(false);
      }
    } else if (open.type === 'storeSwitch') {
      if (storeStatus === false) {
        setStoreStatus(true);
      } else {
        setStoreStatus(false);
      }
    }
    setOpen(false);
  };

  const handleNo = () => {
    setOpen(false);
  };


  const unlinkADevice = async (storeId, aisleId, deviceId) => {
    await dispatch(unlinkDevice(storeId, aisleId, deviceId));
  }


  const disableAStore = async (switchType, storeId, active) => {
    if(!active){
    setOpenDisableDialog(true);
    const disableStoreParams = {storeId, active};
    setDisableState({switchType, params: disableStoreParams});
    }
    else{
      await dispatch(disableStore(storeId,  active));
    }
  }

  const disableAStaff = async (switchType, storeId, staffId, active) => {
    if(!active){
    setOpenDisableDialog(true);
    const disableStaffParams = {storeId, staffId, active};
    setDisableState({switchType, params: disableStaffParams});
    }
    else{
      await dispatch(disableStaff(storeId, staffId, active));
    }
  }

  const disableAnAisle = async (switchType, storeId, aisleId, active) => {
    if(!active){
    setOpenDisableDialog(true);
    const disableAisleParams = {storeId, aisleId, active};
    setDisableState({switchType, params: disableAisleParams});
    }
    else{
      await dispatch(disableAisle(storeId, aisleId, active));
    }
  }


  const toggleChatInDevice = async (state, storeId, aisleId) => {
      await dispatch(toggleChat(storeId, aisleId, state))
  }

  const getAnAisleToken = async (storeId, aisleId, minutesToExpire = 1) => {
    let aisleToken = await dispatch(getAisleToken(storeId, aisleId, minutesToExpire));
    setAisleToken(aisleToken);
    setMinutesToExpire(minutesToExpire * 60 * 1000);
    setOpen(true);
    setTimerExpired(false);
  }

  useEffect(() => {
    const fetch = async () => {
      await dispatch(getStores());

    };
    fetch();
  }, []);

  return (
    <>
      <StatusBar />
      <Box className={isMobileView ? classes.containerMobile : classes.container}>
        <Box className={isMobileView ? classes.outsideContentMobile : classes.outsideContent}>
          <Typography
            className={isMobileView ? classes.mainHeadingMobile : classes.mainHeading}
            style={{ fontFamily: 'PlusJakartaSans-Bold' }}
          >
            MANAGE STOREFRONT
          </Typography>
          <Button
            className={isMobileView? classes.addStoreBtnMobile:classes.addStoreBtn}
            variant="contained"
            color="primary"
            onClick={() => history.push(`${url}/create`)}
          >
            Add Store
          </Button>
        </Box>
        {stores.map((row, index) => (
          <Paper square elevation={0} className={classes.storePaper}>
            <Box
              className={
                isMobileView ? classes.divideMobileView : classes.divideTabletView
              }
            >
              <Box className={isMobileView? classes.storeContainerMobile : classes.storeContainer}>
                <Box className={classes.imageContainer}>
                  <img width="100%" src={row.logo?.thumbnailUrl} alt={row.name} />
                </Box>
                <Box className={classes.storeDetails}>
                  <Typography
                    className={
                      storeNameTabletView
                        ? classes.storeNameTabletView
                        : classes.storeName
                    }
                  >
                    {`${row.name}`}
                    <img
                      src="/images/edit_icon.svg"
                      alt="edit"
                      className={
                        storeNameTabletView
                          ? classes.editStoreTabletView
                          : classes.editStore
                      }
                      style={{cursor: 'pointer', pointerEvents: row.active ? 'auto' : 'none'}}
                      onClick={() => history.push(`${url}/${row.id}/update`)}
                    />
                  </Typography>
                  <Typography className={classes.storeAddress}>
                    <img
                      src="/images/location.svg"
                      alt="edit"
                      className={classes.marginRightTen}
                    />
                    {(row?.storeType === 'single-device') ? 
                    `${row?.address?.address1}, ${row?.address?.address2 ? `${row?.address?.address2},` : ``} ${row?.address?.city}, ${row?.address?.state} ${row?.address?.zipCode}, ${row?.address?.country}` 
                    : 
                    `${row?.address?.address1}`}
                    {/* {row?.address?.address1} */}
                  </Typography>
                  {row.storeNo && <Typography className={classes.storeAddress}>
                    <img
                      src="/images/store.svg"
                      alt="edit"
                      className={classes.marginRightTen}
                    />
                    {`Store No: ${row.storeNo}`}
                  </Typography>}
                </Box>
              </Box>
              <Box className={isMobileView? classes.storeStatusMobile : classes.storeStatus}>
                <FormControlLabel
                  style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 0
                  }}
                  labelPlacement="start"
                  // label={
                  //   <Typography className={classes.tableData}>
                  //     Disable
                  //   </Typography>
                  // }
                  // className= "toggleSwitch"
                  className= {row.active ? "toggleSwitchEnable" : "toggleSwitchDisable"}
                  control={
                    <Switch 
                      color="primary"
                      checked={row.active}
                      onChange={(e) => disableAStore("storeSwitch",row.id,e.target.checked)}
                    />
                  }
                />
              </Box>
            </Box>
            <Box className={classes.breakPoint}></Box>
            <Box className='manageStoreBodyHeader'>
              <Box>
                <Typography className={classes.aisleUsers}>
                  App Users
                </Typography>
              </Box>
              <Box>
                <Button
                  to={{
                    pathname: `${url}/${row.id}/add-user`,
                    state: { background: location },
                  }}
                  className={classes.link}
                  component={Link}
                  disabled={row.active ? false : true}
                  style={{fontWeight: 'bolder'}}
                >
                  <img
                    src={row.active ? "/images/add_aisle_user.svg" : "/images/add_aisle_user_inactive.png" }
                    alt="edit"
                    className={classes.addAisleUser}
                  />
                  Add User
                </Button>
              </Box>
            </Box>
            <Box className={isMobileView ? classes.aislesMobile : classes.aisles}>
              {!isMobileView ? 
              <TableContainer style={{ marginBottom: '40px' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.headTableCell}>
                      <TableCell
                        className={classes.tableHead + ' ' + classes.serial}
                      >
                        S.No
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        First Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Last Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Email Id
                      </TableCell>
                      <TableCell
                        className={[classes.tableHead]}
                        style={{width: '15%'}}
                        align="center"
                      >
                        Enable
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.staff.map((singleStaff, index) => (
                      <TableRow key={singleStaff.id}>
                        <TableCell className={classes.tableData}>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          <Box className={[classes.aisleDetails,classes.textCaptilize]}>
                            <Box
                              className={classes.tableData}
                            >{`${singleStaff?.user?.firstName}`}</Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className={[classes.aisleDetails,classes.textCaptilize]}>
                            <Box
                              className={classes.tableData}
                            >{`${singleStaff?.user?.lastName}`}</Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className={classes.aisleDetails}>
                            <Box
                              className={classes.tableData}
                            >{`${singleStaff?.user?.email}`}</Box>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <FormControlLabel
                              style={{
                                 marginRight: 10,
                                // marginLeft: 5
                              }}
                              className= {singleStaff.active ? "switchEnable" : "switchDisable"}
                              disabled={row.active ? false : true}
                              control={
                                <Switch
                                  size="small"
                                  checked={singleStaff.active}
                                  color="primary"
                                  onChange={(e) => disableAStaff("staffSwitch",singleStaff.storeId,singleStaff.id,e.target.checked)}
                                />
                              }
                              labelPlacement="start"
                              
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> :
              <Box>
              {row.staff.map((singleStaff, index) => (
                 <Card elevation={0} style={{  width: '95%' ,backgroundColor: '#f4f9ff', margin: '1rem auto' , display: 'flex' }}>
                 <CardContent 
                  style={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'space-between', 
                    gap:'8px', 
                    flexBasis: 0, 
                    flexGrow: 1}}
                    >
                    <Typography 
                      style={{   
                        fontSize: '14px',
                        fontFamily: "PlusJakartaSans-Bold"
                      }} 
                      color="text.secondary"
                    >
                      First Name
                    </Typography>

                   <Typography color="text.secondary" style={{ fontSize: '14px', overflow:'auto',fontFamily: "PlusJakartaSans-Regular"}}>
                   {`${singleStaff?.user?.firstName}`}
                   </Typography>


                   <Typography style={{ fontFamily: "PlusJakartaSans-Bold",  fontSize: '14px'}} color="text.secondary">
                     Last Name
                   </Typography>

                   <Typography color="text.secondary" style={{ fontSize: '14px', overflow:'auto',fontFamily: "PlusJakartaSans-Regular"}}>
                   {`${singleStaff?.user?.lastName}`}
                   </Typography>

                  
                  
                   <Typography style={{ fontFamily: "PlusJakartaSans-Bold",  fontSize: '14px'}} color="text.secondary">
                     Email Id
                   </Typography>

                   <Typography color="text.secondary" style={{ fontSize: '14px', overflow:'auto',fontFamily: "PlusJakartaSans-Regular"}}>
                   {`${singleStaff?.user?.email}`}
                   </Typography>
                 </CardContent>
                 <CardActions style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
                            <FormControlLabel
                              style={{
                                margin: 0
                              }}
                              className= {singleStaff.active ? "switchEnable" : "switchDisable"}
                              disabled={row.active ? false : true}
                              control={
                                <Switch
                                  size="small"
                                  checked={singleStaff.active}
                                  color="primary"
                                  onChange={(e) => disableAStaff("staffSwitch",singleStaff.storeId,singleStaff.id,e.target.checked)}
                                />
                              }

                            />
                 </CardActions>
               </Card>
              ))}
              </Box>
            }
            </Box>
            <Box className={classes.breakPoint}></Box>
            <Box 
              className="manageStoreBodyHeader"
            >
              <Box>
                <Typography className={classes.aisleUsers}>
                  Devices
                </Typography>
              </Box>
              <Box>
                <Button
                  to={{
                    pathname: `${url}/${row.id}/add-device`,
                    state: { background: location },
                  }}
                  className={classes.link}
                  component={Link}
                  disabled={row.active && !(row.storeType==='single-device') ? false : true}
                  style={{fontWeight: 'bolder'}}
                >
                  <img
                    src={row.active && !(row.storeType==='single-device')? "/images/add_device.svg" : "/images/add_device_inactive.svg" }
                    alt="edit"
                    className={classes.addDevice}
                  /> 
                  Add Device
                </Button>
              </Box>
            </Box>
            <Box className={isMobileView? classes.devicesMobile: classes.devices}>
              {!isMobileView ?
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.headTableCell}>
                      <TableCell
                        className={classes.tableHead + ' ' + classes.serial}
                      >
                        S.No
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Device Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Status
                      </TableCell>
                      <TableCell className={classes.tableHead} align='center'>
                        Chat
                      </TableCell>
                      <TableCell className={[classes.tableHead]} 
                         style={{
                          width: '15%'
                        }}
                        align="center">  
                       Enable                      
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.aisles.map((aisle, index) => (
                      <TableRow key={aisle.id}>
                        <TableCell className={classes.tableData}>
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          <Box
                            className={
                              isTablet
                                ? classes.aisleDetailsTablet +
                                  ' ' +
                                  classes.tableData
                                : classes.aisleDetails + ' ' + classes.tableData
                            }
                          >
                            <Box>{aisle.name}</Box>
                            <Box>
                              <Button
                                className={
                                  isTablet
                                    ? classes.editDeviceTablet
                                    : classes.editDevice
                                }
                                component={Link}
                                disabled={row.active && aisle.active && !aisle.deviceId ? false : true}
                                color="default"
                                variant="contained"
                                style={{
                                  textTransform: 'unset',
                                  fontSize: '15px',
                                  minWidth: '5px',
                                  marginRight: '30px',
                                  backgroundColor: '#f4f4f4',
                                  borderRadius: '4px',
                                  width: '37px',
                                  height: '33px',
                                  boxShadow: 'none',
                                }}
                                to={{
                                  pathname: `${url}/${row.id}/aisles/${aisle.id}/update-device`,
                                  state: { background: location, aisleName: aisle.name },
                                }}                                
                              >
                                <img
                                  style={{ width: '15px', height: '20px', cursor:'pointer' }}
                                  src="/images/edit_icon.svg"
                                  alt="edit"
                                />
                              </Button>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          { aisle.deviceId ? (
                            <Box className='storeLinkSec'>
                            <Box
                              className={
                                isTablet
                                  ? classes.deviceStatusTablet
                                  : classes.deviceStatus
                              }
                            >
                              <Typography>
                                Linked
                              </Typography>
                              <Button
                                className={
                                  isTablet
                                    ? classes.linkStatusTablet
                                    : classes.linkStatus
                                }
                                color="primary"
                                variant="outlined"
                                style={{
                                  //minWidth: '90px',
                                  textTransform: 'unset',
                                  minWidth: '82px',
                                  height: '32px',
                                  borderRadius: '2px'
                                }}
                                disabled={row.active && aisle.active ? false : true}
                                onClick={() => {
                                  unlinkADevice(row.id, aisle.id, aisle.deviceId);
                                }}
                              >
                                Unlink
                              </Button>
                            </Box>
                            </Box>
                          ) : (
                            <Box className='storeUnLinkSec'>
                            <Box
                              className={
                                isTablet
                                  ? classes.deviceStatusTablet
                                  : classes.deviceStatus
                              }
                            >
                              <Typography>
                                Not Linked
                              </Typography>
                              <Button
                                className={
                                  isTablet
                                    ? classes.linkStatusTablet
                                    : classes.linkStatus
                                }
                                color="primary"
                                variant="contained"
                                style={{
                                  textTransform: 'unset',
                                  minWidth: '82px',
                                  height: '32px',
                                  borderRadius: '2px'
                                }}
                                disabled={row.active && aisle.active ? false : true}
                                onClick={() => {
                                  getAnAisleToken(aisle.storeId, aisle.id);
                                  setStoreId(aisle.storeId);
                                  setAisleId(aisle.id);
                                }}
                              >
                                Link
                              </Button>
                            </Box>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          <Box>
                            <FormControlLabel
                              style={{
                                marginRight: 10,
                                //marginLeft: 5,
                              }}
                              className={aisle?.isChatEnabled ? "switchEnable" : "switchDisable"}
                              disabled={row.active ? false : true}
                              control={
                                <Switch
                                  checked={aisle?.isChatEnabled}
                                  size="small"
                                  color="primary"
                                  onChange={(e) => {
                                      const noOfChatAisles =
                                        row.aisles.reduce((acc, curr) => {
                                          if (curr.isChatEnabled === true) {
                                            acc = acc + 1;
                                          }
                                          return acc;
                                        }, 0);
                                      if (aisle.isChatEnabled === true && noOfChatAisles === 1) {
                                        toggleChatInDevice("disabled",aisle.storeId, aisle.id);
                                      }
                                      else if (aisle.isChatEnabled === false && noOfChatAisles === 0){
                                        toggleChatInDevice("enabled",aisle.storeId, aisle.id);
                                      }
                                      else{
                                        dispatch(showToast('Chat can be enabled only at one device. Please disable other devices'))
                                        setTimeout(() => {
                                          dispatch(stopToast());
                                        }, 4000);
                                        return
                                      }
                                  }}
                                />
                              }
                              labelPlacement="start"

                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            <FormControlLabel
                              style={{
                                marginRight: 10,
                                //marginLeft: 5,
                              }}
                              className={aisle.active ? "switchEnable" : "switchDisable"}
                              disabled={row.active ? false : true}
                              control={
                                <Switch
                                  checked={aisle.active}
                                  size="small"
                                  color="primary"
                                  onChange={(e) => {
                                    if (row.storeType === "single-device") {
                                      const noOfActiveAisles =
                                        row.aisles.reduce((acc, curr) => {
                                          if (curr.active === true) {
                                            acc = acc + 1;
                                          }
                                          return acc;
                                        }, 0);
                                      if (aisle.active === true && noOfActiveAisles === 1) {
                                        disableAnAisle("aisleSwitch",aisle.storeId, aisle.id, e.target.checked);
                                      }
                                      else if(aisle.active === false && noOfActiveAisles === 0){
                                        disableAnAisle("aisleSwitch",aisle.storeId, aisle.id, e.target.checked);
                                      }
                                      else{
                                        return;
                                      }

                                    }
                                    disableAnAisle("aisleSwitch",aisle.storeId, aisle.id, e.target.checked);
                                  }}
                                />
                              }
                              labelPlacement="start"
                              // label={
                              //   <Typography style={{ fontSize: '15px' }}>
                              //     Disable
                              //   </Typography>
                              // }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> :
              <Box>
                  {row.aisles.map((aisle, index) => (
                      <Card elevation={0} style={{  width: '95%' ,backgroundColor: '#f4f9ff', margin: '1rem auto' , display: 'flex' }}>
                      <Box style={{flexBasis: 0 , flexGrow: 2, display: 'flex', flexDirection:'column'}}>
                      <CardContent style={{display: 'flex', flexDirection: 'column', gap:'0.5rem', alignItems:'flex-start', padding: '16px'}}>
                        <Typography style={{ fontFamily: "PlusJakartaSans-Bold", fontSize: 14 }} color="text.secondary">
                          Device Name
                        </Typography>
                        <Box style={{display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center'}}>
                        <Typography style={{ fontFamily: "PlusJakartaSans-Regular", fontSize: 14 }} color="text.secondary">
                          {aisle.name}
                        </Typography>
                        <Button
                                color="default"
                                variant="contained"
                                style={{
                                  textTransform: 'unset',
                                  fontSize: '15px',
                                  minWidth: '5px',
                                }}
                                component={Link}
                                disabled={row.active && aisle.active ? false : true}
                                to={{
                                  pathname: `${url}/${row.id}/aisles/${aisle.id}/update-device`,
                                  state: { background: location, aisleName: aisle.name },
                                }}  
                              >
                                <img
                                  style={{ width: '15px', height: '20px' }}
                                  src="/images/edit_icon.svg"
                                  alt="edit"
                                />
                        </Button>
                        </Box>
                      </CardContent>
                      <CardActions style={{display: 'flex', flexDirection: 'column', gap:'0.5rem', alignItems:'flex-start', padding: '16px'}}>
                      <Typography style={{ fontFamily: "PlusJakartaSans-Bold", fontSize: 14 }} color="text.secondary">
                          Status
                      </Typography>
                      { aisle.deviceId ? (
                            <Box style={{display: 'flex',  flexDirection:'row', alignItems:'center', justifyContent: 'space-between', margin: 0, gap:'1rem'}}                              
                            >
                              <Typography
                                style={{
                                  color: '#0ac743',
                                  fontSize: 14,
                                  fontFamily: "PlusJakartaSans-Regular"
                                }}
                              >
                                Linked
                              </Typography>
                              <Button 
                                color="primary"
                                variant="outlined"
                                style={{
                                  minWidth: '90px',
                                  textTransform: 'unset',
                                  margin: 0,
                                  fontSize: 14,
                                  fontFamily: "PlusJakartaSans-Regular"
                                }}
                                disabled={row.active && aisle.active ? false : true}
                                onClick={() => {
                                  unlinkADevice(row.id, aisle.id, aisle.deviceId);
                                }}
                              >
                                Unlink
                              </Button>
                            </Box>
                          ) : (
                            <Box style={{display: 'flex',  flexDirection:'row', alignItems:'center', justifyContent: 'space-between', margin: 0, gap:'1rem'}}
                            >
                              <Typography
                                style={{
                                  color: '#86888a',
                                  fontSize: 14,
                                  fontFamily: "PlusJakartaSans-Regular"
                                }}
                              >
                                Not Linked
                              </Typography>
                              <Button
                                color="primary"
                                variant="contained"
                                style={{
                                  textTransform: 'unset',
                                  minWidth: '90px',
                                  margin: 0,
                                  fontSize: 14,
                                  fontFamily: "PlusJakartaSans-Regular"
                                }}
                                disabled={row.active && aisle.active ? false : true}
                                onClick={() => {
                                  getAnAisleToken(aisle.storeId, aisle.id);
                                  setStoreId(aisle.storeId);
                                  setAisleId(aisle.id);
                                }}
                              >
                                Link
                              </Button>
                            </Box>
                          )}
                      </CardActions>
                      </Box>
                      {/* <Divider orientation="vertical" variant="middle" flexItem style={{border: 'none',borderRight: '0.1rem dotted gray', backgroundColor:'transparent'}} /> */}
                      <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                      {/* <CardContent style={{}}> */}
                        {/* <Typography style={{ fontWeight: 'bold' }} color="text.secondary">
                          Allow Auto Payment Accept
                        </Typography> */}
                      {/* </CardContent> */}
                      <CardActions style={{display: 'flex', justifyContent: 'flex-end', padding:'16px'}}>
                      <FormControlLabel
                              style={{
                                margin: 0
                              }}
                              className={aisle.active ? "switchEnable" : "switchDisable"}
                              disabled={row.active ? false : true}
                              control={
                                <Switch
                                  checked={aisle.active}
                                  size="small"
                                  color="primary"
                                  onChange={(e) => disableAnAisle("aisleSwitch",aisle.storeId, aisle.id, e.target.checked)}
                                />
                              }

                            />
                      </CardActions>
                      <CardContent 
                        style={{
                          display: 'flex', 
                          flexDirection: 'column', 
                          gap:'0.5rem', 
                          alignItems:'flex-start', 
                          padding: '16px',
                          marginBottom: 8,
                        }}
                      >
                        <Typography style={{ fontFamily: "PlusJakartaSans-Bold", fontSize: 14 }} color="text.secondary">
                          Chat
                        </Typography>
                        <Box>
                          <FormControlLabel
                            style={{
                              //marginRight: 10,
                              marginLeft: -4,
                            }}
                            className={aisle?.isChatEnabled ? "switchEnable" : "switchDisable"}
                            disabled={row.active ? false : true}
                            control={
                              <Switch
                                checked={aisle?.isChatEnabled}
                                size="small"
                                color="primary"
                                onChange={(e) => {
                                    const noOfChatAisles =
                                      row.aisles.reduce((acc, curr) => {
                                        if (curr.isChatEnabled === true) {
                                          acc = acc + 1;
                                        }
                                        return acc;
                                      }, 0);
                                    if (aisle.isChatEnabled === true && noOfChatAisles === 1) {
                                      toggleChatInDevice("disabled",aisle.storeId, aisle.id);
                                    }
                                    else if(aisle.isChatEnabled === false && noOfChatAisles === 0){
                                      toggleChatInDevice("enabled",aisle.storeId, aisle.id);
                                    }
                                    else{
                                      return;
                                    }
                                }}
                              />
                            }
                            labelPlacement="start"
                          />
                        </Box>
                      </CardContent>
                      </Box>
                    </Card>
                  ))}
              </Box>
              }
            </Box>
          </Paper>
        ))}
        {!loader.showLoader && (stores?.length === 0) && 
        <Paper square elevation={0} >
                <div className='noTransText'>
                    No Stores added
                </div>
        </Paper>}
      </Box>
      <Dialog onClose={() => setOpen(false)} open={open} 
        fullWidth={true}
        maxWidth={'sm'} 
        className="linkDevicePopUp"
        >
          {/* <DialogTitle style={{textAlign: 'end'}}></DialogTitle> */}
        <DialogActions>
          {/* <Button onClick={() => setOpen(false)}>Cancel</Button> */}
          <img onClick={() => setOpen(false)} src="/images/cross_icon.svg" style={{cursor: 'pointer'}} />
        </DialogActions>  
         
        <DialogTitle className= "modalTitle">
            LINK DEVICE
        </DialogTitle>
        <DialogContent 
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem'
        }}>
          <DialogContentText 
            style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              textAlign:'center',
              letterSpacing:'1rem',
              color: timerExpired ? 'rgba(40, 40, 40, 0.4)' : 'black'
            }}>
              {`${aisleToken.token}`}
          </DialogContentText>
          <DialogContentText 
            style={{
              display: 'flex',
              backgroundColor: "#F3ECE7",
              width: '80%',
              justifyContent: 'center',
              padding: '1rem',
              gap : '1rem',
              fontSize: 13,
              fontFamily:'PlusJakartaSans-SemiBold',
              color: '#000000',
              letterSpacing: 0.65
            }} >
            <img src="/images/note_icon.svg" alt="note icon"/>
            Please enter the above generated code in the Aisle App and refresh this page after linking
          </DialogContentText>
          
          { !timerExpired ? (<DialogContentText 
            style={{
              fontSize: 14,
              fontFamily:'PlusJakartaSans-Bold',
              color: '#333333',
              marginBottom: 20
            }}
          >
            Token will expire in 
              <span style={{color: '#1A7CFA',fontWeight: 700,marginLeft: 5}}>
                <Countdown 
                date={Date.now() + minutesToExpire} 
                renderer={({minutes, seconds}) => {
                   return (<span>{minutes < 10 ? `0${minutes}`: `${minutes}`} : {seconds < 10 ? `0${seconds}`: `${seconds}`}</span>)
                }} 
                onComplete={() => setTimerExpired(true)}>
                </Countdown>
              </span>
          </DialogContentText>) 
            :
          (<DialogContentText 
            style={{
              fontSize: 14,
              fontFamily:'PlusJakartaSans-Bold',
              color: '#333333',
              marginBottom: 20
            }}
          >
            Token expired 
            <span className="margin_left_10">
              <Button variant='contained' color='primary' onClick={() =>{
                 getAnAisleToken(storeId, aisleId);
              }}>
                Generate Token
              </Button>
            </span>
          </DialogContentText>
          )
        }
        </DialogContent>
        

      </Dialog>
      <DisableDialogComponent openDisableDialog={openDisableDialog} setOpenDisableDialog={setOpenDisableDialog} disableState={disableState} />
    </>
  );
}
