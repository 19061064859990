import { CALL_API } from '../api.middleware';
import {
  BUSINESS_ME,
  BUSINESS_ME_SUCCESS,
  BUSINESS_PREMIUM,
  BUSINESS_PREMIUM_SUCCESS,
  CREATE_DISCOUNT,
  CREATE_DISCOUNT_SUCCESS,
  CREATE_LOYALTY,
  CREATE_LOYALTY_SUCCESS,
  DELETE_LOYALTY,
  DELETE_LOYALTY_SUCCESS,
  DISCOUNT_DELETE,
  DISCOUNT_DELETE_SUCCESS,
  DISCOUNT_EDIT,
  DISCOUNT_EDIT_SUCCESS,
  DISCOUNT_LIST,
  DISCOUNT_LIST_SUCCESS,
  EDIT_LOYALTY,
  EDIT_LOYALTY_SUCCESS,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  LOYALTY_LIST,
  LOYALTY_LIST_SUCCESS,
} from '../types/rewards';

export const createDiscount = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/discounts/create`,
        showLoader: true,
        method: 'POST',
        types: [CREATE_DISCOUNT, CREATE_DISCOUNT_SUCCESS],
        body,
        showToast: true,
        toastMessage: 'Discount created successfully'
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const fileUpload = (file) => async (dispatch) => {
  let formData = new FormData();
  formData.append(`file`, file);
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/files/files`,
        showLoader: true,
        method: 'POST',
        types: [FILE_UPLOAD, FILE_UPLOAD_SUCCESS],
        body: formData,
        isForm: true,
        // showToast: true,
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const getDiscountList =
  () =>
  async (dispatch) => {
    try {
      return await dispatch({
        [CALL_API]: {
          url: `/businesses/discounts`,
          showLoader: true,
          method: 'GET',
          types: [DISCOUNT_LIST, DISCOUNT_LIST_SUCCESS],
          // showToast: true,
        },
      });
    } catch (error) {
      console.error(error)
      throw error
    }
  };

export const getDiscountById = 
  (id) => 
    async (dispatch) => {
      try {
        return await dispatch({
          [CALL_API]: {
            url: `/businesses/discounts/${id}`,
            showLoader: true,
            method: 'GET',
            // types: [EDIT_DISCOUNT],
            // showToast: true,
          },
        })
      } catch (error) {
        console.log(error);
        throw error;
      }
    }

export const getLoyaltyById = 
  (id) => 
    async (dispatch) => {
      try {
        return await dispatch({
          [CALL_API]: {
            url: `/businesses/loyalties/${id}`,
            showLoader: true,
            method: 'GET',
            // types: [LOYALTY_EDIT],
            // showToast: true,
          },
        })
      } catch (error) {
        console.log(error);
        throw error;
      }
    }

export const getBusiness = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/businesses/me`,
        showLoader: true,
        method: 'GET',
        types: [BUSINESS_ME, BUSINESS_ME_SUCCESS],
        // showToast: true,
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const changeBusinessPremium = (past) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/businesses/me/premium/${past ? 'disable' : 'enable'}`,
        showLoader: true,
        method: 'POST',
        types: [BUSINESS_PREMIUM, BUSINESS_PREMIUM_SUCCESS],
        // showToast: true,
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const editDiscount = (id, body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/discounts/${id}/update`,
        showLoader: true,
        method: 'POST',
        types: [DISCOUNT_EDIT, DISCOUNT_EDIT_SUCCESS],
        body,
        showToast: true,
        toastMessage: 'Discount updated successfully'
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const deleteDiscount = (id) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/discounts/${id}/delete`,
        showLoader: true,
        method: 'POST',
        types: [DISCOUNT_DELETE, DISCOUNT_DELETE_SUCCESS],
        showToast: true,
        toastMessage: 'Discount have been deleted'
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const editLoyalty = (id, body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/loyalties/${id}/update`,
        showLoader: true,
        method: 'POST',
        types: [EDIT_LOYALTY, EDIT_LOYALTY_SUCCESS],
        body,
        showToast: true,
        toastMessage: 'Loyalty updated successfully'
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const deleteLoyatly = (id) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/loyalties/${id}/de-activate`,
        showLoader: true,
        method: 'post',
        types: [DELETE_LOYALTY, DELETE_LOYALTY_SUCCESS],
        showToast: true,
        toastMessage: 'Loyalty have been deleted'
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const createLoyalty = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/loyalties/create`,
        showLoader: true,
        method: 'POST',
        types: [CREATE_LOYALTY, CREATE_LOYALTY_SUCCESS],
        body,
        showToast: true,
        toastMessage: 'Loyalty created successfully'
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const loyaltyList = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/loyalties`,
        showLoader: true,
        method: 'GET',
        types: [LOYALTY_LIST, LOYALTY_LIST_SUCCESS],
        // showToast: true,
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};
