import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ImageList from "@material-ui/core/ImageList";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    // height: 500,
    width: 600,
  },
  paperXsMW: {
    //width: 253,
    width: 300
  },
  paperMW: {
    width: 340,
  },
  paperTW: {
    width: 400,
  },
  structure: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    overflow: "hidden",
    backgroundColor: "transparent",
    position: "absolute",
    marginTop: -270,
    marginLeft: 58,
  },
  imageList: {
    width: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageListXs: {
    width: 250,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageListItem: {
    // height: 50,
    // width: 50,
    opacity: 0.65,
  },
}));

const imageListItem = { height: 130, width: 130 };
const imageListItemXs = { height: 100, width: 105 };

export default function DiscountImagePreview({
  handleClose,
  handleOpen,
  open,
  previewImg,
  discount,
  description
}) {
  const classes = useStyles();
  const isXsMobile = useMediaQuery("(max-width : 360px)");
  const isMobile = useMediaQuery("(max-width : 480px)");
  const isTablet = useMediaQuery("(max-width : 767px)");

  console.log("preview =", previewImg);

  return (
    <div style={{ overflow: "scroll" }}>
      <Modal
        // aria-labelledby="transition-modal-title"
        // aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ height: "auto" }}
      >
        <Fade style={{ outline: "none" }} in={open}>
          <Box>
            <Box style={{ display: "flex" }}>
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  // position: "absolute",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "PlusJakartaSans-Bold",
                    fontSize: isXsMobile ? 12 : 16,
                    color: "gray",
                    marginLeft: 50,
                  }}
                >
                  DISCOUNT IMAGE PREVIEW
                </Typography>
              </Box>

              <Box style={{ backgroundColor: "white" }}>
                <Button onClick={handleClose}>
                  <CloseIcon />
                </Button>
              </Box>
            </Box>

            <Box
              className={
                isXsMobile
                  ? `${classes.paper} ${classes.paperXsMW}`
                  : isMobile
                  ? `${classes.paper} ${classes.paperMW}`
                  : isTablet
                  ? `${classes.paper} ${classes.paperTW}`
                  : classes.paper
              }
            >
              <Box className={classes.structure}>
                {!isMobile && !isTablet && !isXsMobile && (
                  <Box
                    style={{
                      position: "absolute",
                      // width: 100,
                      marginLeft: -450,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box style={{ width: 100 }}>
                      <img
                        alt="preview arrow"
                        style={{
                          height: 120,
                          width: 120,
                          objectFit: "contain",
                        }}
                        src="/images/discount_arrow.svg"
                      />
                    </Box>
                  </Box>
                )}
                <Box>
                  <img 
                    src="/images/your_discount_mob.svg"
                    alt="store preview"
                    style={{
                      width: 150,
                      margin: 'auto'
                    }}
                    className="desktop_none"
                  />
                
                <Box
                  style={{
                    // position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "pink",
                  }}
                >
                  <img
                    alt="Preview"
                    src="/images/discountpreview.png"
                    style={{
                      // height: 529,
                      width: isXsMobile ? 350 : 450,
                      // height: 479,
                      // marginTop: 300,
                      // objectFit: "contain",
                      // backgroundColor: "green",
                    }}
                  />
                </Box>
                <Box className="imagePreviewWrapper">
                  <ImageList
                    gap={8}
                    className={
                      isXsMobile
                        ? `${classes.imageList} ${classes.imageListXs}`
                        : classes.imageList
                    }
                  >
                    <ImageListItem
                      className= "imageItemBar"
                      // className={classes.imageListItem}
                      style={isXsMobile ? imageListItemXs : imageListItem}
                    >
                      <Box
                      // style={{ height: 160, width: 160, objectFit: "contain" }}
                      >
                        <img
                          src={
                            previewImg ? previewImg : "/images/section_two_image.svg"
                          }
                          style={{
                            width: '100%'
                          }}
                          alt={"Shop pic"}
                        />
                      </Box>

                      <ImageListItemBar
                        title={`${parseFloat(discount)}% ${description}`}
                        subtitle={<span style={{ textOverflow: "ellipsis",whiteSpace: "nowrap"}}>Store name</span>}
                        //   actionIcon={
                        //     <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
                        //       <InfoIcon />
                        //     </IconButton>
                        //   }
                      />
                    </ImageListItem>
                    {new Array(3).fill(0).map(() => <ImageListItem
                      className= "imageItemBar"
                      //className={classes.imageListItem}
                      style={isXsMobile ? imageListItemXs : imageListItem}
                    >
                      <Box
                      >
                        <img src="/images/store-preview.png" 
                          style={{
                            width: '100%'
                          }}
                          alt={"Shop pic"}
                        />
                      </Box>

                      <ImageListItemBar
                        //title={"New Clinton"}
                        subtitle={<span>{"Foods Market"}</span>}
                        //   actionIcon={
                        //     <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
                        //       <InfoIcon />
                        //     </IconButton>
                        //   }
                      />
                    </ImageListItem>)}
                  </ImageList>
                </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
