import React from "react";
import { useSelector } from "react-redux";

export default function BusinessDetails() {
    const { businessData } = useSelector((state) => state.auth);
    const { address, business = {} } = businessData || {};
    const {
        address1 = "",
        address2 = "",
        city = "",
        state = "",
        zipCode = "",
        country = "",
    } = address || {};
    const { name = "" } = business || {};
    const { name: categoryName = "" } = business.category || {};
    // const addressDetails = !address
    //     ? ""
    //     : [
    //           address1,
    //           address2 ? address2 : "",
    //           city,
    //           state,
    //           zipCode,
    //           country,
    //       ].join(", ");

        const addressDetails = !address
        ? ""
        : `${address1}, ${address2 ? `${address2},` : ``} ${city}, ${state} ${zipCode}, ${country}` ;


    return (
        <div>
            <div className="detailsSec">
                {/* <p className="businessSec">Business Details</p> */}
            </div>
            <div className="adminDetailsSec">
                <div className="businessColumn marginLeft">
                    <div className="businessLabel">Business Name</div>
                    <div className="businessValue">{name}</div>
                    <div className="businessLabel">Address</div>
                    <div className="businessValue textCapitalize">{`${addressDetails}`}</div>
                </div>
                <div className="businessColumn margin_mob">
                    <div className="businessLabel">Business Category</div>
                    <div className="businessValue">{categoryName}</div>
                </div>
            </div>
        </div>
    );
}