import { Checkbox, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import {
	CONDITIONAL_DISCOUNT_ROUTE,
	ONE_OFF_DISCOUNT_ROUTE,
} from '../../constants';
import RewardsRoutes from '../../controllers/rewards/rewardsFolder';
import StatusBar from '../../controllers/statusBar';
import { changeBusinessPremium } from '../../store/actions/rewards';

const Rewards = (props) => {
	const location = useLocation();
	const [state, setState] = useState({
		currentTab: CONDITIONAL_DISCOUNT_ROUTE
		// currentTab: location.pathname.includes('conditional') ? CONDITIONAL_DISCOUNT_ROUTE : ONE_OFF_DISCOUNT_ROUTE,
	});

	const dispatch = useDispatch();
	
	const { businessData } = useSelector((s) => s.auth);


	const handleChange = (e, newValue) => {
		setState({ currentTab: newValue });
	};

	const updateBusinessPremium = async () => {
		await dispatch(changeBusinessPremium(businessData.business.premium));
	};

	return (
		<>
			<StatusBar />
			<div className='rewards-wrapper reward_wid_sec'>
				<div className='rewardsHeadSec'>
					<Typography>SET PROMOTION</Typography>
					<span className='premiumText mobile_none'>
						<Checkbox
							style={{ color: '#1a7cfa' }}
							checked={businessData.business.premium}
							onChange={() => updateBusinessPremium()}
						/>
						Enable Premium Placement
					</span>
				</div>

				<Paper square elevation={0}>
					{/* <Tabs
						value={state?.currentTab}
						indicatorColor='primary'
						textColor='primary'
						onChange={handleChange}>
						<Tab
							value={ONE_OFF_DISCOUNT_ROUTE}
							label='One-off Discount'
							component={NavLink}
							to={ONE_OFF_DISCOUNT_ROUTE}
						/>
						<Tab
							value={CONDITIONAL_DISCOUNT_ROUTE}
							label='Conditional Discount'
							component={NavLink}
							to={CONDITIONAL_DISCOUNT_ROUTE}
						/>
					</Tabs> */}
				</Paper>
				<Paper
					style={{ marginTop: 12, paddingBottom: 50 }}
					square
					elevation={0}>
					<RewardsRoutes {...props} />
				</Paper>
			</div>
			<div className='enable_premium_mob desktop_none'>
				<span className='premiumText'>
					<Checkbox
						style={{ color: '#1a7cfa' }}
						checked={businessData.business.premium}
						onChange={() => updateBusinessPremium()}
					/>{' '}
					Enable Premium Placement
				</span>
			</div>
		</>
	);
};

export default Rewards;
