export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT = "LOGOUT";

export const SHOW_TOAST = "SHOW_TOAST";
export const STOP_TOAST = "STOP_TOAST";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";


export const OTP_LOGIN = "OTP_LOGIN";
export const OTP_LOGIN_SUCCESS = "OTP_LOGIN_SUCCESS";

export const FORGOT_PASSWORD_LOGIN = "FORGOT_PASSWORD_LOGIN";
export const FORGOT_PASSWORD_LOGIN_SUCCESS = "FORGOT_PASSWORD_LOGIN_SUCCESS";


export const FORGOT_PASSWORD_VALIDATE_OTP = "FORGOT_PASSWORD_VALIDATE_OTP";
export const FORGOT_PASSWORD_VALIDATE_OTP_SUCCESS = "FORGOT_PASSWORD_VALIDATE_OTP_SUCCESS";


export const CREATE_MERCHANT = "CREATE_MERCHANT";
export const CREATE_MERCHANT_SUCCESS = "CREATE_MERCHANT_SUCCESS";

export const GET_BUSSINESS_CATEGORY = "GET_BUSSINESS_CATEGORY";
export const GET_BUSSINESS_CATEGORY_SUCCESS = "GET_BUSSINESS_CATEGORY_SUCCESS";

export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";

export const VALIDATE_CODE = "VALIDATE_CODE";
export const VALIDATE_CODE_SUCCESS = "VALIDATE_CODE_SUCCESS";

export const VALIDATE_EXPERIAN = "VALIDATE_EXPERIAN";
export const VALIDATE_EXPERIAN_SUCCESS = "VALIDATE_EXPERIAN_SUCCESS";

export const MERCHANT_REGISTRATION = "MERCHANT_REGISTRATION"
export const MERCHANT_REGISTRATION_SUCCESS = "MERCHANT_REGISTRATION_SUCCESS"

export const TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS"
export const TERMS_AND_CONDITIONS_SUCCESS = "TERMS_AND_CONDITIONS_SUCCESS"

export const SCROLL_INTO_VIEW = "SCROLL_INTO_VIEW"
export const REMOVE_SCROLL_INTO_VIEW = "REMOVE_SCROLL_INTO_VIEW"


export const SET_PASSWORD = "SET_PASSWORD"
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS"


export const CLEAR_TOKEN = 'CLEAR_TOKEN'