import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPendingStatement } from "../store/actions/statement";
import StatusBar from "../controllers/statusBar";
import { formatAmount, formatDate, formatTime } from "../utils/commonFunc";
import { useHistory } from "react-router";
import { getAccountBalance } from "../store/actions/balance";

const headers = ["Trade Date", "Payment ID", "Description", "Pending Amount"];

export default function PendingStatement(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [statementList, setStatementList] = useState([]);
  const [pendingBalance, setPendingBalance] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const response = await dispatch(getAccountBalance());
      const { pendingBalance } = response;
      setPendingBalance(pendingBalance);
      const pendingStatement = await dispatch(getPendingStatement());
      setStatementList(pendingStatement);
    };
    fetch();
  }, []);

  return (
    <>
      <StatusBar />
      <div className="creditContainer">
        <div className="pendingBalHeader">
          <div className="flex_row alignItem_center">
            <img
              style={{ height: 15, marginRight: 12 }}
              src="/images/back_icon.svg"
              className="cursor"
              alt="Go Back"
              onClick={() => history.push("/portal/balance")}
            />
            <div className="pendingBalanceText">PENDING BALANCE </div>
          </div>
          <div className="totalPendingBalance">
            {`TOTAL :  ${formatAmount({ amount: pendingBalance })}`}
          </div>
        </div>
        <Paper sqare className="transactionTable" elevation={0}>
          <TableContainer component={Paper} className="tableWidth">
            <Table>
              <TableHead>
                <TableRow className="textBold statementTableRow">
                  {headers.map((header, index) => (
                    <TableCell>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {statementList?.map((row, i) => {
                  const { amount, description, paymentId, tradeDate } = row;
                  const _amount = amount?.toString().includes("-")
                    ? `-$${Math.abs(amount)}`
                    : `$${amount}`;
                  return (
                    <TableRow key={paymentId} className="statementTableRow">
                      <TableCell>{`${formatDate(tradeDate)} ${formatTime(
                        tradeDate
                      )}`}</TableCell>
                      <TableCell>{paymentId}</TableCell>
                      <TableCell>{description}</TableCell>
                      <TableCell>{_amount}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {statementList?.length === 0 && (
              <div className="noTransText">No Pending Cash Statement</div>
            )}
          </TableContainer>
        </Paper>
        <div className="paginationSec">
          {/* <Pagination count={transactionList?.pages} shape="rounded" defaultPage={parameters?.page} onChange={(event, value) => {
                      dispatch({ type: SET_PARAMETERS, body: { page: value } });
                    }} /> */}
        </div>
      </div>
    </>
  );
}
