import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { createRef } from "react";
import Footer from "./footer";
import HeaderLanding from "./headerLanding";
export default class LendersLanding extends React.Component {
    constructor(props) {
        super(props);
        this.lenderPageRef = createRef();
        this.footerRef = createRef();
    }
    componentDidMount() {
        this.lenderPageRef.current?.scrollIntoView();
    }

    componentDidUpdate() {
        this.lenderPageRef.current?.scrollIntoView();
    }
    render() {
        return (
            <>
                <HeaderLanding
                    handleAboutUs={() =>
                        this.footerRef.current?.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                />
                <div
                    style={{ paddingTop: "65px" }}
                    className="landingPage"
                    ref={this.lenderPageRef}
                >
                    {/* <Header history={this.props.history} /> */}
                    <div className="lenders_landing_sec ">
                        <div className="heading_tag_line">
                            <div className="max-width">
                                <p>connect direct. get more.</p>
                            </div>
                        </div>
                        <div className="banner_section">
                            <div className="max-width banner_content">
                                <h2 className="">
                                    {" "}
                                    A scalable venue for institutional investors
                                    to beat negative real yields.
                                </h2>
                                <p>
                                    Our mission is to deliver a positive real
                                    return at the very short end, while
                                    responsibly financing consumer spend.
                                </p>
                            </div>
                        </div>
                        <div className="embedded_sec">
                            <Grid container spacing={1}>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="embedded_left">
                                        <h2>
                                            Embedded in Unplank’s
                                            customer-merchant interface is a
                                            private credit platform that offers
                                            instituional investors access to a
                                            steady flow of consumer loans via a
                                            forward flow agreement.
                                        </h2>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="embedded_right">
                                        <img
                                            src="/images/embedded_flow_chat_img.svg"
                                            alt="flow chat"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="transparency_sec">
                            <Grid container spacing={1}>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="transparency_left">
                                        <h2>
                                            Alignment of interest. <br />
                                            We get paid, when you do.
                                        </h2>
                                        <h2>
                                            1% fee deducted monthly, in arrears.
                                        </h2>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="transparency_right">
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Transparency:
                                            <br /> Access to portfolio and
                                            analytics via online portal.
                                        </h2>
                                        <h2>
                                            {" "}
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            No SPV:
                                            <br /> Just-in-time drawdown of
                                            capital commitment upon origination.
                                        </h2>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="lenders_break_sec">
                            <div className="lenders_break_banner">
                                <img
                                    src="/images/lender_bg_break_image.png"
                                    alt="merchent info"
                                />

                                <div className="max-width">
                                    <div className="lenders_break_banner_text">
                                        <h2>
                                            Unplank was conceived as an antidote
                                            to the unsustainable compression of
                                            term and risk premiums created by
                                            negative real rates that have held
                                            global financial markets in a
                                            recurring loop of asset led boom and
                                            bust for the past twenty-five years.
                                        </h2>

                                        <p>
                                            The intention is to create a conduit
                                            for excessive liquidity that
                                            directly supports consumer demand,
                                            instead of fuelling unsustainable
                                            increases in secondary asset prices
                                            that threaten financial stability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="expected_rate_sec">
                        <div className="max-width">
                            <div className="expected_rate_text">
                                <h2>Expected Rate of Return:</h2>
                                <h3>
                                    Target return is linked to inflation. At
                                    current rates, expected rate of return is
                                    +9% with an expected variance of 2%.{" "}
                                </h3>
                                <p>
                                    Should inflation abate, the expected rate of
                                    return will fall but will remain floored at
                                    4% in the event that inflation dips into
                                    negative territory. Likewise, it will adjust
                                    upwards, should inflation continue to spike.
                                </p>
                            </div>
                        </div>
                    </div> */}
                        <div className="high_quality_loan_sec loan_quality_sec">
                            <div className="max-width">
                                <h2 className="high_quality_title">
                                    High quality loans:
                                </h2>
                                <Grid
                                    container
                                    spacing={1}
                                    className="loan_sec"
                                >
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box className="high_quality_loan_text">
                                            <h2>Solicitation edge</h2>
                                            <Box className="high_quality_info_data">
                                                <p>
                                                    Our multi-faceted
                                                    customer-merchant interface
                                                    differentiates us from other
                                                    fintech originators that
                                                    target the unbanked.
                                                </p>

                                                <p>
                                                    A cornucopia of rewards
                                                    normally associated with
                                                    specific store cards, is
                                                    made possible across
                                                    merchants by margin savings
                                                    unique to unplank.
                                                </p>

                                                <p>
                                                    This gives us an edge in
                                                    attracting prime and near
                                                    prime borrowers who need
                                                    credit that they can easily
                                                    pay off on flexible terms
                                                    and competitive rates.
                                                </p>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box className="high_quality_loan_text">
                                            <h2>Underwriting standards</h2>
                                            <Box className="high_quality_info_data">
                                                <p>
                                                    We undertake rigorous analysis of
                                                    income and expenditure data 
                                                    and watch for potential red flags 
                                                    for high debt to income ratios and 
                                                    evidence of recent delinquency elsewhere.
                                                </p>

                                                <p>
                                                    A cross-check against
                                                    traditional credit scores
                                                    further buffers our
                                                    underwriting as we set
                                                    credit limits to ensure that
                                                    our borrowers are easily
                                                    able to fulfil their
                                                    projected monthly
                                                    obligations.
                                                </p>

                                                <p>
                                                    We don’t rationalize
                                                    inability to pay back with
                                                    very high APRs as that is
                                                    only likely to precipate
                                                    deliquency.
                                                </p>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box className="high_quality_loan_text">
                                            <h2>Terms of credit</h2>
                                            <Box className="high_quality_info_data">
                                                <p>
                                                    Instalment credit on a
                                                    revolving basis not only
                                                    enhances user experience but
                                                    also creates its own
                                                    creditworthiness.
                                                </p>

                                                <p>
                                                    Borrowers are required to
                                                    pay-off weekly take-up of
                                                    credit, subject to a limit
                                                    over a twelve month period.
                                                    Interest is calculated on a
                                                    declining balance basis and
                                                    their credit limit is
                                                    replenished as older loans
                                                    re paid-off.
                                                </p>

                                                <p>
                                                    On-going demonstration of an
                                                    ability to pay-off
                                                    borrowings creates the
                                                    virtuous circle of lower
                                                    APRs and higher
                                                    creditworthiness.
                                                </p>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className="high_quality_loan_sec loan_fetures_sec loan_quality_sec">
                            <div className="max-width">
                                <h2 className="high_quality_title">
                                    Loan features:
                                </h2>
                                <Grid
                                    container
                                    spacing={1}
                                    className="loan_sec"
                                >
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box className="high_quality_loan_text">
                                            <h2>Diversified</h2>
                                            <Box className="high_quality_info_data">
                                                <p>
                                                    Daily spend across borrowers
                                                    is bundled into a single
                                                    loan.
                                                </p>

                                                <p>
                                                    For example, 100,000 users
                                                    borrowing $100 a day, on
                                                    average, amounts to a
                                                    $10,000,000 loan that can be
                                                    financed by one or more
                                                    lenders.
                                                </p>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box className="high_quality_loan_text">
                                            <h2>Short Duration</h2>
                                            <Box className="high_quality_info_data">
                                                <p>
                                                    As loans are paid-off in a
                                                    maximum of twelve
                                                    instalments, the expected
                                                    weighted average life is 6
                                                    months.
                                                </p>

                                                <p>
                                                    With automatic reinvestment
                                                    of principal paydowns and
                                                    interest, you may invest for
                                                    the long-term, while keeping
                                                    your underlying duration
                                                    risk low; an advantage, in a
                                                    rising rate environment, be
                                                    it on account of widening
                                                    credit spreads or rising
                                                    risk-free rates.
                                                </p>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={4} lg={4}>
                                        <Box className="high_quality_loan_text">
                                            <h2>Liquid</h2>
                                            <Box className="high_quality_info_data">
                                                <p>
                                                    Our borrower-facing 12-month
                                                    loans are structured into a
                                                    string of 30-day loans to
                                                    provision for montly
                                                    liquidity wherein another
                                                    lender may smoothly step in
                                                    without an unwind fee.
                                                </p>

                                                <p>
                                                    This allows us to widen the
                                                    competing pool of loanable
                                                    funds by making it amenable
                                                    to both short-term and
                                                    long-term investors.
                                                </p>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className="market_condition_sec">
                            <Grid container spacing={1}>
                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    // className="mobile_order"
                                >
                                    <Box className="market_condition_left">
                                        <h2>
                                            Expected rate of return is a dynamic
                                            function of inflation and market
                                            conditions.
                                        </h2>
                                        <div className="rate_return_sec">
                                            <p>
                                                <img
                                                    src="/images/square_box_white_icon.svg"
                                                    alt="icon"
                                                />
                                                As of June 2022,
                                                <br /> target rate of return set
                                                at 9% p.a.
                                            </p>
                                            <p>
                                                <img
                                                    src="/images/square_box_white_icon.svg"
                                                    alt="icon"
                                                />
                                                Expected variance +/- 2%
                                            </p>
                                        </div>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="market_condition_right">
                                        <h2>
                                            Private consumer credit compares
                                            very well on a duration adjusted
                                            basis.
                                        </h2>
                                        <Box className="table_detail_sec">
                                            <TableContainer className="table_container_sec">
                                                <Table
                                                    // className={classes.table}
                                                    aria-label="simple table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell align="right">
                                                                Yield
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                Duration (Years)
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell scope="row">
                                                                USD Inv. Grade
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                6.16%
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                6.6%
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell scope="row">
                                                                EUR Inv. Grade
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                4.44%
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                4.66%
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell scope="row">
                                                                EM Inv. Grade
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                6.32%
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                4.95%
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell scope="row">
                                                                USD High Yield
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                9.45%
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                4.17%
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell scope="row">
                                                                EUR High Yield
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                8.7%
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                3.2%
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell scope="row">
                                                                EM High Yield
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                12.66%
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                3.8%
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                        <Box className="bloomburg_date_sec ">
                                            <p>
                                                As of October 21, 2022. Source:
                                                Bloomberg
                                            </p>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        {/* <div className="borrower_characteristics_sec">
                        <div className="max-width">
                            <div className="borrower_text_sec">
                                <h2>Borrower characteristics:</h2>
                                <p>
                                    Our multi-faceted customer-merchant
                                    interface gives us an edge in attracting
                                    prime and near prime borrowers, who need
                                    sensibly priced credit that they can easily
                                    pay off given our flexible terms and
                                    competitive rates.
                                </p>
                                <p>
                                    Our customer facing APRs are competitively
                                    priced – our terms of instalment credit on a
                                    revolving basis creates its own
                                    creditworthiness. High headline APRs may be
                                    seemingly soothing for investors but they
                                    are self-fulling when it comes to high
                                    delinquencies. Distressed, is not how we
                                    want our borrowers to be.{" "}
                                </p>
                                <p>
                                    Our borrowers face a 12-month term on every
                                    week’s take-up of credit and are extended a
                                    limit in proportion to their disposable
                                    income to ensure that credit serves it
                                    purposes of smoothing spend over time and
                                    not snowballing into an unsurmountable
                                    burden.
                                </p>
                            </div>
                        </div>
                    </div> */}
                        {/* <div className="network_merchant_sec loan_features_sec">
                        <div className="max-width">
                            <h2>Loan features</h2>
                            <p>
                                Our customer facing 12-month loans are
                                structured into a string of 30-day loans to
                                provision for monthly liquidity and widen the
                                capital base by making it amenable to both short
                                term as well as long term investors.{" "}
                            </p>

                            <p>
                                The weighted average life of the loans is closer
                                six months but we enable automatic reinvestment
                                of principal paydowns and interest. You may
                                invest for the long term, while keeping your
                                underlying duration risk low – an advantage, in
                                a rising rate environment, be it on account of
                                widening credit spreads or rising risk-free
                                rates.
                            </p>
                        </div>
                    </div> */}
                        <div className="max-width">
                            <div className="customer_facing_sec">
                                <h2>
                                    Our customer-facing APRs are competitively
                                    set as a function of the target return and
                                    credit risk.
                                </h2>
                                <p>
                                    A fall in inflation will diminish the target
                                    rate of return which will follow-through
                                    into our customer-facing APRs. Similarly, a
                                    continued spike in inflation and/or
                                    appreciable rise in market interest rates
                                    will see them marked higher.
                                </p>
                                <p>
                                    Please contact us via the form below to
                                    register interest and access our
                                    lender-specific terms of use.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer footerRef={this.footerRef} />
            </>
        );
    }
}
