import {
    GET_STORES,
    DISABLE_STORE,
    ADD_STAFF,
    ADD_AISLE,
    DISABLE_STAFF,
    DISABLE_AISLE,
    UPDATE_AISLE,
    UNLINK_DEVICE,
    TOGGLE_CHAT
  } from "../types/activateMerchantApp";
import { LOGOUT_SUCCESS } from "../types/auth";
  
  const initState = []
  
  export default function storesReducer(state = initState, action) {
    switch (action.type) {
      case GET_STORES: return action.payload;

      case DISABLE_STORE: {
        const {storeId,  statusChangedStore} = action.payload;
        const modifiedState = state.map(store => {
          if(store.id === storeId){
            return statusChangedStore
          }
          else{
            return store
          }
        })
        return modifiedState
      } 

      case ADD_STAFF: {
        const {storeId, newStaff} = action.payload;
        const modifiedState = state.map(store => {
             return store.id === storeId ? {...store, staff : [{...newStaff, storeId}, ...store.staff]} : store;
        })
        return modifiedState
      }

      case DISABLE_STAFF: {
        const {storeId, staffId, statusChangedStaff} = action.payload;
        const modifiedState = state.map(store => {
          if(store.id === storeId){
            const newStaff = store.staff.map(singleStaff => {
              return singleStaff.id === staffId ? {...singleStaff, ...statusChangedStaff} : singleStaff
            })
            return {...store, staff: newStaff}
          }
          else{
            return store
          }
        })
        return modifiedState
      } 

      case ADD_AISLE: {
        const {storeId, newAisle} = action.payload;
        const modifiedState = state.map(store => {
             return store.id === storeId ? {...store, aisles : [newAisle, ...store.aisles]} : store;
        })
        return modifiedState
       }

      case UPDATE_AISLE: {
        const {storeId, aisleId, updatedAisle} = action.payload;
        const modifiedState = state.map(store => {
          if(store.id === storeId){
            const newAisles = store.aisles.map(aisle => {
              return aisle.id === aisleId ? updatedAisle : aisle
            })
            return {...store, aisles: newAisles}
          }
          else{
            return store
          }
        })
        return modifiedState
      }

      case DISABLE_AISLE: {
        const {storeId, aisleId, statusChangedAisle} = action.payload;
        const modifiedState = state.map(store => {
          if(store.id === storeId){
            const newAisles = store.aisles.map(aisle => {
              return aisle.id === aisleId ? statusChangedAisle : aisle
            })
            return {...store, aisles: newAisles}
          }
          else{
            return store
          }
        })
        return modifiedState
      } 


      case TOGGLE_CHAT: {
        const {storeId, aisleId, toggledChatState} = action.payload;
        const {isChatEnabled} = toggledChatState;
        const modifiedState = state.map(store => {
          if(store.id === storeId){
            const newAisles = store.aisles.map(aisle => {
              return aisle.id === aisleId ? {...aisle, isChatEnabled} : aisle
            })
            return {...store, aisles: newAisles}
          }
          else{
            return store
          }
        })
        return modifiedState
      } 

      case UNLINK_DEVICE: {
        const {storeId, aisleId} = action.payload;
        const modifiedState = state.map(store => {
          if(store.id === storeId){
            const newAisles = store.aisles.map(aisle => {
              return aisle.id === aisleId ? {...aisle, device: null, deviceId: null} : aisle
            })
            return {...store, aisles: newAisles}
          }
          else{
            return store
          }
        })
        return modifiedState
      }

      case LOGOUT_SUCCESS: {
        return initState;
      }


      default:
        return state;
    }
  }
  