import { Typography, Button } from "@material-ui/core";
import React from "react";
import { SelectInput, TextInput } from "../../forms/textinput";
import { COLORS } from "../../constants";
import { FONTS } from "../../constants";

const formContainer = {
  backgroundColor: COLORS.default_white,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxSizing: "border-box",
  width: "450px",
  margin: "auto",
  padding: "30px",
  boxShadow: "0 0 6px #ccc",
};
const inputWrapper = {
  width: "100%",
  // maxWidth: "350px",
  margin: "15px 0",
};
const sbtBtn = {
  backgroundColor: COLORS.juma_theme_clr,
  width: "180px",
  color: COLORS.default_white,
  borderRadius: 0,
  fontFamily: "PlusJakartaSans-Bold",
};
// const forgotPwd = {
//   float: "right",
//   fontSize: FONTS.size_minimum,
//   margin: 0,
//   color: COLORS.juma_theme_clr,
//   marginTop: "4px",
//   cursor: "pointer",
// };
const loginContainer = {
  width: "100%",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: "#F0F5FD",
  display: "flex",
};

export default class CreateMerchant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: { value: "" },
      ein: { value: "" },
      category: { value: "" },
      categoryList: [],
      is_experian_verified: false,
    };
  }

  goToSignIn = () => {
    this.props.history.push("/login");
  };

  keyPress = () => (e) => {
    if (e.key === "Enter") {
      this.onNext();
      e.preventDefault();
    }
  };

  render() {
    return (
      <div style={loginContainer} className="createMerchant">
        <div style={formContainer}>
          <img alt = 'Jumna Logo' src="/images/jumna_logo.svg" className="sign_up_logo" />
          <Typography
            style={{
              fontFamily: "PlusJakartaSans-Bold",
              marginTop: "10px",
              fontSize: FONTS.size_xtra,
            }}
          >
            Sign Up
          </Typography>
          <div style={inputWrapper}>
            <SelectInput
              style={{ width: "100%" }}
              onChange={this.onInputChange(`category`)}
              value={this.state.category.value}
              label={"Business Category"}
              options={this.state.categoryList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              isError={this.state.category.isError}
              errorMessage={this.state.category.errorMessage}
            ></SelectInput>
          </div>
          <div style={inputWrapper}>
            <TextInput
              style={{ width: "100%" }}
              onChange={this.onInputChange(`businessName`)}
              value={this.state.businessName.value}
              label={"Business Name"}
              isError={this.state.businessName.isError}
              errorMessage={this.state.businessName.errorMessage}
            ></TextInput>
          </div>
          <div style={inputWrapper}>
            <TextInput
              style={{ width: "100%" }}
              onChange={this.onInputChange(`ein`)}
              value={this.state.ein.value}
              isError={this.state.ein.isError}
              editable={!this.state.is_experian_verified}
              errorMessage={this.state.ein.errorMessage}
              label={"EIN"}
              onKeyPress={this.keyPress()}
            ></TextInput>
          </div>
          <div className="sign_up_next_sec" >
            <Button style={sbtBtn} onClick={this.onNext} className="login_btn">
              Next
            </Button>
            <div
              style={{
                margin: 0,
                fontSize: FONTS.size_minimum,
                marginTop: "5px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {" "}
              Already have an account?{" "}
              <div
                onClick={this.goToSignIn}
                className="fontBold"
                style={{
                  color: COLORS.juma_theme_clr,
                  cursor: "pointer",
                  marginLeft: 3,
                }}
              >
                Sign In{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
