import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { ConnectedRouter as Router } from "connected-react-router";
import { history } from "./store/store";
import ProtectedRoute from "./protectedRoute";
import RedirectRoute from "./redirectRoute";
import { connect } from "react-redux";
import Login from "./controllers/login";
import Dashboard from "./views/dashboard";
import {
    Backdrop,
    CircularProgress,
    makeStyles,
    Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Registration from "./controllers/registration/createMerchant";
import _SignUp from "./controllers/registration/signup";
import EmailOTP from "./views/registration/emailOTP";
import MerchantSignUp from "./controllers/registration/merchantSignUp";
import MerchantSignIn from "./controllers/registration/merchantSignIn";
import VerifyEmail from "./controllers/registration/verifyEmail";
import RegisterAccount from "./controllers/registration/registerAccount";
import ResetPassword from "./controllers/registration/resetPassword";
import SetPassword from "./controllers/registration/setPassword";
import TermsAndConditions from "./controllers/registration/termsAndConditions";
import ForgotPassword from "./controllers/registration/ForgotPassword";
import DeclinedUser from "./controllers/registration/declinedUser";
import VerifyUser from "./controllers/registration/verifyUser";
import MerchantLanding from "./views/landing/merchantLanding";
import LendersLanding from "./views/landing/lendersLanding";
import PrivacyPolicy from "./views/landing/privacyPolicy";
import TermsOfUse from "./views/landing/termsofuse";
import Home from "./views/landing/home";
import { getBusiness } from "./store/actions/rewards";
import RegProtectedRoute from "./RegProtectedRoute";
import OtpProtectedRoute from "./OtpProtectedRoute";
import HomeScreen from "./views/landing/homeNew";
import { ShopWithUnplank } from "./views/landing/shopWithUnplank";
import NewMerchantSignUp from './controllers/registration/newMerchantSignUp';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export function SimpleBackdrop({ open }) {
    const classes = useStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
class Routes extends Component {

    // async getMerchantData(){
    //     await this.props.getBusiness()
    // }

    componentDidMount() {
        if(this.props.userDetails?.token){
            const { location } = history;
            // this.getMerchantData()
            history.push(location.pathname.includes('/portal') ? location.pathname : '/portal/admin');
        }
    }
    render() {
        const { severity } = this.props;
        return (
            <Router history={history}>
                <SimpleBackdrop open={this.props.showLoader} />
                <Switch>
                    <ProtectedRoute path="/portal" component={Dashboard} />
                    <RedirectRoute path={`/login`} component={Login} />
                    <RedirectRoute path={`/create`} component={Registration} />
                    <Route path="/signup" component={_SignUp} />
                    <Route path="/email-otp" component={EmailOTP} />
                    {/* <Route path={`/home`} component={Home} /> */}
                    <Route path={`/home`} component={HomeScreen} />
                    <Route path={`/shop-with-unplank`} component={ShopWithUnplank} />
                    <Route path={`/merchant`} component={MerchantLanding} />
                    <Route path={`/lenders`} component={LendersLanding} />
                    <Route path={`/privacy-policy`} component={PrivacyPolicy} />
                    <Route path={`/terms-of-use`} component={TermsOfUse} />
                    <RedirectRoute path="/merchant-signup" component={MerchantSignUp} />
                    <RedirectRoute path="/merchant-signin" component={MerchantSignIn} />
                    <RedirectRoute path="/new-merchant-signup" component={NewMerchantSignUp}/>
                    <OtpProtectedRoute path="/validate-otp" component={VerifyEmail} />
                    <RegProtectedRoute path="/register" component={RegisterAccount} />
                    <RedirectRoute path="/forgot-password" component={ForgotPassword} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <RegProtectedRoute path="/set-password" component={SetPassword} />
                    <RegProtectedRoute path="/tandc" component={TermsAndConditions} />
                    <RegProtectedRoute path="/declined-user" component={DeclinedUser} />
                    <RegProtectedRoute path="/verifying-user" component={VerifyUser} />
                    {/* <RedirectRoute path={'/'} component={Home} /> */}
                    <RedirectRoute path={'/'} component={HomeScreen} />
                </Switch>
                {this.props.toaster ? (
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={this.props.toaster ? true : false}
                        autoHideDuration={3000}
                    >
                        <Alert
                            variant="filled"
                            severity={severity || "error"}
                            style={severity === 'success' ? {backgroundColor : '#1A7CFA'} : null}
                        >
                            {this.props.errorMessage}
                        </Alert>
                    </Snackbar>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
            </Router>
        );
    }
}
export default connect(
    ({
        auth,
        loader: {
            showLoader,
            toaster,
            errorMessage = "Something went wrong",
            severity,
        },
    }) => {
        let { userDetails } = auth;
        return {
            userDetails,
            showLoader,
            toaster,
            errorMessage,
            severity,
        };
    },
    // {getBusiness}
)(Routes);
