import React  from "react";
import { useSelector } from "react-redux";



export default function MyDetails(){
  const { businessData : merchantData } = useSelector((state) => state.auth);
    return (
        <div>
            <div className="detailsSec">
            </div>
            <div className="adminDetailsSec details_mob_row">
                <div className="businessColumn marginLeft">
                  <div className="businessLabel">First Name</div>
                  <div className="businessValue textCapitalize">{merchantData?.user?.firstName}</div>
                  <div className="businessLabel">SSN</div>
                  <div className="businessValue">---</div>
                </div>
                <div className="businessColumn margin_mob">
                  <div className="businessLabel">Last Name</div>
                  <div className="businessValue textCapitalize">{merchantData?.user?.lastName}</div>
                  <div className="businessLabel">DOB</div>
                  <div className="businessValue">{merchantData?.user?.dob || `---`}</div>
                </div>
                <div className="businessColumn margin_mob">
                  <div className="businessLabel">Title</div>
                  <div className="businessValue">{merchantData?.business?.ownerTitle}</div>
                </div>
            </div>
        </div>
    );
}