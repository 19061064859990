import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import Footer from "./footer";
import HeaderLanding from "./headerLanding";
import { PersonalSignUP } from "../../controllers/registration/newMerchantSignUp";

export function ShopWithUnplank() {
  const [open, setOpen] = useState(false);
  const shopLandingRef = useRef();
  const footerRef = useRef();
  const joinWaitList = () => {
    setOpen(true);
  };

  useEffect(() => {
    shopLandingRef.current?.scrollIntoView();
  }, []);

  return (
    <>
      {<Modal open={open} closeVideoPopup={() => setOpen(false)} />}
      <HeaderLanding
        handleAboutUs={() =>
          footerRef.current?.scrollIntoView({
            behavior: "smooth",
          })
        }
      />
      <Box style={{ paddingTop: "65px" }} ref={shopLandingRef}>
        <div className="landing_main_sec">
          <div className="shopUnplank_banner_sec">
            <img src="/images/shopunplank_banner.png" alt="shop with unplank" className="mobile_none"/>
            <img src="/images/shopunplank_banner_mobile.png" alt="shop with unplank" className="desktop_none"/>
            <div className="shopUnplank_banner_text max-width">
              <h2>
                Higher rewards. <br />
                Better rates.
              </h2>
              <h4>
                No cards. <br />
                No fees.No hassle.
              </h4>
              <p>
                Because what’s good for business <br /> is good for you.
              </p>
              <Button
                variant="contained"
                //size="large"
                onClick={() => {
                  joinWaitList();
                }}
              >
                Join waitlist
              </Button>
            </div>
          </div>
          <div className="credit_sec">
            {/* <div className="max-width"> */}
              <Grid container>
                <Grid xs={12} sm={12} md={6} lg={6}>
                  <div className="credit_content">
                    <h2>We designed credit like we’d want it for ourselves</h2>
                    <p>
                      But you can always use our debit facility,
                      <br /> if you’re only up for the rewards.
                    </p>
                  </div>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                  <div className="credit_type_sec">
                    <div className="flex_row alignItem_center">
                      <img src="/images/shopunplank_icon1.svg" alt="icon" />
                      <p>No minimum spend amount</p>
                    </div>
                    <div className="flex_row alignItem_center">
                      <img src="/images/shopunplank_icon2.svg" alt="icon" />
                      <p>Prepay at any time without penalty</p>
                    </div>
                    <div className="flex_row alignItem_center">
                      <img src="/images/shopunplank_icon3.svg" alt="icon" />
                      <p>Opt for auto-pay to get the lowest rate possible</p>
                    </div>
                    <div className="flex_row alignItem_center">
                      <img src="/images/shopunplank_icon4.svg" alt="icon" />
                      <p>
                        Pay progressively less interest as you pay off your
                        instalments
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            {/* </div> */}
          </div>
          <div className="credit_sec">
            {/* <div className="max-width"> */}
              <div className="transact_ease_sec">
                <img src="/images/shopunplank_image.png" alt="east transact" />
                <div className="transact_ease_content">
                  <h2>Transact with ease </h2>
                  <p>
                    You no longer need to upload a card into a wallet – from
                    behind two levels of security; that of your device and
                    Unplank’s password. And with peace of mind as we don’t sell
                    your data or track your shopping habits to sell you
                    competing products, unless you ask for it.
                  </p>
                  <p>
                    Always be alert to scammers; for added protection our
                    anti-fraud protocols keep merchants from contacting you
                  </p>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      </Box>
      <Footer footerRef={footerRef} />
    </>
  );
}

function Modal(props) {
  const { open } = props;
  return (
    <Dialog
      open={open}
      onClose={() => {
        props.closeVideoPopup();
      }}
      className="join_popup_sec"
      fullWidth={true}
      maxWidth="sm"
    >
      <Box>
        <Box
          onClick={() => {
            props.closeVideoPopup();
          }}
        >
          <img
            src="/images/close_menu.svg"
            alt="close"
            style={{ float: "right", cursor: "pointer" }}
          />
        </Box>
        <DialogTitle>Join waitlist</DialogTitle>
        <DialogContent>
          <PersonalSignUP closeVideoPopup={props.closeVideoPopup} />
        </DialogContent>
      </Box>
    </Dialog>
  );
}
