import React, { useState }  from "react";
import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Snackbar,
    TextField,
  } from "@material-ui/core";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { resetPassword } from "../../store/actions/auth";
import { Alert } from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";

  const changeButton = {
    width: "100%",
    background: "#1A7CFA",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0605059)",
    borderRadius: 2,
    color: 'white',
    marginTop: 40,
  }; 

  const textFieldStyle = {
    margin: "20px 0px",
  };

  const validationSchema = yup.object({
    oldPassword: yup.string().required('Type old password'),
    newPassword: yup.string().required('Type new password').matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must have min 8 characters length, one special character, one capital letter and one digit."
    ),
    confirmPassword: yup.string().required('Type new password again').test('Incorrect confirm password', 'Please type the same password as new password', (value, testContext) => value === testContext.parent.newPassword ),
  });

export default function PasswordReset(){

  const [isRevealOldPwd, setIsRevealOldPwd] = useState(false);
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
  const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);

    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const {confirmPassword, ...rest} = values
            await dispatch(resetPassword(rest));
            resetForm()
        },
      });

    
    return (
        <div>
            <form className="passwordResetSec" onSubmit={formik.handleSubmit}>

            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard"
              error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}>
                <InputLabel htmlFor="standard-adornment-password">
                  Old Password
                </InputLabel>
                <Input
                  id="oldPassword"
                  name="oldPassword"
                  style={textFieldStyle}
                  label="Old Password"
                  type={isRevealOldPwd ? "text" : "password"}
                  value={formik.values.oldPassword}
                  onChange={({target: {value}}) => {
                    if (/\s/g.test(value)) {
                      return
                    }
                    formik.setFieldValue("oldPassword", value)
                  }}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setIsRevealOldPwd((prevState) => !prevState)
                        }
                      >
                        {isRevealOldPwd ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {formik.touched.oldPassword && formik.errors.oldPassword}
                </FormHelperText>
              </FormControl>


              <FormControl sx={{ m: 1, width: "25ch" }} variant="standard"
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}>
                <InputLabel htmlFor="standard-adornment-password">
                  New Password
                </InputLabel>
                <Input
                  id="newPassword"
                  name="newPassword"
                  style={textFieldStyle}
                  label="New Password"
                  type={isRevealNewPwd ? "text" : "password"}
                  value={formik.values.newPassword}
                  onChange={({target: {value}}) => {
                    if (/\s/g.test(value)) {
                      return
                    }
                    formik.setFieldValue("newPassword", value)
                  }}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setIsRevealNewPwd((prevState) => !prevState)
                        }
                      >
                        {isRevealNewPwd ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {formik.touched.newPassword && formik.errors.newPassword}
                </FormHelperText>
              </FormControl>


              <FormControl sx={{ m: 1, width: "25ch" }} variant="standard"
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}>
                <InputLabel htmlFor="standard-adornment-password">
                  Confirm New Password
                </InputLabel>
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  style={textFieldStyle}
                  label="Confirm New Password"
                  type={isRevealConfirmPwd ? "text" : "password"}
                  value={formik.values.confirmPassword}
                  onChange={({target: {value}}) => {
                    if (/\s/g.test(value)) {
                      return
                    }
                    formik.setFieldValue("confirmPassword", value)
                  }}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setIsRevealConfirmPwd((prevState) => !prevState)
                        }
                      >
                        {isRevealConfirmPwd ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword}
                </FormHelperText>
              </FormControl>



                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    style={changeButton}
                    type='submit'
                >
                    Change
                </Button>
            </form> 
        </div>
    );
}