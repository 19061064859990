import { CALL_API } from "../api.middleware";
import {
  GET_STORES,
  DISABLE_STORE,
  DISABLE_STAFF,
  UPLOAD,
  UPLOAD_SUCCESS,
  ADD_STAFF,
  ADD_AISLE,
  DISABLE_AISLE,
  GET_AISLE_TOKEN,
  GET_STORE,
  UPDATE_AISLE,
  UNLINK_DEVICE,
  TOGGLE_CHAT
} from "../types/activateMerchantApp";

export const getStores = () => async (dispatch) => {
  try {
    const stores = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores`,
        showLoader: true,
        method: "GET",
        // showToast: true,
      },
    });
    dispatch({
      type: GET_STORES,
      payload: stores,
    });
    return stores;
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const uploadFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      var fd = new FormData();
      fd.append("file", file);
      return await dispatch({
        [CALL_API]: {
          url: `/files/files`,
          showLoader: true,
          method: "POST",
          types: [UPLOAD, UPLOAD_SUCCESS],
          isForm: true,
          // body: { file: file },
          body: fd,
          // showToast: true,
        },
      });
    } catch (error) {
      console.error(error)
      throw error
    }
  };

export const createStore = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/create`,
        showLoader: true,
        method: "POST",
        body: body,
        showToast: true,
        toastMessage: 'Store added successfully'
      },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getStore = (storeId) => async (dispatch) => {
  try {
    const storeData = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}`,
        showLoader: true,
        method: "GET",
        // showToast: true,
      },
    });
    return storeData;
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const updateStore = (storeId, body) => async (dispatch) => {
  try {
    const { id, businessId, active, staff, aisles, address: {id: addressId, ...restAddress} ,...rest } = body;
    const storeData = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}/update`,
        showLoader: true,
        method: "POST",
        body: {...rest, address: restAddress},
        showToast: true,
        toastMessage: 'Store updated successfully'
      },
    });
    return storeData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};



export const disableStore = (storeId, active) => async (dispatch) => {
  try {
    const statusChangedStore = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}/update-active-status`,
        showLoader: true,
        method: "POST",
        body: { active : active},
        showToast: true,
        toastMessage: 'Store is disabled'
      },
    });
    dispatch({
      type: DISABLE_STORE,
      payload: {storeId,  statusChangedStore: statusChangedStore}
    });
    return statusChangedStore;
  } catch (error) {
    console.error(error)
    throw error
  }
};


export const createStaff = (body, storeId) => async (dispatch) => {
  try {
    const newStaff = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}/staff/create`,
        showLoader: true,
        method: "POST",
        body: {user: body},
        showToast: true,
        toastMessage: 'User added successfully'
      },
    });
    dispatch({
      type: ADD_STAFF,
      payload: {storeId, newStaff: newStaff}
    });
    return newStaff;
  } catch (error) {
    console.error(error)
    throw error
  }
};


export const disableStaff = (storeId, staffId, active) => async (dispatch) => {
  try {
    const statusChangedStaff = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}/staff/${staffId}/update-active-status`,
        showLoader: true,
        method: "POST",
        body: { active : active},
        showToast: true,
        toastMessage: 'User is disabled'
      },
    });
    dispatch({
      type: DISABLE_STAFF,
      payload: {storeId, staffId, statusChangedStaff: statusChangedStaff}
    });
    return statusChangedStaff;
  } catch (error) {
    console.error(error)
    throw error
  }
};



export const createAisle = (body, storeId) => async (dispatch) => {
  try {
    const newAisle = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}/aisles/create`,
        showLoader: true,
        method: "POST",
        body: body,
        showToast: true,
        toastMessage: 'Device added successfully'
      },
    });
    dispatch({
      type: ADD_AISLE,
      payload: {storeId, newAisle: newAisle}
    });
    return newAisle;
  } catch (error) {
    console.error(error)
    throw error
  }
};



export const updateAisle = (body, storeId, aisleId) => async (dispatch) => {
  try {
    const updatedAisle = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}/aisles/${aisleId}/update`,
        showLoader: true,
        method: "POST",
        body: body,
        showToast: true,
        toastMessage: 'Device name updated'
      },
    });
    dispatch({
      type: UPDATE_AISLE,
      payload: {storeId, aisleId, updatedAisle: updatedAisle}
    });
    return updatedAisle;
  } catch (error) {
    console.error(error)
    throw error
  }
};




export const disableAisle = (storeId, aisleId, active) => async (dispatch) => {
  try {
    const statusChangedAisle = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}/aisles/${aisleId}/update-active-status`,
        showLoader: true,
        method: "POST",
        body: { active : active},
        showToast: true,
        toastMessage: 'Device is disabled'
      },
    });
    dispatch({
      type: DISABLE_AISLE,
      payload: {storeId, aisleId, statusChangedAisle: statusChangedAisle}
    });
    return statusChangedAisle;
  } catch (error) {
    console.error(error)
    throw error
  }
};





export const toggleChat = (storeId, aisleId, state) => async (dispatch) => {
  try {
    const toggledChatState = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}/aisles/${aisleId}/toggle-chat`,
        showLoader: true,
        method: "POST",
        showToast: true,
        toastMessage: `Chat for device is ${state}`
      },
    });
    dispatch({
      type: TOGGLE_CHAT,
      payload: {storeId, aisleId, toggledChatState: toggledChatState}
    });
    return toggledChatState;
  } catch (error) {
    console.error(error)
    throw error
  }
};





export const getAisleToken = (storeId, aisleId, minutesToExpire) => async (dispatch) => {
  try {
    const aisleToken = await dispatch({
      [CALL_API]: {
        url: `/businesses/stores/${storeId}/aisles/${aisleId}/tokens/create`,
        showLoader: true,
        method: "POST",
        body: { minutesToExpire : minutesToExpire},
        // showToast: true,
      },
    });
    return aisleToken;
  } catch (error) {
    console.error(error)
    throw error
  }
};


export const unlinkDevice = (storeId, aisleId, deviceId) => async (dispatch) => {
  try {
    const deviceData = await dispatch({
      [CALL_API]: {
        url: `/businesses/devices/${deviceId}/unlink`,
        showLoader: true,
        method: "POST",
        // showToast: true,
      },
    });
    dispatch({
      type: UNLINK_DEVICE,
      payload: {storeId, aisleId, deviceId, deviceData: deviceData}
    });
    return deviceData;
  } catch (error) {
    console.error(error)
    throw error
  }
};