import {
  AppBar,
  Toolbar,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

export default function OnBoardingHeader(){
  const history = useHistory();
  return(
    <>
      <AppBar position="fixed" elevation={1}>
        <Toolbar
          style={{
            justifyContent: "space-between",
            backgroundColor: "white",
          }}
        >
          <img
            src="/images/unplank.svg"
            onClick={() => history.push("/")}
            alt="unplank logo"
            className="boardingImg cursor"
          />
        </Toolbar>
      </AppBar>
    </>
  );
}
