import { CALL_API } from "../api.middleware";
import {
  TRANSFER_FUNDS,
  TRANSFER_FUNDS_SUCCESS,
} from "../types/balance";

export const getAccountBalance = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/payments/merchant/get_account_balance`,
        showLoader: true,
        method: "GET",
        // showToast: true,
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const transferBalance = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/payments/merchant/credit_to_bank`,
        showLoader: true,
        showToast: true,
        toastMessage: "Transfered Successfully.",
        method: "POST",
        types: [TRANSFER_FUNDS, TRANSFER_FUNDS_SUCCESS],
        body,
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};

export const getTipBalance = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/payments/merchant/get_tip_balance`,
        showLoader: true,
        // showToast: true,
        method: "GET",
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};
