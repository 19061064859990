import React, { useEffect, useRef, useState } from "react";

import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import { TextField } from "@material-ui/core";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const key = "AIzaSyBiI91bNz-ATp_uoz-FwzbX_z20BU7ONJs";
const DEFAULT_LOCATION = [40.712778, -74.006111];
function MapContainer({ location, style, onChange, disabled, address: add }) {
  const marker = useRef(null);
  const [selectedLatLng, setSelectedLatLng] = useState({
    lat: location?.lat || DEFAULT_LOCATION[0],
    lng: location?.lng || DEFAULT_LOCATION[1],
  });
  const showMarker = location && (location?.lat !== 0 || location?.lng !== 0);
  const [address, setAddress] = useState(add || "");

  Geocode.setApiKey(key);

  const getZipCode = (addressArray) => {
    let postal_code = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_code" === addressArray[i].types[0]
      ) {
        postal_code = addressArray[i].long_name;
        return postal_code;
      }
    }
  };

  const getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
        country = addressArray[i].long_name;
        return country;
      }
    }
  };

  const getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  const getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  const getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            // "sublocality_level_1" === addressArray[i].types[j] ||
            // "sublocality_level_2" === addressArray[i].types[j] ||
            "sublocality" === addressArray[i].types[j] ||
            "neighborhood" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j] ||
            "street_number" === addressArray[i].types[j] ||
            "route" === addressArray[i].types[j] ||
            "premise" === addressArray[i].types[j]
          ) {
            if (area !== "") {
              area = area + ", " + addressArray[i]?.long_name;
            } else {
              area = addressArray[i]?.long_name;
            }
          }
        }
      }
    }
    return area;
  };

  const getAddress = async ({ lat, lng }, storeName) => {
    /*
        https://developers.google.com/maps/documentation/places/web-service/supported_types
        The supported types are:
            establishment: instructs the Place Autocomplete service to return only business results.
        */
    const filterBy = "establishment";
    const response = await Geocode.fromLatLng(lat, lng);
    const filteredResult = response?.results.find((result) =>
      result.types.includes(filterBy)
    );
    const address =
      filteredResult?.formatted_address ||
      response.results[0].formatted_address;
    const addressArray =
      filteredResult?.address_components ||
      response.results[0].address_components;
    const city = getCity(addressArray);
    const area = getArea(addressArray);
    const state = getState(addressArray);
    const country = getCountry(addressArray);
    const zipCode = getZipCode(addressArray);
    onChange({
      location: { lat, lng },
      address: {
        address1: address,
        address2: area ? area : "",
        country: country ? country : "",
        zipCode: zipCode ? zipCode : "",
        city: city ? city : "",
        state: state ? state : "",
        googleApi: response,
      },
      storeName: storeName,
    });
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    try {
      console.log(address);
      const [first, ...rest] = address.split(",");
      console.log(first);
      setAddress(address);
      const results = await geocodeByAddress(address);
      console.log(results);
      const latLng = await getLatLng(results[0]);
      console.log(latLng);
      setSelectedLatLng({ lat: latLng.lat, lng: latLng.lng });
      marker.current.setOpacity(1);
      marker.current.setPosition(latLng);
      marker.current.map.setZoom(15);
      marker.current.setTitle(address);
      getAddress(latLng, first);
    } catch (error) {
      console.error(error);
    }
  };

  const renderMarkers = (map, maps) => {
    console.log(
      `Setting marker at lat: ${selectedLatLng.lat}, lng: ${selectedLatLng.lng}`
    );
    marker.current = new maps.Marker({
      position: { lat: selectedLatLng.lat, lng: selectedLatLng.lng },
      map,
    });
    if (!showMarker) {
      marker.current.setOpacity(0);
    }
  };

  useEffect(() => {
    if (disabled) {
      // marker?.current?.setPosition({lat: 0, lng: 0});
      marker?.current?.setOpacity(0);
    }
  }, [disabled]);

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: { country: "" },
          types: ["establishment"],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            style={{
              marginLeft: 2,
              position: "absolute",
              zIndex: 2,
              outline: "none",
              display: "flex",
              flexDirection: "column",
              width: "90%",
              top: 10,
            }}
            className="search-field"
          >
            <img
              src="/images/search_icon.svg"
              alt="search"
              style={{
                height: "20px",
                width: "20px",
                position: "absolute",
                zIndex: 10,
                top: 19,
                left: 10,
                marginRight: 10,
              }}
            />
            <TextField
              style={{
                fontSize: 14,
                fontFamily: "PlusJakartaSans-Regular",
                backgroundColor: "white",
              }}
              id="standard-basic"
              label="Search by store name"
              fullWidth={true}
              variant="outlined"
              margin="dense"
              value={address}
              // className="search-field"
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
              })}
              disabled={disabled}
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      padding: 8,
                      // width: 400,
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      padding: 8,
                      // width: 400,
                    };
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span
                      style={{
                        fontSize: 12,
                        fontFamily: "PlusJakartaSans-Regular",
                      }}
                    >
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: key,
          }}
          draggable={false}
          center={[selectedLatLng.lat, selectedLatLng.lng]}
          options={{
            panControl: false,
            fullscreenControl: false,
            zoomControl: disabled ? false : true,
            clickableIcons: false,
            keyboardShortcuts: false,
          }}
          defaultZoom={11}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          style={{ opacity: disabled ? "0.5" : "1" }}
        />
      </div>
    </div>
  );
}

export default MapContainer;
