import { Dialog, DialogTitle, DialogActions, Button, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  disableAisle,
  disableStaff,
  disableStore,
} from "../../store/actions/activateMerchantApp";
export default function DisableDialogComponent({
  openDisableDialog,
  setOpenDisableDialog,
  disableState,
}) {
  const { switchType, params } = disableState;
  const dispatch = useDispatch();
  async function disableAStore(storeId, active) {
    await dispatch(disableStore(storeId, active));
  }
  async function disableAStaff(storeId, staffId, active) {
    await dispatch(disableStaff(storeId, staffId, active));
  }
  async function disableAnAisle(storeId, aisleId, active) {
    await dispatch(disableAisle(storeId, aisleId, active));
  }
  function disableHandler() {
    if (switchType === "storeSwitch") {
      const { storeId, active } = params;
      disableAStore(storeId, active);
    }
    if (switchType === "staffSwitch") {
      const { storeId, staffId, active } = params;
      disableAStaff(storeId, staffId, active);
    }
    if (switchType === "aisleSwitch") {
      const { storeId, aisleId, active } = params;
      disableAnAisle(storeId, aisleId, active);
    }
    setOpenDisableDialog(false)
  }

  return (
    <>
      <Dialog
        open={openDisableDialog}
        onClose={() => setOpenDisableDialog(true)}
        className="conformPopUp"
      >
        <Box className="close_img">
          <img
            src="/images/close_menu.svg"
            alt="close"
            onClick={() => setOpenDisableDialog(false)}
          />
        </Box>
        <DialogTitle>Are you sure you want to Disable</DialogTitle>
        <DialogActions>
          <Button 
            color="primary" 
            variant="contained" 
            fullWidth 
            onClick={disableHandler}
            style={{marginRight: 10}}
          >
            Confirm
          </Button>
          <Button
            color="primary" 
            variant="outlined" 
            fullWidth
            onClick={() => setOpenDisableDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
