import { 
    AppBar,
    Toolbar,
 } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import OnBoardingHeader from "../../views/landing/onBoardingHeader";


export default function DeclinedUser() {
    const history = useHistory();
    return (
        <div>
            <OnBoardingHeader />
            <div className="signUpContainer">
                <div className="cardContainer">
                    <img src="/images/declined_user.svg" alt="declined user" className="decImgWidth"/>
                    <div className="decTextWrapper margin_bottom_40">
                        {/* <img src="images/info_icon.png" alt="info icon" className="infoImgWidth"/> */}
                        <p className="declineTxt">Sorry, we were not able to verify your business identity. Unplank account declined.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}