import React, { createRef } from "react";
import Footer from "./footer";
import HeaderLanding from "./headerLanding";

export default class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.privacyPageRef = createRef();
        this.footerRef = createRef();
    }
    componentDidMount() {
        this.privacyPageRef.current?.scrollIntoView();
    }

    render() {
        return (
            <>
                <HeaderLanding
                    handleAboutUs={() =>
                        this.footerRef.current?.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                />
                <div
                    style={{ paddingTop: "65px" }}
                    className="landingPage"
                    ref={this.privacyPageRef}
                >
                    <div className="privacy_landing_page">
                        <div className="privacy_policy_sec">
                            <div className="max-width">
                                <div className="privacy_heading">
                                    <h2>Privacy Policy</h2>
                                </div>
                                <div className="last_update_title">
                                    <h2>Last updated: Jun 29 2022</h2>
                                </div>
                                <div className="policy_detail_sec">
                                    <p>
                                        Unplank Inc., a Delaware corporation
                                        (the “Company”, “us”, “we”, or “our”)
                                        operates the web platform
                                        www.unplank.com and the associated
                                        Unplank App (the “Service”).
                                    </p>
                                    <p>
                                        This page informs you of our policies
                                        regarding the collection, use and
                                        disclosure of Personal Information
                                        (defined below) when you use our
                                        Service.
                                    </p>
                                    <p>
                                        We will not use or share your
                                        information with anyone except as
                                        described in this Privacy Policy.
                                    </p>
                                    <p>
                                        We use your Personal Information for
                                        providing and improving the Service. By
                                        using the Service, you agree to the
                                        collection and use of information in
                                        accordance with this policy. Unless
                                        otherwise defined in this Privacy
                                        Policy, terms used in this Privacy
                                        Policy have the same meanings as in our
                                        Terms of Use,accessible at{" "}
                                        <span
                                            onClick={() =>
                                                this.props.history.push(
                                                    "terms-of-use"
                                                )
                                            }
                                            className="link_text"
                                        >
                                            www.unplank.com/terms-of-use
                                        </span>
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Information Collection and Use</h2>
                                    <p>
                                        While using our Service, we ask you to
                                        provide us with certain personally
                                        identifiable information that can be
                                        used to contact or identify you.
                                        Personally identifiable information may
                                        include, but is not limited to, your
                                        email address, name, phone number,
                                        postal address, social security number,
                                        and other information (collectively,
                                        “Personal Information”).
                                    </p>
                                    <p>
                                        We collect this information for the
                                        purpose of providing the Service,
                                        complying with federal law, identifying
                                        and communicating with you, criminal
                                        activity prevention, managing risk,
                                        disputes and collections, responding to
                                        your requests and inquiries, and
                                        improving our services.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Location Data</h2>
                                    <p>
                                        We use Google Map APIs to track this
                                        information when you use our services to
                                        help us offer suggestions of merchants
                                        nearest to you and flag available
                                        promotions based on your general
                                        location.
                                    </p>
                                    <p>
                                        You may turn on or off our access to
                                        this information via your device’s
                                        account settings. Turning off our access
                                        to your location data will prevent us
                                        from providing our service to you.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Log Data</h2>
                                    <p>
                                        We may also collect information that
                                        your browser sends whenever you visit
                                        our Service (“Log Data”). Log Data may
                                        include information such as your
                                        computer's Internet Protocol (“IP”)
                                        address, browser type, browser version,
                                        the pages of our Service that you visit,
                                        the time and date of your visit, the
                                        time spent on those pages and other
                                        statistics.
                                    </p>
                                    <p>
                                        In addition, we may use third party
                                        services such as Google Analytics that
                                        collect, monitor and analyze this type
                                        of information in order to increase our
                                        Service's functionality. These
                                        third-party service providers have their
                                        own privacy policies addressing how they
                                        use such information.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Cookies</h2>
                                    <p>
                                        Cookies are files with a small amount of
                                        data, which may include an anonymous
                                        unique identifier. Cookies are sent to
                                        your browser from a website and
                                        transferred to your device. We use
                                        cookies to collect information in order
                                        to improve our services for you.
                                    </p>
                                    <p>
                                        You can instruct your browser to refuse
                                        all cookies or to indicate when a cookie
                                        is being sent. The Help feature on most
                                        browsers provides information on how to
                                        accept cookies, disable cookies or to
                                        notify you when receiving a new cookie.
                                    </p>
                                    <p>
                                        If you do not accept cookies, you may
                                        not be able to use some features of our
                                        Service and we recommend that you leave
                                        them turned on.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Do Not Track Disclosure</h2>
                                    <p>
                                        We support Do Not Track (“DNT”). Do Not
                                        Track is a preference you can set in
                                        your web browser to inform websites that
                                        you do not want to be tracked.
                                    </p>
                                    <p>
                                        You can enable or disable Do Not Track
                                        by visiting the Preferences or Settings
                                        page of your web browser.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Service Providers</h2>
                                    <p>
                                        We employ third party companies and
                                        individuals to facilitate our Service,
                                        to provide the Service on our behalf, to
                                        perform Service-related services or to
                                        assist us in analyzing how our Service
                                        is used.
                                    </p>
                                    <p>
                                        These third parties have access to your
                                        Personal Information only to perform
                                        specific tasks on our behalf and are
                                        obligated not to disclose or use your
                                        information for any other purpose
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Compliance with Laws</h2>
                                    <p>
                                        We will disclose your Personal
                                        Information where required to do so by
                                        law or subpoena or if we believe that
                                        such action is necessary to comply with
                                        the law and the reasonable requests of
                                        law enforcement or to protect the
                                        security or integrity of our Service.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Security</h2>
                                    <p>
                                        The security of your Personal
                                        Information is important to us, and we
                                        strive to implement and maintain
                                        reasonable, commercially acceptable
                                        security procedures and practices
                                        appropriate to the nature of the
                                        information we store, in order to
                                        protect it from unauthorized access,
                                        destruction, use, modification, or
                                        disclosure. However, please be aware
                                        that no method of transmission over the
                                        internet, or method of electronic
                                        storage is 100% secure and we are unable
                                        to guarantee the absolute security of
                                        the Personal Information we have
                                        collected from you.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>International Transfer</h2>
                                    <p>
                                        If you are located outside the United
                                        States and choose to provide information
                                        to us, please note that we transfer the
                                        information, including Personal
                                        Information, to the United States and
                                        process it there. Your consent to this
                                        Privacy Policy followed by your
                                        submission of such information
                                        represents your agreement to that
                                        transfer.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Children's Privacy</h2>
                                    <p>
                                        Only persons age 18 or older have
                                        permission to access our Service. Our
                                        Service does not address children under
                                        the age of 13.
                                    </p>
                                    <p>
                                        We do not knowingly collect personally
                                        identifiable information from children.
                                        If you are a parent or guardian and you
                                        learn that your children have provided
                                        us with Personal Information, please
                                        contact us. If we become aware that we
                                        have collected Personal Information from
                                        a child without verification of parental
                                        consent, we take steps to remove that
                                        information from our servers.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Changes to this Privacy Policy</h2>
                                    <p>
                                        This Privacy Policy is effective as of
                                        June 29, 2022 and will remain in effect
                                        except with respect to any changes in
                                        its provisions in the future, which will
                                        be in effect immediately after being
                                        posted on this page.
                                    </p>
                                    <p>
                                        We reserve the right to update or change
                                        our Privacy Policy at any time and you
                                        should check this Privacy Policy
                                        periodically. Your continued use of the
                                        Service after we post any modifications
                                        to the Privacy Policy on this page will
                                        constitute your acknowledgment of the
                                        modifications and your consent to abide
                                        and be bound by the modified Privacy
                                        Policy.
                                    </p>
                                    <p>
                                        If we make any material changes to this
                                        Privacy Policy, we will notify you
                                        either through the email address you
                                        have provided us or by placing a
                                        prominent notice on our website.
                                    </p>
                                </div>
                                <div className="privacy_policy_info">
                                    <h2>Contact Us</h2>
                                    <p>
                                        If you have any questions about this
                                        Privacy Policy, please contact us at
                                        <span
                                            onClick={() =>
                                                this.props.history.push(
                                                    "terms-of-use"
                                                )
                                            }
                                            className="link_text sub_link_text"
                                        >
                                            www.unplank.com/terms-of-use
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer footerRef={this.footerRef} />
            </>
        );
    }
}
