import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";


export default function CreateStoreDialog({ open, closeDialog }) {

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            className="bank_popup_sec"
        >
            <Box style={{ padding: 20 }}>
                <Box onClick={() => closeDialog()}>
                    <img
                        src="/images/close_menu.svg"
                        alt="close"
                        style={{ float: "right", cursor: 'pointer' }}
                    />
                </Box>
                <DialogTitle>
                    <Box className="VerifyAccountHeading">
                        <Typography>Create Storefront</Typography>
                        {/* <div className="commingSoonSec">
                            <img src="/images/comming_soon.svg" alt="comming soon" />
                            <p>coming soon!</p>
                        </div> */}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <div className="createStoreSec">
                        <div className="createStoreHeading">
                            <h2>
                                At first instance, you must{" "}
                                <span>create your storefront</span> that
                                will be visible on the customer app.
                            </h2>
                            <ul className="createStoreDetails">
                                <li>
                                    You may create as many stores as you
                                    like, so long as the funds received are
                                    all to be routed to the same bank
                                    account.
                                </li>
                                <li>
                                    You may edit your display names by
                                    branch, e.g., ABC store – Soho, ABC
                                    store – Nomad etc.
                                </li>
                            </ul>
                        </div>
                        <div className="createStoreHeading">
                            <h2>
                                Go to <span>“Manage Storefront” </span>on
                                the dashboard menu on the left-hand side and
                                follow the steps:
                            </h2>
                            <p>1. Add Store </p>
                            <p>2. Set location: </p>
                            <ul className="createStoreDetails">
                                <li> Use Google Maps</li>
                                <li>
                                    {" "}
                                    Or display your business address to
                                    customers while setting your location
                                    in-app, if you are on the move.
                                </li>
                            </ul>
                            <p>3. Upload an image</p>
                            <ul className="createStoreDetails">
                                <li>
                                    {" "}
                                    Either of your storefront or flagship
                                    product; make it look good!
                                </li>
                            </ul>
                            <p>
                                4. Confirm your choices to create your
                                storefront
                            </p>
                            <p>
                                5. Add users (other than yourself) for the
                                Unplank Merchant App &amp;
                            </p>
                            <ul className="createStoreDetails">
                                <li>
                                    Add a device/s on which the app will be
                                    downloaded.
                                </li>
                            </ul>
                            <h2>
                                Once you’ve created your storefront, we will
                                guide you to verifying your bank account
                                where we will send your monies.
                            </h2>
                        </div>
                    </div>
                </DialogContent>
            </Box>
        </Dialog>
    )
}