import React from "react";
import "./App.scss";
import { PersistGate } from "redux-persist/es/integration/react";
import { connect, Provider } from "react-redux";
import store, { history, persistor } from "./store/store";
import Routes from "./rootRouter";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}
