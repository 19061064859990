import { 
    Typography, 
    Button,
    AppBar,
    Toolbar,
    TextField,
    useMediaQuery,
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    IconButton,
    FormHelperText
 } from "@material-ui/core";
import React, { useState } from "react";
import { COLORS } from "../../constants";
import { FONTS } from "../../constants";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { forgotPassword, showToast, stopToast } from "../../store/actions/auth";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import OnBoardingHeader from "../../views/landing/onBoardingHeader";
 
const textFieldStyle = {
    margin: '20px 0px'
};
const btnContinue ={
    width: '100%',
    background: '#1A7CFA',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0605059)',
    borderRadius: 2,
    color: COLORS.default_white,
    marginTop: 30
};


const validationSchema = yup.object({
  password: yup
    .string()
    .required('Enter your password')
    .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Password must have min 8 characters length, one special character, one capital letter and one digit.'),
  confirmPassword: yup
    .string()
    .required('Enter your password again'),
});


export default function ResetPassword() {

  const isXsMobile = useMediaQuery("(max-width : 360px)");
  const isMobile = useMediaQuery("(max-width : 480px)");
  const isTablet = useMediaQuery("(max-width : 768px)");
  const isBigTablet = useMediaQuery("(max-width : 1180px)");

  const signUpContainer = {
    backgroundColor : isMobile || isXsMobile || isTablet ? "#FFFFFF" : "#F4F4F4",
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: '100vh',
    overflow: 'auto'
};

  const formContainer = {
    backgroundColor: COLORS.default_white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    width: isMobile || isXsMobile ? "94%" : isTablet || isBigTablet ? "50%" : "40%",
    maxWidth: 520,
    margin: " 30px auto",
    padding: isMobile || isXsMobile ? "20px 12px" : isTablet || isBigTablet ? 30 : 40,
  };
  const signUpMerchant={
    marginTop: isMobile || isXsMobile || isTablet || isBigTablet ? 80 : 120,
      fontSize: isMobile || isXsMobile || isTablet ? 28 : 32,
      fontWeight: 600,
      fontStyle: "PlusJakartaSans-Bold",
  };
  const inputWrapper = {
      margin: isMobile || isXsMobile || isTablet || isBigTablet ? 10 : 30,
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const {state} = useLocation();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if(values?.password !== values?.confirmPassword){
        dispatch(showToast("Password and confirm password are not same"))
        setTimeout(() => {
          dispatch(stopToast());
        }, 4000);
        return
      }
      if(values?.password === values?.confirmPassword) {
        const response = await dispatch(forgotPassword({token: state?.token, password: values?.password}));
        if (response?.user) history.push("/merchant-signin");
      }
      
    },
});

    return (
      <div>
        <OnBoardingHeader />
        <div style={signUpContainer} className="createMerchant">
          <Typography style={signUpMerchant}>Merchant Sign-in</Typography>
          <form onSubmit={formik.handleSubmit} style={formContainer}>
            <div style={inputWrapper} className="resetPasswordTxt">
              <Typography>Reset Password</Typography>



              <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <FormControl sx={{ m: 1, width: "25ch" }} variant="standard"
              error={formik.touched.password && Boolean(formik.errors.password)}>
                <InputLabel htmlFor="standard-adornment-password">
                  Set New Password
                </InputLabel>
                <Input
                  id="password"
                  name="password"
                  style={textFieldStyle}
                  label="Set New Password"
                  type={isRevealPwd ? "text" : "password"}
                  value={formik.values.password}
                  onChange={({target: {value}}) => {
                    if (/\s/g.test(value)) {
                      return
                    }
                    formik.setFieldValue("password", value)
                  }}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      >
                        {isRevealPwd ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {formik.touched.password && formik.errors.password}
                </FormHelperText>
              </FormControl>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <FormControl sx={{ m: 1, width: "25ch" }} variant="standard"
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}>
                <InputLabel htmlFor="standard-adornment-password">
                  Confirm New Password
                </InputLabel>
                <Input
                  id="confirmPassword"
                  name="confirmPassword"
                  style={textFieldStyle}
                  label="Confirm New Password"
                  type={isRevealConfirmPwd ? "text" : "password"}
                  value={formik.values.confirmPassword}
                  onChange={({target: {value}}) => {
                    if (/\s/g.test(value)) {
                      return
                    }
                    formik.setFieldValue("confirmPassword", value)
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setIsRevealConfirmPwd((prevState) => !prevState)
                        }
                      >
                        {isRevealConfirmPwd ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword}
                </FormHelperText>
              </FormControl>
            </div>

              <Button size="large" variant="contained" style={btnContinue} type='submit'>
                Continue
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
}
