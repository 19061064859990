import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { createAisle, updateAisle } from '../../store/actions/activateMerchantApp';
import { useLocation } from 'react-router-dom';

const validationSchema = yup.object({
  name: yup
    .string('Enter Device Name')
    .required('Device Name is required'),
});

export default function UpdateDeviceModal({ handleClose }) {
  const classes = useStyles();
  const tabletView = useMediaQuery('(max-width: 920px');
  const dispatch = useDispatch();
  const route = useRouteMatch();
  const storeId = route.params.storeid;
  const aisleId = route.params.aisleid;
  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      name: location.state.aisleName,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(updateAisle(values, storeId, aisleId));
      handleClose();
    },
  });
  return (
    <div
      className={
        tabletView ? classes.formContainerTabletView : classes.formContainerUpdate
      }
    >
      <Box className={classes.titleContainer}>
        <Typography className={classes.dialogTitle}>UPDATE DEVICE</Typography>
      </Box>
      <Box className='updateDevicePopUp'>
      <form onSubmit={formik.handleSubmit}>
        <Box
          className='fieldsContainer'
        >
          <Box style={{ marginTop: '30px' }}>
            <TextField
              fullWidth
              type="text"
              id="name"
              name="name"
              placeholder="Enter Device Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                formik.touched.name && Boolean(formik.errors.name)
              }
              helperText={
                formik.touched.name && formik.errors.name
              }
            />
          </Box>
          <Box style={{ marginTop: '50px', marginBottom: '20px' }}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </Box>
        </Box>
      </form>
      </Box>
    </div>
  );
}
