import { Button, Dialog, DialogActions, DialogTitle, Box } from "@material-ui/core";
import React from "react";
import { LOYALTY_EDIT_SCREEN } from "../../store/types/rewards";
import { formatDate } from "../../utils/commonFunc";

export default function SetLoyaltyConfirmation({open, setOpen, createLoyalty, values}) {
    // console.log(spend, reward, fromDate, toDate)
  return (
      <>
          <Dialog
              open={open}
              onClose={() => setOpen(false)}
              className="loyaltyPopUp"
          >
              <Box className="close_img">
                  <img
                      src="/images/close_menu.svg"
                      alt="close"
                      onClick={() => setOpen(false)}
                  />
              </Box>
              <Box className="discounts_popup">
                  <DialogTitle>
                      <div className="promoTxt">
                          {/* <img src="/images/blue_info_icon.svg" alt="info-icon" /> */}
                          <p>
                              {`You have set a promotion that will reward the customer ${
                                  values?.reward
                              }% when they spend $${values?.spend} between ${
                                values?.fromDate ? formatDate(values?.fromDate) : ""
                              } and ${values?.toDate ? formatDate(values?.toDate) : ""}`}
                          </p>
                      </div>

                      <div className="promoSec">
                          <img
                              src="/images/grey_info_icon.svg"
                              alt="info-icon"
                          />
                          <p>
                              Promotion cannot be edited or deleted after the
                              start date
                          </p>
                      </div>
                  </DialogTitle>
                  <DialogActions className="promoBtn">
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={() => createLoyalty(values)}
                      >
                          Confirm
                      </Button>
                      <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setOpen(false)}
                      >
                          Cancel
                      </Button>
                  </DialogActions>
              </Box>
          </Dialog>
      </>
  );
}
