import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware, connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import apiMiddleware from "./api.middleware";
import rootReducer from "./reducers/index";

export const history = createBrowserHistory();

const persistConfig = {
    key: "jumna-root",
    storage,
    whitelist: ["auth"],
};

const persistedReducer = persistReducer(
    persistConfig,
    rootReducer(connectRouter(history)) // root reducer with router state
);

const store = createStore(
    persistedReducer,
    applyMiddleware(thunk, apiMiddleware, routerMiddleware(history))
);
history.listen(() => {
    document.getElementById("top_header")?.scrollTo(0, 0);
});
export const persistor = persistStore(store);
export default store;
