import { Box, Grid } from "@material-ui/core";
import React, { createRef } from "react";
import Footer from "./footer";
import HeaderLanding from "./headerLanding";
export default class MerchantLanding extends React.Component {
    constructor(props) {
        super(props);
        this.merchantPageRef = createRef();
        this.footerRef = createRef();
    }
    componentDidMount() {
        this.merchantPageRef.current?.scrollIntoView();
    }

    componentDidUpdate() {
        this.merchantPageRef.current?.scrollIntoView();
    }
    render() {
        return (
            <>
                <HeaderLanding
                    handleAboutUs={() =>
                        this.footerRef.current?.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                />
                <div
                    style={{ paddingTop: "65px" }}
                    className="landingPage"
                    ref={this.merchantPageRef}
                >
                    <div className="merchant_landing_sec ">
                        <div className="merchant_banner_sec">
                            <img
                                src="/images/merchant_banner.png"
                                alt=""
                                className="mobile_none"
                            />
                            <img
                                src="/images/merchant_banner_mobile.png"
                                alt=""
                                className="desktop_none"
                            />
                            <div className="heading_tag_line heading_title_line max-width">
                                <p>connect direct. get more.</p>
                            </div>
                            <div className="merchant_banner_text max-width">
                                <h2>
                                    Get customers. Accept payment.
                                    <br /> No interchange or platform fees.
                                </h2>
                                <p>
                                    Create account and download app to get
                                    unplanked.
                                </p>
                            </div>
                        </div>
                        <div className="easy_steps_sec">
                            <Grid container>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="easy_step_left">
                                        <h2>
                                            Five easy steps to get unplanked.
                                        </h2>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="easy_step_right">
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Upload store image & set location
                                        </h2>
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Generate app user logins
                                        </h2>
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Link mobile device at first login
                                        </h2>
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Tailor customer acquisition strategy
                                        </h2>

                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Verify your bank account
                                        </h2>
                                        {/* <ul>
                                        <li>Create your account here </li>
                                        <li>
                                            Add store: fix address, upload store
                                            image & generate App user logins{" "}
                                        </li>
                                        <li>
                                            Link mobile device to accept
                                            payments{" "}
                                        </li>
                                        <li>Set customer promotion [opt.] </li>
                                        <li>
                                            Link your business bank account to
                                            receive funds
                                        </li>
                                    </ul> */}
                                        <p>
                                            ...and you are done in just minutes!
                                        </p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="max-width">
                            <div className="technology_sec">
                                <h2>
                                    Our technology supports everyone from sole
                                    proprietorships to medium-sized merchants
                                    and the retail giants.
                                </h2>

                                <h2>
                                    It’s a complementary solution. Requires no
                                    change to existing order management
                                    solutions.
                                </h2>

                                <h2>
                                    No cumbersome technology adaptation, either.
                                    Your existing mobile device or tablet is all
                                    you need to open your account and download
                                    our app.
                                </h2>
                            </div>
                        </div>
                        <div className="top_features_sec">
                            <Grid container>
                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    className="feature_dflex mobile_order"
                                >
                                    <Box className="top_fetures_left ul_circle_sec">
                                        <h2>Top features:</h2>
                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Get paid within three business days
                                        </p>
                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Self-operate or create employee
                                            logins
                                        </p>
                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Add multiple devices &/or stores
                                        </p>
                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Enjoy full data privacy
                                        </p>
                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Check amount before accepting
                                            payment
                                        </p>

                                        {/* <ul>
                                        <li>
                                            Get paid into your business bank
                                            account
                                            <br /> within three business days
                                        </li>
                                        <li>Add multiple stores</li>
                                        <li>
                                            Create logins for employees
                                            <br /> or self-operate
                                        </li>
                                        <li>
                                            Set up multiple devices per store{" "}
                                        </li>
                                        <li>
                                            Check payment amount
                                            <br /> before accepting
                                        </li>
                                    </ul> */}
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="top_fetures_right">
                                        <img
                                            src="/images/merchant-topfeatures-image.png"
                                            alt=""
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="top_features_sec">
                            <Grid container>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="top_fetures_right app_highlights">
                                        <img
                                            src="/images/david_img.png"
                                            alt=""
                                        />
                                        {/* <img
                                        src="/images/david_mobile_img.png"
                                        alt=""
                                        className="desktop_none"
                                    /> */}
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    className="feature_dflex"
                                >
                                    <Box className="top_fetures_left ul_circle_sec">
                                        <h2>App highlights:</h2>

                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Set in-app location
                                        </p>
                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Switch to auto-accept payments
                                        </p>
                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Enable chat to push payment requests
                                        </p>
                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            View daily payments
                                        </p>
                                        <p>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Process refunds
                                        </p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="merchant_break_sec">
                            <div className="merchant_break_banner">
                                <img
                                    src="/images/merchant-info-bg.png"
                                    alt="merchent info"
                                    className="mobile_none"
                                />
                                <img
                                    src="/images/merchant-info-bg_mobile.png"
                                    alt="merchent info"
                                    className="desktop_none"
                                />
                                <div className="merchant_break_banner_text">
                                    <h2 className="mobile_none">
                                        Keep your unplank <br />
                                        savings to yourself or use
                                        <br />
                                        them to acquire customers instead.
                                    </h2>
                                    <h2 className="desktop_none">
                                        Keep your unplank <br />
                                        savings to yourself or use
                                        <br />
                                        &nbsp; them to acquire customers
                                        instead.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="easy_steps_sec tailor_sec">
                            <Grid container>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="easy_step_left tailor_inner_sec">
                                        <h2>
                                            Tailor your customer acquisition
                                            strategy
                                        </h2>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="easy_step_right">
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Attract new customers with cashbacks
                                            and special one-off discounts
                                        </h2>
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Reward repeat spend
                                        </h2>
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Target larger share of wallet
                                        </h2>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="ditch_sec">
                            <Grid container className="ditch_inner_sec">
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="ditch_left_sec">
                                        <img
                                            src="/images/century_cards.png"
                                            alt=""
                                        />
                                    </Box>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    className="feature_dflex"
                                >
                                    <Box className="ditch_right_sec">
                                        <h2>
                                            Ditch those last-century cards. Get
                                            unplanked to reward loyalty.
                                        </h2>
                                    </Box>
                                </Grid>
                            </Grid>
                            <div className="max-width">
                                <div className="ditch_card_sec">
                                    <p>
                                        Those cards are a great concept but more
                                        often than not, customers lose them and
                                        there is no way for you to remind them
                                        to come back to collect on a pending
                                        reward.
                                    </p>
                                    <p>
                                        So, we took the idea and built a
                                        customizable platform you can use,
                                        whatever your business, to keep your
                                        customers coming back and to target a
                                        bigger share of their wallet.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="easy_steps_sec steps_share_wallet">
                            <Grid container>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="easy_step_left">
                                        <h2>
                                            3 steps to a larger
                                            <br />
                                            share of wallet.
                                        </h2>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="easy_step_right">
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Select your time window
                                        </h2>
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Set your spend threshold
                                        </h2>
                                        <h2>
                                            <img
                                                src="/images/square_box_icon.svg"
                                                alt="icon"
                                            />
                                            Choose your percentage discount
                                        </h2>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="network_merchant_sec reward_sec">
                            <div className="max-width">
                                <h2>
                                    Set your reward threshold above your average
                                    customer spend per visit and see your sales
                                    grow.
                                </h2>
                                <p>
                                    For example, you might tell your customers
                                    that if they spend, say $100 in a week, they
                                    get 5% cash back; after all, today you pay
                                    away much of that 5% in fees anyway!
                                </p>
                            </div>
                        </div>
                        {/* <div className="steps_wallet_sec">
                        <div className="steps_wallet_banner">
                            <img
                                src="/images/steps_bg_image.png"
                                alt="merchent info"
                            />
                            <div className="steps_wallet_banner_text">
                                <h2>3 steps to a larger share of wallet:</h2>
                                <h2>1. Select time window</h2>
                                <h2>2. Set a spend threshold</h2>
                                <h2>3. Choose percentage discount</h2>
                                <div className="steps_wallet_sub_sec">
                                    <p>
                                        Customer reads: if they spend, say $100
                                        in a week, they will get 5% in cash
                                        back. Remember, you pay away much of
                                        that 5% in fees anyways!{" "}
                                    </p>

                                    <p>
                                        Set your thresholds and rewards, in
                                        accordance with your average customer
                                        spend on each visit and see your sales
                                        grow.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    </div>
                </div>
                <Footer footerRef={this.footerRef} />
            </>
        );
    }
}
