import React from "react";
import { connect } from "react-redux";
import {
  createMerchant,
  getBusinessCategories,
  validateExperian,
} from "../../store/actions/auth";
import { validateFields } from "../../utils/commonFunc";
import CreateMerchant from "../../views/registration/createMerchant";

class _CreateMerchant extends CreateMerchant {
  onInputChange = (key) => (e) => {
    this.setState({ [key]: { value: e.target.value } });
  };

  async componentDidMount() {
    const categoryList = (await this.props.getBusinessCategories()) || {};
    this.setState({ categoryList });
  }

  onNext = async () => {
    const { category, ein, businessName } = this.state;
    const error = validateFields(
      {
        category: category.value,
        ein: ein.value,
        businessName: businessName.value,
      },
      (key) => {
        this.setState({
          [key]: {
            ...this.state[key],
            isError: true,
            errorMessage: `${key} is required`,
          },
        });
      }
    );
    if (!error) {
      const experian = await this.validateExperian();
      if (!!experian) {
        let queryParams = "";
        queryParams = ["category", `ein`, `businessName`]
          .map((key) => {
            return encodeURIComponent(
              `${key}=${JSON.stringify(this.state[key].value)}`
            );
          })
          .join("&");
        this.props.history.push(`signup?${queryParams}`);
      } else {
        this.setState({
          is_experian_verified: experian,
          ein: {
            ...this.state.ein,
            isError: true,
            errorMessage: "Please enter valid ein",
          },
        });
      }
    }
  };
  
  onSubmit = async () => {
    const body = {
      ein: this.state.ein.value,
      businessName: this.state.businessName.value,
      category: this.state.category.value,
    };
    let key = this.setErrorMessages();
    if (!key) {
      let res = await this.props.onLogin(body);
      if (res) {
        this.props.history.push("/portal");
      }
    } else {
      return this.setState({
        [key]: {
          ...this.state[key],
          isError: true,
          errorMessage: `Please enter valid ${key}`,
        },
      });
    }
  };

  validateExperian = async () => {
    let { is_experian_verified } =
      (await this.props.validateExperian({ ein: this.state.ein.value })) || {};
    return is_experian_verified || false;
  };
}

export default connect(
  ({ auth }) => {
    return { userDetails: auth.userDetails };
  },
  { getBusinessCategories, createMerchant, validateExperian }
)(_CreateMerchant);
