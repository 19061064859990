import { LOGOUT_SUCCESS } from '../types/auth';
import {
  CREATE_DISCOUNT_SUCCESS,
  CREATE_LOYALTY_SUCCESS,
  DISCOUNT_EDIT_SUCCESS,
  DISCOUNT_LIST_SUCCESS,
  EDIT_DISCOUNT,
  EDIT_LOYALTY_SUCCESS,
  LOYALTY_LIST_SUCCESS,
} from '../types/rewards';

const initState = {
  discounts: [],
  loyalty: [],
  editDiscountDetail: {},
};

export default function rewardsReducer(state = initState, action) {
  switch (action.type) {
    case DISCOUNT_LIST_SUCCESS: {
      const data = action.body;
      return { ...state, discounts: data };
    }

    case CREATE_DISCOUNT_SUCCESS: {
      const { data = {} } = action.body;
      return { ...state, discounts: [...state.discounts, data.discount] };
    }
    case DISCOUNT_EDIT_SUCCESS: {
      const { data = {} } = action.body;
      return {
        ...state,
        discounts: [
          ...state.discounts.filter(
            (item) => item.id !== (data.discount || {}).id
          ),
          data.discount,
        ],
      };
    }
    case LOGOUT_SUCCESS: {
      return initState;
    }
    case LOYALTY_LIST_SUCCESS: {
      const { loyalties = [] } = action.body;
      return { ...state, loyalty: loyalties };
    }

    case CREATE_LOYALTY_SUCCESS: {
      return { ...state, loyalty: [...state.loyalty, action.body] };
    }
    case EDIT_LOYALTY_SUCCESS: {
      const { body } = action;
      return {
        ...state,
        loyalty: [
          ...state.loyalty.filter((item) => item.id !== (body || {}).id),
          body,
        ],
      };
    }
    default:
      return state;
  }
}
