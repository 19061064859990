import { LOGOUT_SUCCESS, SHOW_TOAST, STOP_TOAST } from "./types/auth";
import { STOP_LOADING, START_LOADING } from "./types/loader.js";

export const NETWORK_FAILED = "Network request failed";

export const BASE_URL = "/api/v2";

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Cache-Control": "no-cache, no-store, must-revalidate",
  Pragma: "no-cache",
  Expires: 0,
};

const FORM_HEADERS = {
  // Accept: "application/json",
  // "Content-Type": "multipart/form-data",
};

class AuthError extends Error {}

export async function invokeAPI(
  endpoint,
  token,
  config,
  { isForm = false },
  headerContent = {}
) {
  const default_headers = isForm ? FORM_HEADERS : DEFAULT_HEADERS;

  let headers = token
    ? { ...default_headers, Authorization: `Bearer ${token}` }
    : { ...default_headers };
  const updatedConfig = {
    ...config,
    headers: { ...headers, ...headerContent },
  };

  const response = await fetch(BASE_URL + endpoint, updatedConfig);
  const body = await response.json();
  if (response.status === 401) {
    const { code } = body;
    throw new AuthError(code);
  }
  if (response.status >= 400) {
    const { __globals = [], code, ...errors } = body;
    const [firstError] = [...Object.values(errors), ...__globals];
    // alert(firstError || "Something went wrong");
    return {
      isError: true,
      errorMessage: firstError || "Something went wrong",
    };
  }
  return body;
}

export const CALL_API = "Call API";

const dispatchAction = (store) => (next) => async (action) => {
  if (typeof action[CALL_API] === "undefined") {
    return next(action);
  }

  let {
    url,
    method,
    types = [],
    showLoader = false,
    body = undefined,
    isForm = false,
    showToast = false,
    toastMessage = "fetched successfully",
    headerContent = {},
    params,
  } = action[CALL_API];

  const [requestType, successType] = types;
  const {
    auth: { userDetails = {}, regToken = null },
  } = store.getState();
  requestType && next({ type: requestType });
  try {
    if (showLoader) {
      next({ type: START_LOADING });
      //TODO: Dispatch show modal loader now.
    }
    const queryParams = new URLSearchParams();
    if (params) {
      for (let param in params) {
        if (params[param]) {
          queryParams.set(param, params[param]);
        }
      }
    }
    const responseBody = await invokeAPI(
      url + (params ? "?" + queryParams.toString() : ""),
      regToken ? regToken : userDetails.token,
      {
        method,
        body: isForm ? body : JSON.stringify(body),
      },
      { isForm },
      headerContent
    );
    if ((responseBody || {}).isError) {
      next({ type: SHOW_TOAST, message: responseBody.errorMessage });
      setTimeout(() => {
        next({ type: STOP_TOAST });
      }, 4000);
      throw new Error(responseBody.errorMessage);
    }
    if (showToast) {
      const data = responseBody;
      next({
        type: SHOW_TOAST,
        message: data.message || toastMessage,
        severity: "success",
      });
      setTimeout(() => {
        next({ type: STOP_TOAST });
      }, 4000);
    }
    successType &&
      next({
        body: responseBody,
        type: successType,
      });

    return responseBody;
  } catch (err) {
    if (err instanceof AuthError) {
      next({ type: SHOW_TOAST, message: `Please login to continue` });
      setTimeout(() => {
        next({ type: STOP_TOAST });
      }, 3000);
      next({ type: LOGOUT_SUCCESS });
    } else {
      throw err.message;
    }
  } finally {
    if (showLoader) {
      next({ type: STOP_LOADING });
      //TODO: Dispatch hide modal loader now.
    }
  }
};

export default dispatchAction;
