import React from 'react';
import { RouterOutLet } from '../dashboardFolder';
import {
	ADD_NEW_DISCOUNT_ROUTE,
	ADD_NEW_LOYALTY_ROUTE,
	CONDITIONAL_DISCOUNT_ROUTE,
	EDIT_DISCOUNT_ROUTE,
	EDIT_LOYALTY_ROUTE,
	ONE_OFF_DISCOUNT_ROUTE,
} from '../../constants';
import { DiscountAdd, DiscountList } from '../../views/rewards/discount';
import { LoyaltyAdd, LoyaltyList } from '../../views/rewards/loyaty';

export default class RewardsRoutes extends React.Component {
	render() {
		const { path } = this.props.match;
		const Routes = [
			{ path: `${ONE_OFF_DISCOUNT_ROUTE}`, component: DiscountRoutes },
			{ path: `${CONDITIONAL_DISCOUNT_ROUTE}`, component: LoyaltyRoutes },
		];
		return (
			<RouterOutLet
				Routes={Routes}
				// exactPath={{ path, to: `${ONE_OFF_DISCOUNT_ROUTE}/` }}
				exactPath={{ path, to: `${CONDITIONAL_DISCOUNT_ROUTE}/` }}
			/>
		);
	}
}

export function DiscountRoutes(props) {
	const { path } = props.match;

	const Routes = [
		{ path: `${ONE_OFF_DISCOUNT_ROUTE}/list`, component: DiscountList },
		{ path: `${ADD_NEW_DISCOUNT_ROUTE}`, component: DiscountAdd },
		{ path: `${EDIT_DISCOUNT_ROUTE}/:id`, component: DiscountAdd },
	];

	return (
		<RouterOutLet Routes={Routes} exactPath={{ path, to: `${path}/list` }} />
	);
}

export function LoyaltyRoutes(props) {
	const { path } = props.match;
	const Routes = [
		{ path: ADD_NEW_LOYALTY_ROUTE, component: LoyaltyAdd },
		{ path: `${EDIT_LOYALTY_ROUTE}/:id`, component: LoyaltyAdd },
		{ path: `${CONDITIONAL_DISCOUNT_ROUTE}/list`, component: LoyaltyList },
	];

	return (
		<RouterOutLet Routes={Routes} exactPath={{ path, to: `${path}/list` }} />
	);
}
