import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStaff } from '../../store/actions/activateMerchantApp';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const validationSchema = yup.object({
  firstName: yup.string('Enter your first name').required('First Name is required'),
  lastName: yup.string('Enter your last name').required('Last Name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required')
    // .test('UPPERCASE','Email should not be in uppercase',(value) => !/^[A-Z]+$/.test(value)),
});

export default function AddUserModal({ handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const route = useRouteMatch();
  const location = useLocation();
  const storeId = route.params.storeid;
  console.log("storeId",storeId);
  const tabletView = useMediaQuery('(max-width: 920px');
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleClose();
      await dispatch(createStaff(values, storeId));
    },
  });
  return (
    <div
      className={
        tabletView ? classes.formContainerTabletView : classes.formContainerModal
      }
    >
      <Box className={classes.titleContainer}>
        <Typography className={classes.dialogTitle}>ADD USER</Typography>
      </Box>
      <Box style={{padding: '20px 20px 30px'}}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          className='fieldsContainer'
        >
          <Box style={{ marginTop: '30px' }}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              placeholder="Enter First Name"
              autoComplete='off'
              value={formik.values.firstName}
              // onChange={formik.handleChange}
              onChange={({target: {value}}) => {
                formik.setFieldValue("firstName", value.trim() ? value : '')
              }}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Box>
          <Box style={{ marginTop: '30px' }}>
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              placeholder="Enter Last Name"
              autoComplete='off'
              value={formik.values.lastName}
              // onChange={formik.handleChange}
              onChange={({target: {value}}) => {
                formik.setFieldValue("lastName", value.trim() ? value : '')
              }}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Box>

          <Box style={{ marginTop: '30px' }}>
            <TextField
              fullWidth
              id="email"
              name="email"
              placeholder="Enter Email Id"
              autoComplete='off'
              value={formik.values.email}
              // onChange={formik.handleChange}
              onChange={({target: {value}}) => {
                formik.setFieldValue("email", value.trim() ? value : '')
              }}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>
        
          <Box style={{ marginTop: '50px', marginBottom: '10px' }}>
            <Button color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
              Submit
            </Button>
          </Box>
        </Box>
      </form>
      </Box>
    </div>
  );
}
