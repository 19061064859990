import {
  AppBar,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
// import { BUTTON_GREEN_COLOR } from "../constants";
import { COLORS, FONTS, SIDENAV_ITEMS } from "../constants";
import "../App.css";
import { connect } from "react-redux";
import { onLogout } from "../store/actions/auth";
import { leftNavStyles } from "./leftnav";

const SUPPORT_BASE_URL = process.env.REACT_APP_SUPPORT_BASE_URL;

const headerNav = {
  display: "flex",
  alignItems: "center",
};
const navItem = {
  color: COLORS.juma_theme_clr,
  fontSize: FONTS.size_medium,
  margin: "0 15px",
  cursor: "pointer",
};
const navItemLogout = {
  color: COLORS.default_black,
  fontSize: FONTS.size_medium,
  margin: "0 15px",
  cursor: "pointer",
  display: 'flex',
  alignItems: 'center'
}
const logoutImg = {
  marginRight : 8
}
export function TextInput({
  label = "",
  value = "",
  errorMessage = "",
  isError = false,
  ...props
}) {
  return (
    <TextField
      value={value}
      label={label}
      id={label}
      defaultValue="Small"
      size="small"
      helperText={errorMessage}
      error={isError}
      {...props}
    />
  );
}

export function SelectInput({
  label = "",
  value = "",
  options = [],
  placeHolder,
  isError = false,
  errorMessage = "",
  ...props
}) {
  return (
    <FormControl  error={isError}>
      <InputLabel id="demo-simple-select-error-label">{label}</InputLabel>
      <Select value={value} id={label} size="small" {...props}>
        {placeHolder && (
          <MenuItem value="none">
            {" "}
            <em>{placeHolder}</em>
          </MenuItem>
        )}
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export function Buttton({ label = "", style = {}, ...props }) {
  return (
    <div
      className="customBtn"
      {...props}
      style={{
        backgroundColor: COLORS.juma_theme_clr,
        padding: 8,
        color: COLORS.default_white,
        cursor: "pointer",
        ...style,
      }}
    >
      <Typography style={{ textAlign: "center" }}>{label}</Typography>
    </div>
  );
}

function Header({ header = "Jumna", token, ...props }) {
  const [show, showOverLay] = useState(false);
  const {
    eachNavItem,
    activeNavItem,
    navText,
  } = leftNavStyles;
  const { path } = props;
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar
          style={{
            justifyContent: "space-between",
            backgroundColor: COLORS.default_white,
          }}
        >
          <img
            src="/images/unplank.svg"
            alt="website logo"
          />
          <div className="webHeader">
            {token ? (
              <p
                onClick={() => props.onLogout()}
                className="fontBold"
                style={navItemLogout}
              >
                <img
                  src="/images/logout_inactive.svg"
                  alt="logout"
                  style={logoutImg}
                />
                Logout
              </p>
            ) : (
              <div style={headerNav}>
                <p
                  onClick={() => props.history.push("/resetPswd")}
                  className="fontBold"
                  style={navItem}
                >
                  {/* How it Works */}
                  Reset Pswd
                </p>
                <p
                  onClick={() => props.history.push("/setPswd")}
                  className="fontBold"
                  style={navItem}
                >
                  {/* How it Works */}
                  Set Pswd
                </p>
                <p
                  onClick={() => props.history.push("/merchant-signin")}
                  className="fontBold"
                  style={navItem}
                >
                  {/* Jumna Advantage */}
                  New SignIn
                </p>
                <p
                  onClick={() => props.history.push("/register")}
                  className="fontBold"
                  style={navItem}
                >
                  Reg Account
                </p>
                <p
                  onClick={() => props.history.push("/tandc")}
                  className="fontBold"
                  style={navItem}
                >
                  T&C
                </p>
                {/* <p 
                  onClick={() => props.history.push("/merchant-signup")}
                  className="fontBold" 
                  style={navItem}>
                  New SignUp
                </p>
                <p 
                  onClick={() => props.history.push("/verify-email")}
                  className="fontBold" 
                  style={navItem}>
                  Verify Email
                </p> */}
                <p
                  onClick={() => props.history.push("/merchant-signup")}
                  className="fontBold"
                  style={navItem}
                >
                  Create Account
                </p>
                <p
                  onClick={() => props.history.push("/login")}
                  className="fontBold"
                  style={navItem}
                >
                  Business Sign-In
                </p>
              </div>
            )}

            {/* {!!isAuthorized && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>Logout</Typography>
            </div>
          )} */}
          </div>
          <div className="mobileHeader">
            <img
              alt="toggle menu"
              onClick={() => showOverLay(!show)}
              src={
                show ? "/images/close_menu.svg" : "/images/blue_menu_icon.png"
              }
              style={{ height: "25px" }}
            />

            {/* Show hide this section for mobile menu */}
            {show && (
              <div className="menuOverlay">
                <div className="menuNavItems">
                  <div className="margin_bottom_40">
                    <img
                      onClick={() => showOverLay(!show)}
                      src={show ? "/images/close_white_icon_small.svg" : ""}
                      alt="close"
                      className="closeImg"
                    />
                  </div>
                  {/* <p className="fontBold mobileMenuItem">How it Works</p>
                  <p className="fontBold mobileMenuItem">Jumna Advantage</p>
                  <p className="fontBold mobileMenuItem">Create Account</p> */}

                  {token ? (
                    SIDENAV_ITEMS.map(
                      ({
                        activeImage,
                        label,
                        keyRoute,
                        externalLink = false,
                      }) =>
                        !externalLink ? (
                          <div
                            className="mobile_side_nav_item mob_dashboard_sidenav"
                            onClick={() => {
                              if (label === "Logout") {
                                props.onLogout();
                                return;
                              }
                              showOverLay(false);
                              props.history.push(`${path}${keyRoute}`);
                            }}
                            key={label}
                            // style={{
                            //   ...eachNavItem,
                            //   ...activeNavItem,
                            //   borderColor: "white",
                            //   backgroundColor: "white",
                            // }}
                          >
                            {/* <img alt="navigation icon" style={{ width: 18 }} src={activeImage} /> */}
                            <p style={navText}>{label}</p>
                          </div>
                        ) : (
                          <a
                            href={`${SUPPORT_BASE_URL}/ticketing/redirect?session=${token}`}
                            target="_blank"
                            style={{
                              textDecoration: "none",
                              color: "unset",
                            }}
                          >
                            <div
                              className="mobile_side_nav_item mob_dashboard_sidenav cutomerSidenav"
                            >
                              <p style={navText}>{label}</p>
                              <img
                                src="/images/customer_support_blue.png"
                                className="cutomerSidenavImg"
                                alt="launch customer support"
                              />
                            </div>
                          </a>
                        )
                    )
                  ) : (
                    <p
                      onClick={() => props.history.push("/login")}
                      className="fontBold mobileMenuItem"
                    >
                      Business Sign-In
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default connect(
  ({ auth }) => {
    const { token } = auth?.userDetails || {};
    return { token };
  },
  { onLogout }
)(Header);
