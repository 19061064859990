import { Redirect, Route, Switch } from "react-router";
import React from "react";
import Rewards from "../views/rewards/rewards";
import Balance from "../controllers/balance/balance";
import CreditNotes from "../views/credit-notes";
import StoresRouter from "../merchant-stores/router";
import Admin from "../controllers/admin/admin";
import Faq from "../views/faq";
import PendingStatement from "../statement/PendingStatement";


export class RouterOutLet extends React.Component {
    render() {
        const { Routes, exactPath } = this.props;
        return (
            <Switch>
                {Routes.map((route) => (
                    <Route
                        exact={route.exact}
                        key={route.path}
                        path={route.path}
                        component={route.component}
                    />
                    
                ))}
                <Route
                    path={`${exactPath.path}`}
                    exact
                    render={(props) => <Redirect to={exactPath.to} />}
                />
            </Switch>
        );
    }
}

export default class MyDashboardRoutes extends React.Component {
    render() {
        const { path } = this.props.computedMatch;
        const Routes = [
            { path: `${path}/set-rewards`, component: Rewards },
            { path: `${path}/balance`, component: Balance, exact: true },
            { path: `${path}/credits`, component: CreditNotes },
            { path: `${path}/balance/pending-statement`, component: PendingStatement },
            { path: `${path}/stores`, component: StoresRouter },
            { path: `${path}/admin`, component: Admin },
            { path: `${path}/faq`, component: Faq },
        ];
        return (
            <RouterOutLet
                Routes={Routes}
                exactPath={{ path, to: `${path}/balance` }}
            />
        );
    }
}
