import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  FormHelperText
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../../constants";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getBusinessCategories, merchantRegister } from "../../store/actions/auth";
import { useHistory, useLocation } from "react-router";
import US_States_and_Cities from '../../data/US_States_and_Cities.json';
import OnBoardingHeader from "../../views/landing/onBoardingHeader";

const back = {
  backgroundColor: "red",
};

const btnContinue = {
  width: "18rem",
  background: "#1A7CFA",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0605059)",
  borderRadius: 2,
  color: COLORS.default_white,
  marginTop: 30,
  padding: "0.8rem 0",
};

const btnHolder = {
  display: "flex",
  justifyContent: "center",
};

const validationSchema = yup.object({
  ownerFirstName: yup.string().required("firstname is required"),
  ownerLastName: yup.string().required("lastname is required"),
  ownerTitle: yup.string().required("title is required"),
  category: yup.string().required("business category is required"),
  name: yup.string().required("name of business is required"),
  streetAddress1: yup.string().required("address is required"),
  suit: yup.string(),
  city: yup.string().required("city is required"),
  state: yup.string().required("state is required"),
  zipCode: yup
    .string()
    .required("zipCode is required")
    .min(5, 'Zip code must be 5 digits')
    .max(5, 'Zip code must be 5 digits')
    .test('zipCode', 'invalid zip code',(value) => !/^(0{5}|999[5-9][0-9])$/.test(value)),
  country: yup.string().required("country is required"),
});

export default function RegisterAccount() {
  const isIphoneSE = useMediaQuery("(max-width: 320px)");
  const isXsMobile = useMediaQuery("(max-width : 360px)");
  const isMobile = useMediaQuery("(max-width : 480px)");
  const isTablet = useMediaQuery("(max-width : 768px)");
  const isBigTablet = useMediaQuery("(max-width : 1180px)");

  
  const regRef = useRef();
  useEffect(() => {
    const handleOnLoad = () => {
      regRef.current?.scrollIntoView();
    };
    handleOnLoad();
  }, [])
  
  
  const signUpContainer = {
    backgroundColor: isMobile || isXsMobile || isTablet ? "#FFFFFF" : "#F4F4F4",
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "auto",
  };

  const formContainer = {
    backgroundColor: COLORS.default_white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    width: isMobile || isXsMobile || isTablet || isBigTablet ? "94%" : "80%",
    maxWidth: 1055,
    margin: isMobile ? "0px auto" : " 30px auto",
    padding: isIphoneSE ? "0px 12px" : isMobile || isXsMobile ? "20px 12px" : isTablet || isBigTablet ? 30 : 40,
  };
  const signUpMerchant = {
    marginTop: isMobile || isXsMobile || isTablet || isBigTablet ? 80 : 120,
    fontSize: isMobile || isXsMobile || isTablet ? 28 : 32,
    fontWeight: 600,
    fontStyle: "PlusJakartaSans-Bold",
  };
  const emailInputWrapper = {
    margin: isMobile || isXsMobile || isTablet || isBigTablet ? 10 : "10px 30px",
  };
  const textInputSec = {
    width: isMobile || isXsMobile || isTablet || isBigTablet ? "100%" : "40%",
    margin: "24px auto",
  };

  const [cityList, setCityList] = useState([]);
  const [stateList,setStateList] = useState([]);
  const [category, setCategory] = useState([]);

  const handleChange = (event) => {
    formik.setFieldValue('category', event.target.value);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const {state = {}} = useLocation();
  // console.log(state?.token)
  const formik = useFormik({
    initialValues: {
      ownerFirstName: "",
      ownerLastName: "",
      ownerTitle: "",
      category: "",
      name: "",
      streetAddress1: "",
      suit: "",
      city: "",
      state: "",
      zipCode: "",
      country: "United States",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      try{
      const accountCreated = await dispatch(merchantRegister(state?.token, {...values, ein: ""}))
      if(accountCreated.status === "pending-tnc-acceptance"){
        history.replace('tandc')
      }
      if(accountCreated.status === "pending-enigma-verification"){
        history.replace("verifying-user")
      }
      if(accountCreated.status === "enigma-rejected"){
        history.replace("declined-user")
      }
    }
    catch(e){
      console.error(e)
    }
    },
  });


  const zipCodeHandler = (value) => {
    if (!/^[\d]*$/.test(value)) {
      return;
    }
    formik.setFieldValue("zipCode", value)
  }

  useEffect(()=>{
      async function getCategory() {
        const category = await dispatch(getBusinessCategories());
        setCategory(category)
      }
      getCategory();
  }, []);

  useEffect(() => {
    const items = Object.entries(US_States_and_Cities)
      const cities = Object.values(US_States_and_Cities)
      let cityList = []
      for (var i = 0; i < cities.length; i++) {
        cityList = cityList.concat(cities[i]);
      }

      setCityList([...new Set(cityList)])
      if(formik?.values?.city){
        const filteredStates = items.reduce((acc, item) => {
          if(item[1].includes(formik?.values?.city)){
            acc.push(item[0])
          }
          return acc
        }, [])
        setStateList(filteredStates)
      }
  }, [formik.values.city])
  // console.log(formik.values)
  return (
    <div>
      <OnBoardingHeader />
      <div style={signUpContainer} className="createMerchant" ref={regRef}>
        <Typography style={signUpMerchant}>Register Account</Typography>
        <form onSubmit={formik.handleSubmit} style={formContainer}>
          <div style={emailInputWrapper}>
            <div
              className="regAdressSec"
              style={{
                flexDirection:
                  isMobile || isXsMobile || isTablet || isBigTablet
                    ? "column"
                    : "row",
              }}
            >
              <TextField
                id="ownerFirstName"
                label="First Name"
                variant="standard"
                style={textInputSec}
                className="registerFormTxt"
                name="ownerFirstName"
                autoComplete="off"
                value={formik.values.ownerFirstName}
                onChange={({target: {value}}) => {
                  if(!/^[\da-zA-Z ]*$/.test(value)){
                    return
                  }
                  formik.setFieldValue("ownerFirstName", value.trim().length ? value : "")
                }}
                error={
                  formik.touched.ownerFirstName &&
                  Boolean(formik.errors.ownerFirstName)
                }
                helperText={
                  formik.touched.ownerFirstName && formik.errors.ownerFirstName
                }
                inputProps={{ style: { textTransform: "capitalize" } }}
              />

              <TextField
                id="ownerLastName"
                label="Last Name"
                variant="standard"
                style={textInputSec}
                className="registerFormTxt"
                name="ownerLastName"
                autoComplete="off"
                value={formik.values.ownerLastName}
                onChange={({target: {value}}) => {
                  if(!/^[\da-zA-Z ]*$/.test(value)){
                    return
                  }
                  formik.setFieldValue("ownerLastName", value.trim().length ? value : "")
                }}
                error={
                  formik.touched.ownerLastName &&
                  Boolean(formik.errors.ownerLastName)
                }
                helperText={
                  formik.touched.ownerLastName && formik.errors.ownerLastName
                }
                inputProps={{ style: { textTransform: "capitalize" } }}
              />

              <TextField
                id="ownerTitle"
                label="Title(eg: Owner/Legal Representative)"
                variant="standard"
                style={textInputSec}
                className="registerFormTxt"
                name="ownerTitle"
                autoComplete="off"
                value={formik.values.ownerTitle}
                onChange={({target: {value}}) => {
                  if(!/^[\da-zA-Z ]*$/.test(value)){
                    return
                  }
                  formik.setFieldValue("ownerTitle", value.trim().length ? value : "")
                }}
                error={
                  formik.touched.ownerTitle && Boolean(formik.errors.ownerTitle)
                }
                helperText={
                  formik.touched.ownerTitle && formik.errors.ownerTitle
                }
              />

              <FormControl
                style={textInputSec}
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                className="registerFormTxt"
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
              >
                <InputLabel id="businessCategoryLabel">
                  Business Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="category"
                  name="category"
                  value={formik.values.category}
                  onChange={handleChange}
                  label="Business Category"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {category.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>
                  {formik.touched.category && formik.errors.category}
                </FormHelperText>
              </FormControl>

              <TextField
                id="name"
                label="Name of Business"
                variant="standard"
                style={textInputSec}
                className="registerFormTxt"
                name="name"
                autoComplete="off"
                value={formik.values.name}
                onChange={({target: {value}}) => {
                  formik.setFieldValue("name", value.trim().length ? value : "")
                }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <div style={textInputSec} className="mobile_none_reg"></div>

              <TextField
                id="streetAddress1"
                label="Street Address"
                variant="standard"
                style={textInputSec}
                className="registerFormTxt"
                name="streetAddress1"
                autoComplete="off"
                value={formik.values.streetAddress1}
                onChange={({target: {value}}) => {
                  formik.setFieldValue("streetAddress1", value.trim().length ? value : "")
                }}
                error={
                  formik.touched.streetAddress1 &&
                  Boolean(formik.errors.streetAddress1)
                }
                helperText={
                  formik.touched.streetAddress1 && formik.errors.streetAddress1
                }
                inputProps={{ style: { textTransform: "capitalize" } }}
              />

              <TextField
                id="suit"
                label="Street Address 2"
                variant="standard"
                style={textInputSec}
                className="registerFormTxt"
                name="suit"
                autoComplete="off"
                value={formik.values.suit}
                onChange={({target: {value}}) => {
                  formik.setFieldValue("suit", value.trim().length ? value : "")
                }}
                error={formik.touched.suit && Boolean(formik.errors.suit)}
                helperText={formik.touched.suit && formik.errors.suit}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />


                <Autocomplete
                  id="city"
                  disableCloseOnSelect = {false}
                  freeSolo={true}
                  style={textInputSec}
                  className="registerFormTxt"
                  options={cityList}
                  value={formik.values.city}
                  onChange={(event, newCity) =>
                    formik.setFieldValue("city", newCity?.trim().length ? newCity : "")
                  }
                  onInputChange={(event, newCity) => {
                    formik.setFieldValue("city",  newCity?.trim().length ? newCity : "");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="City" variant="standard"
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    />
                  )}  
                  filterOptions= {(options) => formik.values.city ? options.filter((option) => option.toLowerCase().includes(formik.values.city?.toLowerCase())) : options}             
                />


              <Autocomplete
                id="state"
                disableCloseOnSelect = {false}
                freeSolo={true}
                style={textInputSec}
                className="registerFormTxt"
                options={stateList}
                value={formik.values.state}
                onChange={(event, newState) =>
                  formik.setFieldValue("state", newState?.trim().length ? newState : "")
                }
                onInputChange={(event, newState) => {
                  formik.setFieldValue("state",  newState?.trim().length ? newState : "");
                }}
                renderInput={(params) => (
                  <TextField {...params} label="State" variant="standard"
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                  />
                )}
                filterOptions= {(options) => formik.values.state ? options.filter((option) => option.toLowerCase().includes(formik.values.state?.toLowerCase())) : options}             
              />


              <TextField
                id="zipCode"
                label="Zip Code"
                variant="standard"
                style={textInputSec}
                className="registerFormTxt"
                name="zipCode"
                autoComplete="off"
                inputProps={{ inputMode: 'numeric' }}
                value={formik.values.zipCode}
                onChange={({target: {value}}) => zipCodeHandler(value)}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
              />

              <TextField
                id="country"
                label="Country"
                variant="standard"
                style={textInputSec}
                className="registerFormTxt"
                name="country"
                autoComplete="off"
                disabled={true}
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            </div>
            <div style={btnHolder}>
              <Button variant="contained" type="submit" style={btnContinue}>
                Continue
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
