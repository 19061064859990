import {
  Typography,
  Button,
  TextField,
  Box,
  useMediaQuery,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState } from "react";
import { CAPTCHA_KEY, COLORS } from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { merchantSignUp } from "../../store/actions/auth";
import { useHistory, useLocation } from "react-router";
import OnBoardingHeader from "../../views/landing/onBoardingHeader";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage } from "formik";
import {
  removeScrollToView,
  downloadAppSMS,
  showToast,
  stopToast,
} from "../../store/actions/auth";

export default function NewMerchantSignUp() {
  const isIphoneSE = useMediaQuery("(max-width: 320px)");
  const isXsMobile = useMediaQuery("(max-width : 360px)");
  const isMobile = useMediaQuery("(max-width : 480px)");
  const isTablet = useMediaQuery("(max-width : 768px)");
  const isSurface = useMediaQuery("(max-width : 540px)");
  const isBigTablet = useMediaQuery("(max-width : 1180px)");

  const signUpContainer = {
    backgroundColor: isMobile || isXsMobile ? "#FFFFFF" : "#F4F4F4",
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "auto",
  };
  const formContainer = {
    backgroundColor: COLORS.default_white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    width: isIphoneSE
      ? "98%"
      : isMobile || isXsMobile
      ? "94%"
      : isSurface
      ? "70%"
      : isTablet || isBigTablet
      ? "50%"
      : "50%",
    maxWidth: 520,
    margin:
      isMobile || isXsMobile || isTablet || isBigTablet
        ? "0px auto "
        : "30px auto",
    padding: isIphoneSE
      ? "20px 0px"
      : isMobile || isXsMobile
      ? "20px 12px"
      : isTablet || isBigTablet
      ? 30
      : "40px 80px",
  };
  const signUpMerchant = {
    marginTop: isMobile || isXsMobile || isTablet || isBigTablet ? 80 : 120,
    fontSize: isMobile || isXsMobile || isTablet || isBigTablet ? 28 : 32,
    fontWeight: 600,
    fontStyle: "PlusJakartaSans-Bold",
  };

  const MERCHANT = "Merchant";
  const PERSONAL = "Personal";

  const formik = useFormik({
    initialValues: {
      mailType: PERSONAL,
    },
  });

  return (
    <Box>
      <OnBoardingHeader />
      <Box style={signUpContainer} className="createMerchant forTxtInput">
        <Typography style={signUpMerchant}>Sign-up</Typography>
        <div style={formContainer}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              name="mailType"
              defaultValue={MERCHANT}
              value={formik.values.mailType}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setTouched({
                  ...formik?.touched,
                  [e.target.value === PERSONAL ? "mobile" : "email"]: false,
                });
              }}
              className="signInRadio"
            >
              <FormControlLabel
                value={PERSONAL}
                control={
                  <Radio
                    color="primary"
                    checked={formik.values.mailType === PERSONAL}
                  />
                }
                label={PERSONAL}
                className={
                  formik.values.mailType === PERSONAL ? "radio_btn_sec" : null
                }
              />
              <FormControlLabel
                value={MERCHANT}
                control={
                  <Radio
                    color="primary"
                    checked={formik.values.mailType === MERCHANT}
                  />
                }
                label={MERCHANT}
                className={
                  formik.values.mailType === MERCHANT ? "radio_btn_sec" : null
                }
                style={{
                  marginLeft: "20px",
                }}
              />
            </RadioGroup>
          </FormControl>
          {formik.values.mailType === PERSONAL ? (
            <PersonalSignUP />
          ) : (
            <MerchantSignUP />
          )}
        </div>
      </Box>
    </Box>
  );
}

export function PersonalSignUP(props) {
  const isXsMobile = useMediaQuery("(max-width : 360px)");
  const isMobile = useMediaQuery("(max-width : 480px)");
  const isTablet = useMediaQuery("(max-width : 768px)");
  const isSurface = useMediaQuery("(max-width : 540px)");
  const dispatch = useDispatch();
  const btnContinue = {
    width: "100%",
    background: "#1A7CFA",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0605059)",
    borderRadius: 2,
    color: COLORS.default_white,
    marginTop: isMobile || isXsMobile || isTablet || isSurface ? 10 : 30,
  };

  const MERCHANT = "Merchant";
  const PERSONAL = "Personal";

  const validationSchema = yup.object({
    mobile: yup
      .string("Enter your Mobile")
      .min(10, 'Mobile number must be 10 digits')
      .max(10, 'Mobile number must be 10 digits')
      .required("Mobile is required"),
  });

  const formik = useFormik({
    initialValues: {
      mobile: "",
      isVerified: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("values", values);
      let mobileWithCode = "+1" + values?.mobile;
      if (values?.isVerified) {
        await dispatch(
          downloadAppSMS({
            mobile: mobileWithCode,
            deviceToken: "abcd",
          })
        );
      }
      props.closeVideoPopup()
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ marginTop: 25 }}>
      <div className="personalMobSec">
        <div style={{ display: "flex", marginBottom: 20 }}>
          <div className="mobCodeWrapper">
            <span>+1</span>
          </div>
          <TextField
            style={{
              width: "100%",
            }}
            label={"Enter mobile number"}
            variant="outlined"
            id="mobile"
            name="mobile"
            inputProps={{ inputMode: "numeric" }}
            value={formik.values.mobile}
            onChange={({ target: { value } }) => {
              if (!/^[\d]*$/.test(value)) {
                return;
              }
              formik.setFieldValue("mobile", value);
            }}
            error={formik?.touched?.mobile && Boolean(formik?.errors?.mobile)}
            helperText={formik?.touched?.mobile && formik?.errors?.mobile}
          />
        </div>
        {formik?.touched && formik?.touched?.mobile === false && (
          <ErrorMessage
            className="errorMssgMobile"
            name="mobile"
            component="div"
          />
        )}
        <div className="reCapchaJoin">
          <ReCAPTCHA
            sitekey={CAPTCHA_KEY}
            onChange={() => formik.setFieldValue("isVerified", true)}
            type="image"
            onExpired={() => formik.setFieldValue("isVerified", false)}
          />
        </div>
        <Button
          size="large"
          variant="contained"
          style={btnContinue}
          // className="create_btn"
          type="submit"
          disabled={!formik.values.isVerified}
        >
          Download App
        </Button>
      </div>
    </form>
  );
}

export function MerchantSignUP() {
  const isXsMobile = useMediaQuery("(max-width : 360px)");
  const isMobile = useMediaQuery("(max-width : 480px)");
  const isTablet = useMediaQuery("(max-width : 768px)");
  const isBigTablet = useMediaQuery("(max-width : 1180px)");
  const inputWrapper = {
    margin:
      isMobile || isXsMobile || isTablet || isBigTablet ? 10 : "10px 30px",
    width: isMobile || isXsMobile || isTablet || isBigTablet ? "" : "85%",
  };
  const textFieldStyle = {
    fontFamily: "PlusJakartaSans-Regular",
    fontSize: 14,
    color: "#282828",
  };
  const btnContinue = {
    width: "100%",
    background: "#1A7CFA",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0605059)",
    borderRadius: 2,
    color: COLORS.default_white,
    marginTop: 16,
  };
  const accountTxt = {
    fontSize: 13,
    color: "#323232",
    textAlign: "center",
    marginTop: 30,
    fontFamily: "PlusJakartaSans-Regular",
  };
  const spanTxt = {
    color: COLORS.juma_theme_clr,
    fontSize: 14,
    fontFamily: "PlusJakartaSans-Bold",
    cursor: "pointer",
  };

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const onChange = (value) => {
    setIsVerified(true);
  };

  const resetCaptcha = () => {
    setIsVerified(false);
  };

  const { state } = useLocation();

  const formik = useFormik({
    initialValues: {
      email: state?.email,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (isVerified) {
          const response = await dispatch(merchantSignUp(values));
          if (response?.otpToken)
            history.push("validate-otp", {
              token: response,
              email: formik.values,
            });
        }
      } catch (e) {
        setError(e);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div style={inputWrapper} className="registerFormTxt">
        <Box>
          <TextField
            style={textFieldStyle}
            label="Email Id"
            id="email"
            name="email"
            autoComplete="off"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik?.touched?.email && Boolean(formik?.errors.email)}
            helperText={formik?.touched?.email && formik?.errors.email}
          />
          {!!error && (
            <small style={{ color: "red" }}>
              {error}.{" "}
              <span>
                <Link to={{ pathname: "/merchant-signin" }}>Sign in</Link> here.
              </span>
            </small>
          )}
        </Box>
        <Box className="reCapchaNewSignup">
          <ReCAPTCHA
            sitekey={CAPTCHA_KEY}
            onChange={onChange}
            type="image"
            onExpired={resetCaptcha}
          />
        </Box>
        <Button
          size="large"
          variant="contained"
          style={btnContinue}
          disabled={!isVerified}
          type="submit"
        >
          Continue
        </Button>
        <Typography style={accountTxt}>
          Already have an account?{" "}
          <Link to={{ pathname: "/merchant-signin" }} className="underlineNone">
            <span style={spanTxt}>Sign-in</span>
          </Link>
        </Typography>
      </div>
    </form>
  );
}
