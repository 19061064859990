import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Noto_Sans/NotoSans-Italic.ttf";
import "./fonts/Noto_Sans/NotoSans-Regular.ttf";
import "./fonts/Noto_Sans/NotoSans-Bold.ttf";
import "./fonts/Noto_Sans/NotoSans-BoldItalic.ttf";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { DSN_KEY } from "./constants";


Sentry.init({
    dsn: DSN_KEY,
    integrations: [new BrowserTracing()],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
