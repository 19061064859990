import { LOGOUT_SUCCESS } from "../types/auth";
import { SET_PARAMETERS } from "../types/creditNotes";

const initialState = {
    page: 1,
    limit: 10,
    order: 'DESC',
    sort: 'createdAt'
};

function parametersReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case SET_PARAMETERS:
      return { ...state, ...action.body };

    case LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
}

export default parametersReducer;
