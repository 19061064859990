import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  TextField,
  Checkbox,
  Switch,
  FormGroup,
  FormControlLabel,
  styled,
  Box,
  useMediaQuery,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_KEY, COLORS } from "../../constants";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { merchantSignIn, onLogin } from "../../store/actions/auth";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { getBusiness } from "../../store/actions/rewards";
import OnBoardingHeader from "../../views/landing/onBoardingHeader";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  marginRight: 14,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#93c5fd",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    color: "#3b82f6",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M5.28033 1.21772C5.57322 1.51061 5.57322 1.98548 5.28033 2.27838L2.31066 5.24805L5.28033 8.21772C5.57322 8.51061 5.57322 8.98548 5.28033 9.27838C4.98744 9.57127 4.51256 9.57127 4.21967 9.27838L0.71967 5.77838C0.426776 5.48548 0.426776 5.01061 0.71967 4.71772L4.21967 1.21772C4.51256 0.924824 4.98744 0.924824 5.28033 1.21772Z"/></svg>')`,
    },
  },

  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#93c5fd",
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#fff",

      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M0.71967 1.21772C0.426777 1.51061 0.426777 1.98548 0.71967 2.27838L3.68934 5.24805L0.71967 8.21772C0.426777 8.51061 0.426777 8.98548 0.71967 9.27838C1.01256 9.57127 1.48744 9.57127 1.78033 9.27838L5.28033 5.77838C5.57322 5.48548 5.57322 5.01061 5.28033 4.71772L1.78033 1.21772C1.48744 0.924824 1.01256 0.924824 0.71967 1.21772Z"/></svg>')`,
      },
    },
  },
}));

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password"),
});

export default function MerchantSignIn() {
  const isIphoneSE = useMediaQuery("(max-width: 320px)");
  const isXsMobile = useMediaQuery("(max-width : 360px)");
  const isMobile = useMediaQuery("(max-width : 480px)");
  const isSurface = useMediaQuery("(max-width : 540px)");
  const isTablet = useMediaQuery("(max-width : 768px)");
  const isBigTablet = useMediaQuery("(max-width : 1180px)");
  const [isVerified, setIsVerified] = useState(false);
  
  const formContainer = {
    backgroundColor: COLORS.default_white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    width: isIphoneSE
      ? "98%"
      : isMobile || isXsMobile
      ? "94%"
      : isSurface
      ? "70%"
      : isTablet || isBigTablet
      ? "50%"
      : "50%",
    maxWidth: 520,
    margin: " 30px auto",
    padding: isIphoneSE
      ? "20px 0px"
      : isMobile || isXsMobile
      ? "20px 12px"
      : isTablet || isBigTablet
      ? 30
      : 40,
  };
  const signUpContainer = {
    backgroundColor: isMobile || isXsMobile || isTablet ? "#FFFFFF" : "#F4F4F4",
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflow: "auto",
  };
  const signUpMerchant = {
    marginTop: isMobile || isXsMobile || isTablet || isBigTablet ? 80 : 120,
    fontSize: isMobile || isXsMobile || isTablet ? 28 : 32,
    fontWeight: 600,
    fontStyle: "PlusJakartaSans-Bold",
  };
  const inputWrapper = {
    margin:
      isMobile || isXsMobile || isTablet || isBigTablet ? 10 : "10px 30px",
  };
  const textFieldStyle = {
    fontFamily: "PlusJakartaSans-Regular",
    fontSize: 14,
    color: "#282828",
  };
  const checkBoxwrapper = {
    display: "flex",
    alignItems: "center",
  };
  const btnContinue = {
    width: "100%",
    background: "#1A7CFA",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0605059)",
    borderRadius: 2,
    color: COLORS.default_white,
    marginTop: 30,
  };
  const accountTxt = {
    fontSize: 13,
    color: "#323232",
    textAlign: "center",
    marginTop: 30,
    fontFamily: "PlusJakartaSans-Regular",
  };
  const spanTxt = {
    color: COLORS.juma_theme_clr,
    fontSize: 14,
    fontFamily: "PlusJakartaSans-Bold",
    textDecoration: "none",
    cursor: "pointer",
  };
  const robotTxt = {
    color: "#4f4f4f",
    fontSize: 14,
  };
  const passwordSec = {
    display: "flex",
    alignItems: "center",
    marginTop: 40,
    marginBottom: 10,
  };
  const forgotPswd = {
    textDecoration: "none",
  };

  const dispatch = useDispatch();
  const history = useHistory();


  const [isPassLogin, setIsPassLogin] = useState(true);

  const handleChange = (event) => {
    if (event.target.checked) setIsPassLogin(false);
    else setIsPassLogin(true);
  };

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      if (isPassLogin) {
        await dispatch(onLogin(values));
        // await dispatch(getBusiness())
        history.push("/portal");
      } else {
        const { password, ...email } = values;
        const token = await dispatch(merchantSignIn(email));
        console.log(token);
        if (token) history.push("/validate-otp", { token, email });
      }
    },
  });

  const onChange = (value) => {
    setIsVerified(true)  
  }

  const resetCaptcha = () => {
    setIsVerified(false)
  }
 

  return (
    <div>
      <OnBoardingHeader />
      <div style={signUpContainer} className="createMerchant">
        <Typography style={signUpMerchant}>Merchant Sign-in</Typography>
        <form onSubmit={formik.handleSubmit} style={formContainer}>
          <div style={inputWrapper} className="registerFormTxt">
            <TextField
              style={textFieldStyle}
              label="Email Id"
              id="email"
              name="email"
              autoComplete="off"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <div style={passwordSec}>
              <span
                className={
                  isPassLogin ? "passwordTxt_active" : "passwordTxt_inactive"
                }
              >
                Password
              </span>
              <Android12Switch onChange={handleChange} />
              <span
                className={isPassLogin ? "otpTxt_inactive" : "otpTxt_active"}
              >
                OTP
              </span>
            </div>
            {isPassLogin && (
              <Box>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    marginTop: 20,
                  }}
                >
                  <FormControl
                    sx={{ m: 1, width: "25ch" }}
                    variant="standard"
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                  >
                    <InputLabel htmlFor="standard-adornment-password">
                      Enter Password
                    </InputLabel>
                    <Input
                      id="password"
                      name="password"
                      style={textFieldStyle}
                      label="Enter Password"
                      type={isRevealPwd ? "text" : "password"}
                      value={formik.values.password}
                      onChange={({ target: { value } }) => {
                        if (/\s/g.test(value)) {
                          return;
                        }
                        formik.setFieldValue("password", value);
                      }}
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setIsRevealPwd((prevState) => !prevState)
                            }
                          >
                            {isRevealPwd ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText>
                      {formik.touched.password && formik.errors.password}
                    </FormHelperText>
                  </FormControl>
                </div>

                <Link to={{ pathname: "/forgot-password" }} style={forgotPswd}>
                  <Typography>Forgot Password?</Typography>
                </Link>
                <Box className="reCapchaSec">
                  <ReCAPTCHA
                    sitekey={CAPTCHA_KEY}
                    onChange={onChange}
                    type='image'
                    onExpired={resetCaptcha}
                  />
                </Box>
              </Box>
            )}
            <Button
              size="large"
              variant="contained"
              style={btnContinue}
              disabled={isPassLogin ? !isVerified : false}
              type="submit"
            >
              {isPassLogin ? `Continue` : `Generate OTP`}
            </Button>
            <Typography style={accountTxt}>
              Don't have an account?{" "}
              <Link
                to={{ pathname: "/merchant-signup" }}
                className="underlineNone"
              >
                <span style={spanTxt}>Sign-up</span>
              </Link>
            </Typography>
          </div>
        </form>
      </div>
    </div>
  );
}
