import { 
    Typography, 
    Button,
    AppBar,
    Toolbar,
    TextField,
    Box,
    useMediaQuery
 } from "@material-ui/core";
import React, { useState } from "react";
import { CAPTCHA_KEY, COLORS } from "../../constants";
import { FONTS } from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { merchantSignUp } from "../../store/actions/auth";
// import { merchantSignUp } from "../../mock-api/mock-api";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import OnBoardingHeader from "../../views/landing/onBoardingHeader";


const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
  });

export default function MerchantSignUp() {

      const isIphoneSE = useMediaQuery("(max-width: 320px)");
      const isXsMobile = useMediaQuery("(max-width : 360px)");
      const isMobile = useMediaQuery("(max-width : 480px)");
      const isTablet = useMediaQuery("(max-width : 768px)");
      const isSurface = useMediaQuery("(max-width : 540px)");
      const isBigTablet = useMediaQuery("(max-width : 1180px)");

      const signUpContainer = {
        backgroundColor : isMobile || isXsMobile ? '#FFFFFF' : '#F4F4F4',
        width: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: '100vh',
        overflow: 'auto'
      }; 
      const formContainer = {
          backgroundColor: COLORS.default_white,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: 'center',
          boxSizing: "border-box",
          width: isIphoneSE ? "98%" : isMobile || isXsMobile ? "94%" : isSurface ? "70%" : isTablet || isBigTablet ? "50%" : "50%",
          maxWidth: 520,
          margin: isMobile || isXsMobile || isTablet || isBigTablet ? "0px auto " : "30px auto",
          padding: isIphoneSE ? "20px 0px" : isMobile || isXsMobile ? "20px 12px" : isTablet || isBigTablet ? 30 : 40,
      };
      const signUpMerchant={
          marginTop: isMobile || isXsMobile || isTablet || isBigTablet ? 80 : 120,
          fontSize: isMobile || isXsMobile || isTablet || isBigTablet ? 28 : 32,
          fontWeight: 600,
          fontStyle: "PlusJakartaSans-Bold",
      };
      const inputWrapper = {
        margin: isMobile || isXsMobile || isTablet || isBigTablet ? 10 : "10px 30px",
        width: isMobile || isXsMobile || isTablet || isBigTablet ? "" :'85%'
      };
      const textFieldStyle = {
          fontFamily: "PlusJakartaSans-Regular",
          fontSize: 14,
          color: '#282828',
          //opacity: 0.5,
          //width: '100%'
      };
      const btnContinue ={
          width: '100%',
          background: '#1A7CFA',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0605059)',
          borderRadius: 2,
          color: COLORS.default_white,
          marginTop: 30,
      };
      const accountTxt = {
          fontSize: 13,
          color: '#323232',
          textAlign: 'center',
          marginTop: 30,
          fontFamily: "PlusJakartaSans-Regular"
      };
      const spanTxt = {
          color: COLORS.juma_theme_clr,
          fontSize: 14,
          fontFamily: "PlusJakartaSans-Bold",
          cursor: 'pointer'
      };

    const [isVerified, setIsVerified] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    
    const onChange = (value) => {
      setIsVerified(true)  
    }
  
    const resetCaptcha = () => {
      setIsVerified(false)
    }

    const {state} = useLocation()

    const formik = useFormik({
        initialValues: {
          email: state?.email,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
          try{
          if(isVerified){
          const response = await dispatch(merchantSignUp(values))
          if(response?.otpToken)
            history.push("validate-otp",  {token: response, email: formik.values});
          }
          }
          catch(e){
            setError(e);
          }
        },
    });



    return (
      <Box>
        <OnBoardingHeader />
        <Box style={signUpContainer} className="createMerchant forTxtInput">
          <Typography style={signUpMerchant}>Merchant Sign-up</Typography>
          <form onSubmit={formik.handleSubmit} style={formContainer}>
            <div style={inputWrapper} className="registerFormTxt">
            <Box>
              <TextField
                style={textFieldStyle}
                label="Email Id"
                id="email"
                name="email"
                autoComplete="off"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              {!!error && <small style={{color: 'red'}}>{error}. <span><Link to={{pathname: '/merchant-signin'}}>Sign in</Link> here.</span></small>}
            </Box>
            <Box className="reCapchaSec">
              <ReCAPTCHA
                 sitekey={CAPTCHA_KEY}
                 onChange={onChange}
                 type='image'
                 onExpired={resetCaptcha}
              />
            </Box>
            <Button
              size="large"
              variant="contained"
              style={btnContinue}
              disabled={!isVerified}
              type="submit"
            >
              Continue
            </Button>
            <Typography style={accountTxt}>
              Already have an account? <Link to={{pathname: "/merchant-signin"}} className="underlineNone">
                <span style={spanTxt}>Sign-in</span></Link>
            </Typography>
            </div>
          </form>
        </Box>
      </Box>
    );

}
