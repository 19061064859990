import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import ActivateMerchantApp from './ActivateMerchantApp';
import CreateStore from './ActivateMerchantApp/CreateStore';
import UpdateStore from './ActivateMerchantApp/UpdateStore';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import AddUserModal from './ActivateMerchantApp/AddUserModal';
import AddDeviceModal from './ActivateMerchantApp/AddDeviceModal';
import { useStyles } from './ActivateMerchantApp/styles';
import UpdateDeviceModal from './ActivateMerchantApp/UpdateDeviceModal';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1A7CFA',
    },
  },
});

export default function StoresRouter() {
  const { url } = useRouteMatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const background = location.state && location.state.background;
  const handleClose = () => {
    history.goBack();
  };

  return (
    <ThemeProvider theme={theme}>
      <Switch location={background || location}>
        <Route path={`${url}/create`} component={CreateStore} />
        <Route path={`${url}/:id/update`} component={UpdateStore} />
        <Route path={`${url}`} component={ActivateMerchantApp} />
      </Switch>
      <Dialog onClose={handleClose} open={!!background} className="storePopUps">
        <Box>
          <Switch>
            <Route path={`${url}/:storeid/add-user`}>
              <Box className={classes.formDialog}>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <AddUserModal handleClose={handleClose} />
            </Route>
            <Route path={`${url}/:storeid/add-device/`}>
              <Box className={classes.formDialog}>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <AddDeviceModal handleClose={handleClose} />
            </Route>
            <Route path={`${url}/:storeid/aisles/:aisleid/update-device/`}>
              <Box className={classes.formDialog}>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <UpdateDeviceModal handleClose={handleClose} />
            </Route>
          </Switch>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}
