import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getBusiness } from "../store/actions/rewards";
import CreateStoreDialog from "./CreateStoreDialog";
import LinkBankDialog from "./LinkBankDialog";

export default function StatusBar() {
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = useState('')
    const { businessData } = useSelector((state) => state.auth);
    const id = businessData?.business?.id
    const [childWindow, setChildWindow] = useState(null)
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
    const dispatch = useDispatch();

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    useEffect(() => {
        switch (businessData?.status) {
            case 'pending-store-front-creation':
                setStatus('Pending Storefront Creation')
                break;
            case 'verification-under-way':
                setStatus('Bank Verification Underway')
                break;
            case 'pending-personal-verification':
                setStatus('Pre-Live')
                break;
            case 'pending-bank-approval':
                setStatus('Pending Bank verification')
                break;
            case 'pending-device-linking':
                setStatus('Pre-Live')
                break;
            default:
                setStatus('')
        }
    }, [businessData?.status])


    const renderDialog = () => {
        switch(businessData?.status){
            case 'pending-store-front-creation':
              return <CreateStoreDialog open={open} closeDialog={closeDialog}  />
            case 'pending-bank-approval':
              return <LinkBankDialog open={open} closeDialog={closeDialog} />
            default:
              return <React.Fragment></React.Fragment>
        }
    }



    const handleClickOpen = (e) => {
        const childWindow = window.open(
            `https://connect.direct.id/?client_id=${CLIENT_ID}&config_id=merchants&customer_ref=${id}`,
            'popup','width=600,height=600'
        );
        setChildWindow(childWindow)
    };


    useEffect(() => {
        const intervalId = setInterval(async function () {
            if (childWindow?.closed) {
                // alert('browser window closed')
                await dispatch(getBusiness())
                setChildWindow(null)
                clearInterval(intervalId)
            }
        }, 1000);
        return () => {
            if (intervalId) { clearInterval(intervalId) }
        }
    }, [childWindow]);




    const isMobileView = useMediaQuery('(max-width: 600px)');
    return (
        <div className="verifyAccountPopUp">
            {!isMobileView ?
                <div className="accountStatusScroll">
                    <div className="scrollContainer">
                        <p><span>Account status</span>{`: ${status}`}</p>
                        {/* {businessData?.status === 'pending-bank-approval' && <a href="#" className="cursor" onClick={handleClickOpen}>Verify Bank Account */}
                            {/* <img src="/images/info_icon.png" alt="Account Info" /> */}
                        {/* </a>} */}
                        { (businessData?.status === 'pending-store-front-creation' || businessData?.status === 'pending-bank-approval') && 
                            <img src="/images/info_blue_icon.png" alt="Account Info" onClick={openDialog} style={{cursor: 'pointer'}} />
                        }

                    </div>
                </div>

                :
                <div className="accontStatusMobile">
                    <div className="mobile_status_container">
                        <div className="accountSec margin_top_10">
                            <p className="accTxt">Account status:</p>
                            { (businessData?.status === 'pending-store-front-creation' || businessData?.status === 'pending-bank-approval') && 
                                <img onClick={openDialog} src="/images/info_blue_icon.png" alt="Account Info" />
                            }
                        </div>
                        <div className="accountSec">
                            <p className="pendingTxt">{status}</p>
                            {/* {businessData?.status === 'pending-bank-approval' && <a href="#" onClick={handleClickOpen}>Verify Bank Account</a>} */}
                        </div>
                    </div>
                </div>
            }

            {
                renderDialog()
            }


        </div>
    );
}