import React, { createRef } from "react";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { removeScrollToView, downloadAppSMS, showToast, stopToast } from "../../store/actions/auth";
import HeaderLanding from "./headerLanding";
import Footer from "./footer";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_KEY, COLORS } from "../../constants";
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from "formik";
import VideoDialog from "./VideoDialog";
import BookADemoDialog from "./BookADemoDialog";


const MERCHANT = "Merchant";
const PERSONAL = "Personal";


const initialValues = {
    mobile: "",
    email: "",
    mailType: PERSONAL,
    isVerified: false
};



const SignupSchema = Yup.object().shape({
    mobile: Yup.string().when('mailType', {
        is: (value) => value === PERSONAL,
        then: Yup.string().min(10, 'Mobile number must be 10 digits').max(10, 'Mobile number must be 10 digits').required("Mobile is required"),
    }),
    email: Yup.string().when('mailType', {
        is: (value) => value === MERCHANT,
        then: Yup.string()
            .email("Invalid email address format")
            .required("Email is required"),
    }),
});


const dialogIndex = {
    1: 'DEMO_DIALOG',
    2: 'VIDEO_DIALOG',
}

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.signUpRef = createRef();
        this.homeRef = createRef();
        this.footerRef = createRef();
        this.emailRef = createRef();
        this.mobileRef = createRef();
        this.state = {
            open: false,
            activeDialog: ''
        }
    }
    componentDidMount() {
        this.handleOnLoad();
    }

    componentDidUpdate(prevProps, props) {
        this.handleOnLoad();
    }


    openVideoPopup = () => {
        this.setState({open : true})
    }


    closeVideoPopup = () => {
        this.setState({open : false})
    }


    renderDialog = () => {
        switch(this.state.activeDialog){
            case 'DEMO_DIALOG':
              return <BookADemoDialog open={this.state.open} closeVideoPopup={this.closeVideoPopup}  />
            case 'VIDEO_DIALOG':
              return <VideoDialog open={this.state.open} closeVideoPopup={this.closeVideoPopup} />
            default:
              return <React.Fragment></React.Fragment>
        }
    }




    handleOnLoad = () => {
        const { scrollInto = false } = this.props || {};
        if (scrollInto) {
            this.signUpRef.current?.scrollIntoView({ behavior: "smooth" });
            return;
        }
        this.homeRef.current?.scrollIntoView();
    };



    render() {
        return (
            <>
            {
                this.renderDialog()
            }
                <HeaderLanding
                    handleAboutUs={() =>
                        this.footerRef.current?.scrollIntoView({
                            behavior: "smooth",
                        })
                    }
                />
                <div
                    style={{ paddingTop: "65px" }}
                    className="landingPage"
                    ref={this.homeRef}
                >
                    {/* <Header history={this.props.history} /> */}
                    <div className="banner_image font-jakarta ">
                        {/* <div className="mobile-banner">
                        <div className="bannerText-Wrapper">
                            <p className="bannerHead bannerText fontBold">
                                Your
                            </p>
                            <p className="bannerHead bannerText fontBold">
                                Financial
                            </p>
                            <p className="bannerHead bannerText fontBold">
                                Network
                            </p>
                            <p className="bannerText fontBold transText">
                                No transaction fee for personal &amp; business
                                accounts
                            </p>
                        </div>
                        <div className="bannerButtons">
                            <Button
                                onClick={() =>
                                    this.props.history.push("/create")
                                }
                                className="createBtn"
                            >
                                Create Account
                            </Button>
                            <Button
                                onClick={() =>
                                    this.props.history.push("/login")
                                }
                                className="signinBtn"
                            >
                                Business Sign-In
                            </Button>
                        </div>
                    </div>
                    {/* <img
                        className="webBanner"
                        style={{ width: "100%" }}
                        src="/images/jumna_banner.svg"
                    /> */}
                        {/* <img
                        className="mobileBanner"
                        style={{ width: "100%" }}
                        src="/images/mobile-banner.svg"
                    /> */}
                        {/* <div className="marquee_wrapper">
                        <p className="marquee_text fontBold">
                            LINK YOUR BANK . LOAD CASH . GET CREDIT . PAY .
                            IN-STORE . ONLINE . SEND MONEY . REQUEST PAYMENT .
                            INSTANT . EASY . FREE . JOIN THE NEW WAY TO TRANSACT
                            . GET THE JUMNA APP
                        </p>
                    </div> */}
                        <div className="heading_tag_line">
                            <div className="max-width">
                                <p>connect direct. get more.</p>
                            </div>
                        </div>
                        <div className="createAccountCard">
                            <div className="bannerWrapper">
                                <div className="createAccountSec">
                                    <div className="expressTxt">Express <span> Business</span> Sign-up</div>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            this.props.history.push("/merchant-signup");
                                        }}
                                    >
                                        Create Account
                                    </Button>
                                    <p>Get customers. Accept payment.<br />
                                        No interchange or platform fees.</p>
                                </div>
                                {/* <div className="createAccountSec mobile_none" >
                                    <div className="expressTxt">Express Sign-up <span>NY Business</span></div>
                                    <p>Get customers. Accept payment.
                                        No interchange or platform fees.</p>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            this.props.history.push("/merchant-signup");
                                        }}
                                    >
                                        Create Account
                                    </Button> 
                                </div> */}
                                <div className="homeBannerSec">   
                                    <div 
                                        className="homeBannerContent" 
                                        onClick={() => {
                                        this.setState({activeDialog: dialogIndex['1']})
                                        this.openVideoPopup()
                                        }}>
                                        <img
                                            src="/images/hand_cursor.svg"
                                            alt="hand cusor"
                                            className="cursorImg"
                                        />
                                        <Typography>Book a Demo</Typography>
                                    </div>
                                    <div 
                                        className="homeBannerContent" 
                                        onClick={() => {
                                        this.setState({activeDialog: dialogIndex['2']})
                                        this.openVideoPopup()
                                        }}>
                                        <img
                                            src="/images/video.svg"
                                            alt="video icon"
                                            className="videoImg"
                                        />
                                        <Typography>How it Works</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="banner_section_home mob_banner_sec">
                            <div className="max-width banner_content">
                                <h2 className="mobile_none">
                                    No tapping, no waving, no QR codes.
                                </h2>
                                <h2 className="mobile_none">
                                    {" "}
                                    Just one frictionless app.{" "}
                                </h2>
                                <div className="desktop_none">
                                    <h2> No tapping.</h2>
                                    <h2>No waving.</h2>
                                    <h2>No QR codes.</h2>

                                    <h2>
                                        Just one
                                        <br />
                                        frictionless app.{" "}
                                    </h2>
                                </div>
                                <p>
                                    We pulled the planks out so merchants and
                                    lenders can connect with you directly to
                                    offer higher rewards and a flexible credit
                                    line.
                                </p>
                            </div>
                        </div>
                        <div className="playstore_sec">
                            <div className="max-width">
                                <img
                                    src="/images/app_detail_img.png"
                                    alt="playstore"
                                    className="mobile_none"
                                />

                                <img
                                    src="/images/mobile_play_img_left.png"
                                    alt=""
                                    className="desktop_none"
                                />

                                <h2>One app. The power of a thousand apps.</h2>

                                <img
                                    src="/images/mobile_play_img.png"
                                    alt=""
                                    className="desktop_none playstore_detail_img"
                                />
                            </div>
                        </div>
                        <div className="sign_up_sec" ref={this.signUpRef}>
                            <div className="max-width">
                                <Grid container>
                                    <Grid
                                        xs={12}
                                        sm={12}
                                        md={5}
                                        lg={5}
                                        spacing={3}
                                        style={{ marginTop: "100px" }}
                                        className="mobile_order"
                                    >
                                        <h2>Sign-up</h2>
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={SignupSchema}
                                            onSubmit={async (values, { resetForm }) => {
                                                console.log('values', values);
                                                if (values?.mailType === PERSONAL) {
                                                    let mobileWithCode = '+1' + values?.mobile
                                                    if (values?.isVerified) {
                                                        await this.props.downloadAppSMS({ mobile: mobileWithCode, deviceToken: 'abcd' })
                                                    }
                                                }
                                                if (values?.mailType === MERCHANT) {
                                                    if (
                                                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                                                            values?.email
                                                        )
                                                    ) {
                                                        this.props.history.push("merchant-signup", {
                                                            email: values?.email,
                                                        });
                                                        resetForm()
                                                    }
                                                    else {
                                                        this.props.showToast("Invalid Email ID")
                                                        setTimeout(() => {
                                                            this.props.stopToast();
                                                        }, 4000);
                                                        return
                                                    }
                                                }

                                            }}
                                        >
                                            {({ isSubmitting, values, handleChange, setFieldValue, touched, setTouched }) => (
                                                <Form>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            row
                                                            aria-label="position"
                                                            name="mailType"
                                                            defaultValue={MERCHANT}
                                                            value={values.mailType}
                                                            onChange={(e) => {
                                                                handleChange(e)
                                                                setTouched({
                                                                    ...touched,
                                                                    [e.target
                                                                        .value ===
                                                                    PERSONAL
                                                                        ? "mobile"
                                                                        : "email"]: false,
                                                                });
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                value={PERSONAL}
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        checked={
                                                                            values.mailType ===
                                                                            PERSONAL
                                                                        }
                                                                    />
                                                                }
                                                                label={PERSONAL}
                                                                className={
                                                                    values
                                                                        .mailType ===
                                                                    PERSONAL &&
                                                                    "radio_btn_sec"
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                value={MERCHANT}
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        checked={
                                                                            values.mailType ===
                                                                            MERCHANT
                                                                        }
                                                                    />
                                                                }
                                                                label={MERCHANT}
                                                                className={
                                                                    values.mailType ===
                                                                    MERCHANT &&
                                                                    "radio_btn_sec"
                                                                }
                                                                style={{
                                                                    marginLeft: "20px",
                                                                }}
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <div className="input_sec">
                                                        {values.mailType ===
                                                            PERSONAL ? (
                                                            <div className="personalMobSec">
                                                                <div style={{ display: 'flex', marginBottom: 20 }}>
                                                                    <div className="mobCodeWrapper">
                                                                        <span>+1</span>
                                                                    </div>
                                                                    <TextField
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        label={
                                                                            "Enter mobile number"
                                                                        }
                                                                        variant="outlined"
                                                                        id="mobile"
                                                                        name="mobile"
                                                                        inputProps={{ inputMode: "numeric" }}
                                                                        value={
                                                                            values.mobile
                                                                        }
                                                                        onChange={({ target: { value } }) => {
                                                                            if (!/^[\d]*$/.test(value)) {
                                                                                return;
                                                                            }
                                                                            setFieldValue("mobile", value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                {touched.mobile && <ErrorMessage className="errorMssgMobile" name="mobile" component="div" />}
                                                                <div className="reCapchaHome">
                                                                    <ReCAPTCHA
                                                                        sitekey={CAPTCHA_KEY}
                                                                        onChange={() => setFieldValue('isVerified', true)}
                                                                        type='image'
                                                                        onExpired={() => setFieldValue('isVerified', false)}
                                                                    />
                                                                </div>
                                                                <Button
                                                                    className="create_btn"
                                                                    type="submit"
                                                                    disabled={!values.isVerified}
                                                                >
                                                                    Download App
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <TextField
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    label={
                                                                        "Enter email address"
                                                                    }
                                                                    variant="outlined"
                                                                    id="email"
                                                                    name="email"
                                                                    value={values.email}
                                                                    onChange={handleChange}
                                                                />
                                                                {touched.email && <ErrorMessage className="home-page-email-error" name="email" component="div" />}

                                                                <Button
                                                                    className="create_btn"
                                                                    type="submit"
                                                                >
                                                                    Create Account
                                                                </Button>
                                                            </div>
                                                        )}


                                                    </div>
                                                </Form>
                                            )
                                            }
                                        </Formik>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={7} lg={7}>
                                        <Box className="sign_up_right">
                                            <img
                                                src="/images/singup_img.svg"
                                                alt=""
                                                className="sign_up_img"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className="control_sec">
                            <div className="max-width">
                                <h2>You are always, in control. </h2>
                                <p>
                                    Never reveal your phone number, email or
                                    payment information and transact from behind
                                    the safety of our privacy wall.
                                </p>
                                <p>
                                    Initiate chat only with merchants known to
                                    you to enquire, order and accept secure
                                    payment requests.
                                </p>
                                <p>
                                    Our anti-fraud safeguards ensure that no
                                    merchant can charge you without your
                                    explicit consent via our app from your
                                    personal phone device. Nor can they
                                    communicate with you outside of a 24-hour
                                    response window to a chat initiated by you.
                                </p>
                            </div>
                        </div>

                        <div className="">
                            <img
                                src="/images/homepage_landing_banner.png"
                                alt=""
                                style={{ width: "100%" }}
                            />
                            {/* <img
                            src="/images/landing_banner_mobile.svg"
                            alt=""
                            style={{ width: "100%" }}
                            className="desktop_none"
                        /> */}
                        </div>
                        <div className="network_merchant_sec">
                            <div className="max-width">
                                <h2>
                                    Your everyday network of merchants,
                                    unplanked.
                                </h2>
                                <p>
                                    Pick up your morning coffee. Pay for a yoga
                                    class. Ahh, that acupuncture session. An
                                    exquisite meal. Those shoes you've been
                                    eyeing for a while. Or just a punnet of
                                    grapes from the fruit seller around the
                                    corner.
                                </p>
                                <p className="merchants_sub_sec">
                                    We don’t charge merchants to connect with
                                    you, so they can reward you instead. Go on,
                                    out there and spend and let your everyday
                                    merchant reward you more.
                                </p>
                            </div>
                        </div>

                        <div className="control_sec">
                            <div className="max-width">
                                <h2>
                                    We designed credit, like we’d want it for
                                    ourselves.
                                </h2>
                                <p>
                                    A personal credit facility with the
                                    predictability of an instalment plan and the
                                    seamlessness of revolving credit, designed
                                    to help you create a virtuous cycle of
                                    greater credit worthiness and lower APRs.
                                </p>
                                <p>
                                    Your bundled weekly spend across different
                                    merchants is paid off over twelve months at
                                    the lowest possible rate. So, you don’t have
                                    to worry about a spike in interest rate
                                    after a zero-percent buy-now-pay later
                                    window that punishes you for carrying
                                    balances just when you really need to.
                                </p>
                            </div>
                        </div>
                        <div className="get_the_credit">
                            <Grid container spacing={1}>
                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    className="mobile_order"
                                >
                                    <Box className="credit_left">
                                        <p>
                                            <img
                                                src="/images/square_box_white_icon.svg"
                                                alt=""
                                            />
                                            No mininum spend amount
                                        </p>

                                        <p>
                                            {" "}
                                            <img
                                                src="/images/square_box_white_icon.svg"
                                                alt=""
                                            />
                                            Pre-pay at any time without penalty
                                        </p>

                                        <p>
                                            {" "}
                                            <img
                                                src="/images/square_box_white_icon.svg"
                                                alt=""
                                            />
                                            Opt for auto-pay to get the lowest
                                            rate possible
                                        </p>

                                        <p>
                                            {" "}
                                            <img
                                                src="/images/square_box_white_icon.svg"
                                                alt=""
                                            />
                                            Pay progressively less interest as
                                            you pay off your instalments
                                        </p>
                                        <p>
                                            {" "}
                                            <img
                                                src="/images/square_box_white_icon.svg"
                                                alt=""
                                            />
                                            Interest-free returns of credit
                                            funded transactions
                                        </p>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="credit_right">
                                        <h2>
                                            Get the credit you deserve, starting
                                            at 11%.
                                        </h2>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="get_the_credit">
                            <Grid container spacing={1}>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="payment_left">
                                        <img
                                            src="/images/payment_method2.png"
                                            alt=""
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6}>
                                    <Box className="payment_right">
                                        <h2>Credit, or not.</h2>
                                        <p>
                                            You’ll get the same deals applied at
                                            checkout when you pay in-store.
                                        </p>

                                        <p>
                                            You don’t have to spend on credit
                                            just to get our merchant rewards.
                                        </p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="our_inteligent_sec">
                            <div className="max-width">
                                <h2>
                                    Our intelligent store display is
                                    location-based. This ensures two-way
                                    privacy; we don’t sell customer shopping
                                    habits or merchant transaction data.
                                </h2>
                                <p>
                                    Giving merchants ownership of their
                                    transaction customer data makes them
                                    comfortable in using a consolidated platform
                                    like ours as they don’t have to fret about
                                    someone stealing their customer base; it’s
                                    as good as them having a dedicated app.
                                </p>
                                <p>
                                    All to your advantage, as we are able to
                                    bring to you the benefits of consolidation
                                    wherein you don’t have to download different
                                    apps to get the best deals from a merchant.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="eachSection sectionOne">
          <div className="section_one_text">
            <p style={sectionHeading}>Jumna Advantage</p>
            <div className="mobileSectionOne">
              <img style={{ width: "100%" }} src="/images/section_one_image.svg" />
            </div>
            <p style={sectionTextFont}>You don’t have to take out a low rate personal loan to pay off credit card debt!
              <span style={highlightText}> We make the lowest rate available to you, straight at point-of-sale.</span>
            </p>
            <p style={sectionTextFont}>
              And as the merchant doesn’t pay out a chunk in transaction fees, they have more to reward you with.
            </p>
            <p style={sectionTextFont}>
              <span style={highlightText}>Get plugged in.</span>  Enjoy our user experience. You’ll forget you were ever tapping your phone awkwardly or carrying a card. Link your bank.
            </p>
            <p style={sectionTextFont}>
              Get connected. Enjoy direct rewards.
            </p>
          </div>
          <div className="section_one_image webSectionOne">
            <img style={{ width: "100%" }} src="/images/section_one_image.svg" />
          </div>
        </div>
        <div className="eachSection sectionTwo">
          <div className="section_two_image">
            <img style={{ width: "100%" }} src="/images/section_two_image.svg" />
          </div>
          <div className="section_two_text">
            <p style={sectionTextFont}>
              We have the necessary fraud protocols to ensure that merchants face no chargebacks.
            </p>
            <p style={sectionTextFont}>
              <span style={highlightText}>Jumna is the first network to unburden the merchant; </span>  we work hard to eliminate the middle man and get you customers.
            </p>
            <p style={sectionTextFont}>
              Use Jumna to receive payment 	&amp; to send invoices 	&amp; generate payment requests.
            </p>
          </div>
        </div>
        <div className="eachSection sectionThree">
          <div className="section_three_text">
            <p style={sectionHeading}>How it works? It’s simple.</p>
            <div style={howItWorks_section}>
              <div style={imgWrapper}>
                <img src="/images/mobile_icon.svg" />
              </div>
              <div style={howItWorks_text_wrapper}>
                <p style={{ ...howItWorks_text, ...blue_text }}>Get the Jumna App.</p>
                <p style={howItWorks_text}>For all your financial needs.</p>
              </div>
            </div>
            <div style={howItWorks_section}>
              <div style={imgWrapper}>
                <img src="/images/cart_icon.svg" />
              </div>
              <div style={howItWorks_text_wrapper}>
                <p style={howItWorks_text}>Shop in-store, in-app &amp; online.</p>
                <p style={howItWorks_text}>Send money, Request payment.</p>
              </div>
            </div>
            <div style={howItWorks_section}>
              <div style={imgWrapper}>
                <img src="/images/track_icon.svg" />
              </div>
              <div style={howItWorks_text_wrapper}>
                <p style={howItWorks_text}>We track where you are.</p>
                <p style={howItWorks_text}>You select amount and hit pay.</p>
                <p style={howItWorks_text}>We make an instant connection &amp; you are done. </p>
              </div>
            </div>
          </div>
          <div className="section_three_image">
            <img style={{ width: "100%" }} src="/images/howitworks_image.svg" />
          </div>
        </div>
        <div className="eachSection sectionFour">
          <div className="section_four_text">
            <p style={section_FourHeading}>If you are not a sole proprietor, create &amp; manage your account online.</p>
            <div style={section_four_points}>
              <img style={point_icon} src="/images/point_icon.svg" />
              <p style={points_text}>Activate store location, to accept payment in-store. Use a dedicated device or upload our software on to your point-of-sale terminal.</p>
            </div>
            <div style={section_four_points}>
              <img style={point_icon} src="/images/point_icon.svg" />
              <p style={points_text}>Set up a promotional campaign. Then leave it to us to get you customers.</p>
            </div>
            <div style={section_four_points}>
              <img style={point_icon} src="/images/point_icon.svg" />
              <p style={points_text}>Reward your customers for repeat spend. Set up a loyalty program.</p>
            </div>
          </div>
          <div className="section_four_image">
            <img style={{ width: "100%" }} src="/images/section_four_image.png" />
          </div>
        </div>
        <div className="footer_content">
          <div>
            <img style={{ width: "90px" }} src="/images/jumna_logo.svg" />
            <p style={{ margin: 0, fontSize: FONTS.size_small }}>&copy; Copyright 2020</p>
          </div>
          <div className="navList">
            <p className="cursor" style={{ fontSize: FONTS.size_medium }}>Terms &amp; Conditions</p>
            <p className="cursor" style={{ fontSize: FONTS.size_medium }}>Privacy Policy</p>
          </div>
        </div> */}
                </div>
                <Footer footerRef={this.footerRef} />
            </>
        );
    }
}

export default connect(
    ({ scroll: { scrollInto = {} } }) => {
        return { scrollInto };
    },
    { removeScrollToView, downloadAppSMS, showToast, stopToast }
)(Home);
