import {
    Typography,
    Button,
    AppBar,
    Toolbar,
    TextField,
    Checkbox,
    useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { COLORS } from "../../constants";
import { FONTS } from "../../constants";
import { acceptTnC } from "../../store/actions/auth";
import OnBoardingHeader from "../../views/landing/onBoardingHeader";


export default function TermsAndConditions() {
    const isXsMobile = useMediaQuery("(max-width : 360px)");
    const isMobile = useMediaQuery("(max-width : 480px)");
    const isTablet = useMediaQuery("(max-width : 768px)");
    const isBigTablet = useMediaQuery("(max-width : 1180px)");

    const signUpContainer = {
        backgroundColor: isMobile || isXsMobile || isTablet ? "#FFFFFF" : "#F4F4F4",
        width: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "auto",
    };

    const formContainer = {
        backgroundColor: COLORS.default_white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        boxSizing: "border-box",
        width:
            isMobile || isXsMobile || isTablet || isBigTablet ? "94%" : "80%",
        maxWidth: 1055,
        margin: " 30px auto",
        padding:
            isMobile || isXsMobile || isTablet || isBigTablet
                ? "20px 10px"
                : 40,
        marginTop:
            isMobile || isXsMobile ? 80 : isTablet || isBigTablet ? 120 : 120,
    };
    const btnAgree = {
        width:
            isMobile || isXsMobile
                ? "80%"
                : isTablet || isBigTablet
                ? "50%"
                : "40%",
        background: "#1A7CFA",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0605059)",
        borderRadius: 2,
        color: COLORS.default_white,
        display: "block",
        margin: "30px auto",
    };

    const history = useHistory();
    const dispatch = useDispatch();
    const acceptTermsAndConditions = async (tncAccepted) => {
        const response = await dispatch(acceptTnC({ tncAccepted }));
        if (response.status === "pending-password-creation") {
            history.replace("set-password");
        }
    };
    return (
        <div>
            <OnBoardingHeader />
            <div style={signUpContainer} className="createMerchant">
                <div style={formContainer}>
                    <div className="tandcSec">
                        <Typography>Terms of use</Typography>
                        <div className="tandcBox">
                            {/* <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                    <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</Typography>
                    <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography> */}
                            <div className="privacy_landing_page">
                                <div className="privacy_policy_sec">
                                    <div>
                                        <div className="last_update_title_tc">
                                            <h2>Last updated:Dec 12 2022</h2>
                                        </div>
                                        <div className="policy_detail_sec_tc">
                                            <p>
                                                Please read these Terms and
                                                Conditions (collectively, these
                                                “Terms” or these “Terms and
                                                Conditions”) carefully before
                                                using the www.unplank.com
                                                website or Unplank App (the
                                                “Service”) operated by Unplank
                                                Inc., a Delaware corporation
                                                (the “Company”, “us”, “we”, or
                                                “our”). Your access to and use
                                                of the Service is conditioned
                                                upon your acceptance of and
                                                compliance with these Terms.
                                            </p>
                                            <br />
                                            <p>
                                                These Terms apply to all
                                                visitors, users and others who
                                                wish to access or use the
                                                Service.
                                            </p>
                                            <br />
                                            <p>
                                                By accessing or using the
                                                Service you agree to be bound by
                                                these Terms. If you disagree
                                                with any part of these Terms
                                                then you do not have permission
                                                to access the Service.
                                            </p>
                                            <br />
                                            <p>
                                                If you have any questions about
                                                these Terms, please contact us
                                                at compliance@unplank.com.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>1.Definitions:</h2>
                                            <p>
                                                (a) The website www.unplank.com
                                                and the associated Unplank App
                                                (the “App”) refer to the
                                                technology platform of the
                                                Company, which provides for the
                                                facilitation of a marketplace
                                                and origination of whole loans.
                                            </p>
                                            <p>
                                                (b) The “Company”, “we”, “us”,
                                                “our” or “Unplank” refers to
                                                Unplank Inc., a Delaware
                                                corporation.
                                            </p>
                                            <p>
                                                (c) “You” refers to a client
                                                entity as user, defined as an
                                                individual person or business or
                                                merchant.
                                            </p>
                                            <p>
                                                (d) An account on
                                                www.unplank.com constitutes a
                                                credit facility or a cash
                                                account of the user for the
                                                purpose of receiving payments
                                                from transacting participants on
                                                the platform.
                                            </p>
                                            <p>
                                                (e) Monies in the cash account
                                                with us are held in an account
                                                with our payment partner bank,
                                                for the benefit of our users
                                                that qualify as merchants.
                                            </p>
                                            <p>
                                                (f) A linked bank account refers
                                                to a cash account in your name
                                                held at a bank or other
                                                financial institution to which
                                                you may authorize us to debit or
                                                credit with cash proceeds
                                                resulting from the transactions
                                                between users and where
                                                applicable the servicing of
                                                debt.
                                            </p>
                                            <p>
                                                (g) The terms of use, stated
                                                herein, apply to all of our
                                                users and client entities,
                                                unless otherwise stated, and
                                                shall remain in effect at all
                                                times until all accounts of such
                                                user or client entity on our
                                                platform are closed and no
                                                amounts are payable from such
                                                user or client entity to the
                                                Company.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>2. Accounts:</h2>
                                            <p>
                                                (a) When you create an account
                                                with us, you guarantee that you
                                                are a person or merchant and
                                                that the information you provide
                                                us is accurate, complete, true,
                                                and current at the time provided
                                                to us, and, to the extent such
                                                information changes after the
                                                date provided, you are
                                                responsible to provide us with
                                                the most current information.
                                                Inaccurate, incomplete, or
                                                obsolete information may result
                                                in the immediate termination of
                                                your account on and use of the
                                                Service.
                                            </p>
                                            <p>
                                                (b) You are responsible for
                                                maintaining the confidentiality
                                                of your account and password,
                                                including but not limited to the
                                                restriction of access to your
                                                phone, computer, and other means
                                                of accessing your account. You
                                                agree to accept responsibility
                                                for any and all activities or
                                                actions that occur under your
                                                account, whether your password
                                                is with our Service or a
                                                third-party service provider.
                                                You must notify us immediately
                                                upon becoming aware of any
                                                breach of security or
                                                unauthorized use of your
                                                account.
                                            </p>
                                            <p>
                                                (c) You may not use personally
                                                identifiable information of
                                                another person or entity that is
                                                not lawfully available for such
                                                use, including a name or
                                                trademark that is subject to any
                                                rights of another person or
                                                entity, without appropriate
                                                authorization.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>
                                                3. Identification of Client
                                                entities:
                                            </h2>
                                            <p>
                                                (a) To help the government
                                                prevent the funding of terrorism
                                                and money laundering activities,
                                                federal law requires all
                                                financial institutions to
                                                obtain, verify, and record
                                                information that identifies each
                                                person or entity who opens an
                                                account. When you open an
                                                account, we will therefore ask
                                                for certain identifiable
                                                information that will allow us
                                                to identify you and persons
                                                legally authorized to act on
                                                your behalf. Personally
                                                identifiable information may
                                                include, but is not limited to,
                                                your social security number,
                                                name, phone number, postal
                                                address, other information
                                                (collectively, “Personal
                                                Information”). You must inform
                                                us as soon as practicable of any
                                                change in data collected and
                                                agree to supply upon request any
                                                additional information, we deem
                                                necessary to comply with
                                                regulations.
                                            </p>
                                            <p>
                                                (b) We collect this information
                                                for the purpose of providing our
                                                services, identifying and
                                                communicating with you,
                                                preventing criminal activity,
                                                managing risk, disputes and
                                                collections, responding to your
                                                requests and inquiries, and
                                                improving our services. You may
                                                review the detailed Privacy
                                                Policy in a separate disclosure
                                                on our website at
                                                [www.unplank.com/privacy].
                                            </p>
                                            <p>
                                                (c) You agree to confirm
                                                ownership of linked bank
                                                accounts to facilitate payments.
                                                The facility to confirm
                                                ownership will be provided on
                                                the platform via an open banking
                                                portal provided by a partner
                                                service provider.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>
                                                4. Services to individual
                                                persons:
                                            </h2>
                                            <p>
                                                (a) Point-of-sale payment to
                                                participating merchants through
                                                the provision of credit to users
                                                of our platform:
                                            </p>
                                            <p className="pl_20">
                                                i. You are assigned an
                                                indicative APR (average
                                                percentage rate) at which you
                                                may borrow pursuant to a
                                                soft-pull of credit scores
                                                performed during the creation of
                                                your account. A soft-pull will
                                                not affect your credit score.
                                            </p>
                                            <p className="pl_20">
                                                ii. You are assigned a credit
                                                limit only once we have verified
                                                your income via Direct ID’s open
                                                banking portal. The APR at which
                                                you may borrow may change
                                                pursuant to our verification of
                                                your income.
                                            </p>
                                            <p className="pl_20">
                                                iii. Your account is active and
                                                you are ready to pay in-store
                                                with our App only once we have
                                                assigned you a credit limit and
                                                APR at which you may borrow.
                                            </p>
                                            <p className="pl_20">
                                                iv. You commit to pay down any
                                                weekly amount of credit
                                                extended, with a minimum payment
                                                made every successive fifth
                                                Thursday, for a maximum of
                                                twelve months, in an amount
                                                equal to 1/12 th of that week’s
                                                outstanding principal amount
                                                extended plus one month of
                                                interest thereon. This amounts
                                                to a maximum of four or five
                                                payment dates per month,
                                                depending upon when in the
                                                initial month the borrowing was
                                                undertaken.
                                            </p>
                                            <p className="pl_20">
                                                v. You may choose to make
                                                payments to service your loan or
                                                opt to pay by auto-pay, wherein
                                                you authorize us to debit your
                                                linked bank account periodically
                                                as described in Section
                                                4(a)(iv).
                                            </p>
                                            <p className="pl_20">
                                                vi. Opting for the auto-pay
                                                facility may bring down your
                                                cost of borrowing.
                                            </p>
                                            <p className="pl_20">
                                                vii. Interest payable by you is
                                                calculated on a declining
                                                balance, using 30/360 basis of
                                                calculation which is to say that
                                                each month is treated as having
                                                an average of thirty days and
                                                that after each paydown of
                                                principal due, the balance on
                                                which the interest is calculated
                                                for the next month declines.
                                            </p>
                                            <p className="pl_20">
                                                viii. You may prepay without
                                                penalty any outstanding
                                                principal ahead of the 12-month
                                                schedule. To the extent of
                                                repayment, your available credit
                                                limit is replenished.
                                            </p>
                                            <p className="pl_20">
                                                ix. You have a grace period of
                                                two days from payment due date,
                                                beyond which failure to pay
                                                renders the payment overdue.
                                            </p>
                                            <p className="pl_20">
                                                x. If there is an overdue
                                                payment, you will no longer be
                                                extended further credit on the
                                                platform until the payment has
                                                been made.
                                            </p>
                                            <p className="pl_20">
                                                xi. You have a 6-month grace
                                                period in which to pay any
                                                overdue payment before the
                                                overdue payment is declared
                                                delinquent and reported to a
                                                credit agency, affecting your
                                                credit score.
                                            </p>
                                            <p className="pl_20">
                                                xii. A reversal of funds debited
                                                from your bank account upon your
                                                opting for auto-pay will be
                                                treated as an act of delinquency
                                                with respect to the reversed
                                                payment, which upon report to a
                                                credit agency will affect your
                                                credit score.
                                            </p>
                                            <p>
                                                (b) Point-of-sale payment to
                                                participating merchants on our
                                                platform with the use of your
                                                own cash in a linked bank
                                                account:
                                            </p>
                                            <p className="pl_20">
                                                i. You may select the option to
                                                pay for all transactions on any
                                                given date “T”, where T is
                                                between 4:00 pm on T-1 and 3:59
                                                pm on T, with one lump-sum
                                                payment from your linked bank
                                                account, without incurring any
                                                interest.
                                            </p>
                                            <p className="pl_20">
                                                ii. Such provision will be
                                                marked as ‘Cash’ in the in-app
                                                menu before payment.
                                            </p>
                                            <p className="pl_20">
                                                iii. The ability to pay down
                                                spend on a given day after a
                                                transaction but before the start
                                                of the credit facility
                                                amortization and calculation of
                                                interest is open only to
                                                individual users who have
                                                authorized us to deduct from
                                                their linked bank account the
                                                amounts due.
                                            </p>
                                            <p className="pl_20">
                                                iv. In the event of insufficient
                                                funds to make the payment, we
                                                will automatically extend you
                                                credit on the terms described
                                                above.
                                            </p>
                                            <p className="pl_20">
                                                v. For this reason, all
                                                individual users must be credit
                                                approved by us and must have
                                                authorized us to deduct and
                                                transfer funds from their linked
                                                bank account at the end of the
                                                day.
                                            </p>
                                            <p>
                                                (c) Automatic application of
                                                rewards:
                                            </p>
                                            <p className="pl_20">
                                                i. We will apply any discounts
                                                or cash back deals offered by
                                                merchants on our platform at the
                                                point-of-sale.
                                            </p>
                                            <p className="pl_20">
                                                ii. The rewards may be posted
                                                separately on your account even
                                                if, at the point-of-sale, your
                                                payment amount includes the
                                                rebate.
                                            </p>
                                            <p>
                                                (d) Provision of encrypted chat
                                                facility with merchants:
                                            </p>
                                            <p className="pl_20">
                                                i. You may communicate with a
                                                merchant via an in-app chat
                                                facility.
                                            </p>
                                            <p className="pl_20">
                                                ii. The commitment to respond
                                                promptly or at all is at the
                                                discretion of the merchant.
                                            </p>
                                            <p className="pl_20">
                                                iii. Any communication with a
                                                merchant via our in-app chat
                                                facility can only be initiated
                                                by you. The merchant may respond
                                                to you within a 24-hour period
                                                and retain the right of pushing
                                                a payment request to you to
                                                fulfil a commitment of sale.
                                            </p>
                                            <p className="pl_20">
                                                iv. You have the right to accept
                                                or decline the request for
                                                payment. If you accept, you will
                                                be guided through the in-app
                                                payment protocol wherein you may
                                                choose between cash and credit
                                                to make payment, before
                                                authorizing it with the use of
                                                your password.
                                            </p>
                                            <p>(e) Anti-fraud safeguards:</p>
                                            <p className="pl_20">
                                                i. Your ability to pay in-store
                                                using our App is restricted to
                                                your phone device as an
                                                anti-fraud safeguard.
                                            </p>
                                            <p className="pl_20">
                                                ii. If logging into your account
                                                using a new device, you will be
                                                guided in-app to register the
                                                new device.
                                            </p>
                                            <p className="pl_20">
                                                iii. Your bank details and full
                                                identity other than your name
                                                will not be revealed to
                                                participating merchant
                                            </p>
                                            <p className="pl_20">
                                                iv. Merchants may not deduct and
                                                transfer any payment from you
                                                without your explicit consent
                                                in-app with the use of your
                                                password and only from your
                                                registered device.
                                            </p>
                                            <p className="pl_20">
                                                v. Merchants may not initiate
                                                communication with you other
                                                than in response to a query made
                                                by you, and only then within a
                                                24-hour window.
                                            </p>
                                            <p>(f) Processing of refunds:</p>
                                            <p className="pl_20">
                                                i. You will have the ability to
                                                use our App to select a
                                                transaction and request a
                                                reversal at the point-of-sale.
                                            </p>
                                            <p className="pl_20">
                                                ii. All credit funded
                                                transactions may be reversed
                                                only within 25 days of the date
                                                of purchase and the cost of
                                                accrued interest will be borne
                                                by the merchant.
                                            </p>
                                            <p className="pl_20">
                                                iii. The decision to reverse the
                                                transaction will be at the
                                                merchant’s discretion.
                                            </p>
                                            <p>(g) Statements:</p>
                                            <p className="pl_20">
                                                i. All transactions will be
                                                recorded in-app in the fashion
                                                of a viewable statement.
                                            </p>
                                            <p className="pl_20">
                                                ii. After two years, old
                                                transactions will be archived
                                                but will be made available upon
                                                request.
                                            </p>
                                            <p>(h) Support:</p>
                                            <p className="pl_20">
                                                i. A library of frequently asked
                                                questions will be provided to
                                                you in-app
                                            </p>
                                            <p className="pl_20">
                                                ii. You have the ability to
                                                contact us in-app. We aim
                                                respond to all queries within 24
                                                hours.
                                            </p>
                                            <p>(i) Fees:</p>
                                            <p className="pl_20">
                                                i. There are no fees or charges,
                                                other than interest payable on
                                                the amount of credit extended to
                                                you.
                                            </p>
                                            <p>(j) Disputes:</p>
                                            <p className="pl_20">
                                                i. All payments are final once
                                                you have authorized payment at
                                                point-of-sale with your
                                                password.
                                            </p>
                                            <p className="pl_20">
                                                ii. Any request to amend the
                                                amount of payment must be
                                                directly settled with the
                                                merchant and can be processed as
                                                a reversal at their discretion.
                                            </p>
                                            <p>(k) Data Privacy:</p>
                                            <p className="pl_20">
                                                i. Our service is dependent on
                                                information about your location
                                                which helps us offer suggestions
                                                of merchants nearest to you and
                                                flag available promotions based
                                                on your general location.
                                            </p>
                                            <p className="pl_20">
                                                ii. We do not track your
                                                spending patterns to suggest
                                                merchants or advertisements
                                                thereof.
                                            </p>
                                            <p className="pl_20">
                                                iii. You may view our complete
                                                privacy policy on our website at
                                                [www.unplank.com/privacy].
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>5. Services to Merchants:</h2>
                                            <p>(a) Setting up of account</p>
                                            <p className="pl_20">
                                                i. You must set up your account
                                                on our web platform before you
                                                can operate the App for use at
                                                point-of-sale.
                                            </p>
                                            <p className="pl_20">
                                                ii. You may activate your App on
                                                a device of your choosing and
                                                accept, or authorize employees
                                                to accept, payments from
                                                individuals at the
                                                point-of-sale.
                                            </p>
                                            <p>
                                                (b) Payments from transacting
                                                individuals:
                                            </p>
                                            <p className="pl_20">
                                                i. You may use the App to accept
                                                or decline payment from an
                                                individual at the point-of-sale.
                                            </p>
                                            <p className="pl_20">
                                                ii. You may activate the
                                                auto-accept in-app feature to
                                                automatically accept all
                                                payments without prior
                                                verification or explicit
                                                acceptance.
                                            </p>
                                            <p className="pl_20">
                                                iii. The default option of
                                                accepting payment allows you to
                                                accept or decline the amount
                                                proffered at point-of-sale.
                                            </p>
                                            <p>
                                                (c) Setting up and running a
                                                promotional campaign
                                                to acquire customers:
                                            </p>
                                            <p className="pl_20">
                                                i. You may customize the  campaign by changing the allowed parameters.
                                            </p>
                                            <p className="pl_20">
                                                ii. A campaign that makes a
                                                promise of cashback following
                                                spend above
                                            </p>
                                            <p className="pl_20">
                                                a specified threshold before a
                                                date in the future may not be
                                                cancelled once in play until
                                                such future date initially
                                                specified.
                                            </p>
                                            <p className="pl_20">
                                                iii. If a campaign makes a promise to
                                                pay that exceeds the residual payment
                                                required to be made by an individual,
                                                you will remain liable for said cashback.
                                            </p>
                                            <p>
                                                (d) Ability to communicate with,
                                                take orders from and push
                                                payment to individual users via
                                                in-app chat:
                                            </p>
                                            <p className="pl_20">
                                                i. A chat may only be initiated
                                                by an individual user.
                                            </p>
                                            <p className="pl_20">
                                                ii. You may at your discretion
                                                choose to respond for purposes
                                                of answering queries, accepting
                                                orders and pushing payment
                                                requests.
                                            </p>
                                            <p className="pl_20">
                                                iii. All chats with individuals
                                                will be archived once past the
                                                24-hour window.
                                            </p>
                                            <p className="pl_20">
                                                iv. The chat facility is
                                                restricted to one device only.
                                            </p>
                                            <p>
                                                (e) Ability to view daily
                                                payments and process refunds:
                                            </p>
                                            <p className="pl_20">
                                                i. You may view transactions
                                                in-app, sorted by “accepted”,
                                                “declined” or “cancelled,”
                                                should they be cancelled by the
                                                individual before they were
                                                accepted by you.
                                            </p>
                                            <p className="pl_20">
                                                ii. You may process reversals of
                                                any transactions the same day or
                                                prior via our App.
                                            </p>
                                            <p className="pl_20">
                                                iii. Credit funded transactions
                                                may only be reversed within 25
                                                days from the date of purchase.
                                            </p>
                                            <p>(f) Anti-fraud safeguards:</p>
                                            <p className="pl_20">
                                                i. All devices must be linked to
                                                the merchant account at the time
                                                of store set-up.
                                            </p>
                                            <p className="pl_20">
                                                ii. An App may be logged into
                                                only by persons authorized by
                                                the owner or authorizing manager
                                                at the level of account on the
                                                web platform.
                                            </p>
                                            <p>(g) Fees:</p>
                                            <p className="pl_20">
                                                i. Your use of Unplank is free
                                                including the ability to accept
                                                payments and run customizable
                                                promotions to acquire customers.
                                            </p>
                                            <p className="pl_20">
                                                ii. You agree to bear the cost
                                                of accrued interest due on a
                                                reversed credit funded
                                                transaction.
                                            </p>
                                            <p className="pl_20">
                                                iii. You may enable a premium
                                                service to advertise your
                                                promotions to our users.
                                                Pursuant to enabling the premium
                                                service, you will be charged
                                                $0.50 upon conversion to a sale,
                                                irrespective of the amount of
                                                the transaction.
                                            </p>
                                            <p>(h) Statement:</p>
                                            <p className="pl_20">
                                                i. You may view a sum-total of
                                                daily payments in-app along with
                                                a tally of refunds, discounts
                                                and loyalties applied and tips
                                                received.
                                            </p>
                                            <p className="pl_20">
                                                ii. Historical transaction
                                                details may be viewed on the web
                                                platform.
                                            </p>
                                            <p>(i) Support:</p>
                                            <p className="pl_20">
                                                i. A library of frequently asked
                                                questions will be provided to
                                                you in-app and on the web
                                                platform.
                                            </p>
                                            <p className="pl_20">
                                                ii. You have the ability to
                                                contact us via the web platform.
                                                We aim respond to all queries
                                                within 24 hours.
                                            </p>
                                            <p>(j) Disputes:</p>
                                            <p className="pl_20">
                                                i. All payments are final once
                                                accepted by you at
                                                point-of-sale.
                                            </p>
                                            <p className="pl_20">
                                                ii. You accept responsibility
                                                for any reconciliation
                                                differences that result from
                                                accepting the wrong amount or
                                                opting for the auto-accept
                                                payment.
                                            </p>
                                            <p>(k) Data Privacy:</p>
                                            <p className="pl_20">
                                                i. We may track your location to
                                                help you set the store address
                                                in-app.
                                            </p>
                                            <p className="pl_20">
                                                ii. We do not sell or use your
                                                customer data to promote
                                                advertisements by other
                                                merchants.
                                            </p>
                                            <p className="pl_20">
                                                iii.In opening an account, you allow us to
                                                use your logo and/or store image on
                                                our website and/or our app to promote to our users.
                                            </p>
                                            <p className="pl_20">
                                                iv. You may view our complete
                                                privacy policy on our website at
                                                [www.unplank.com/privacy].
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>6. Notifications:</h2>
                                            <p>
                                                (a) You agree to receive
                                                notifications in relation to
                                                activity, required action or
                                                marketing offers in relation to
                                                your Unplank account.
                                            </p>

                                            <p>
                                                (b) These notifications will be
                                                sent to either your registered
                                                mobile or email address.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>7. Termination:</h2>
                                            <p>
                                                (a) You may close your account
                                                with us at any time unless there
                                                is an outstanding balance owed
                                                to us.
                                            </p>
                                            <p>
                                                (b) We may close your account,
                                                at our sole discretion,
                                                following (i) any delay in
                                                posting payment (ii) a period of
                                                inactivity or (iii) failure to
                                                validate any identity
                                                verification criteria requested
                                                at any time.
                                            </p>
                                            <p>
                                                (c) We reserve the right to
                                                modify, temporarily suspend or
                                                discontinue any service provided
                                                by Unplank at any time.
                                            </p>
                                            <p>
                                                (d) All provisions of these
                                                Terms shall survive termination,
                                                including, without limitation,
                                                ownership provisions, warranty
                                                disclaimers, indemnity and
                                                limitations of liability.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>8. Business Continuity:</h2>
                                            <p>
                                                (a) While no contingency plan
                                                can fully eliminate the risk of
                                                a temporary disruption to
                                                services, we maintain a robust
                                                set of procedures to maintain
                                                continuity of operations in the
                                                event of a disruption, both of
                                                an internal and external kind.
                                                These procedures include data
                                                backups held on the cloud and
                                                work from home provisions for
                                                key personnel located in
                                                different geographies to ensure
                                                continuity of service.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>
                                                9. Limited Liability and
                                                Indemnification:
                                            </h2>
                                            <p>
                                                (a) You agree to defend,
                                                indemnify and hold harmless
                                                Unplank Inc. and its licensees
                                                and licensors, and each of such
                                                party’s employees, contractors,
                                                agents, officers and directors,
                                                from and against any and all
                                                claims, damages, obligations,
                                                losses, liabilities, costs or
                                                debt, and expenses (including
                                                but not limited to
                                                attorney&#39;s fees), resulting
                                                from or arising out of (i) your
                                                use and access of the Service,
                                                by you or any person using your
                                                account, (ii) a breach of these
                                                Terms, and (iii) any other
                                                claims or causes of action you
                                                may have in any way relating to
                                                the Company or the Service.
                                            </p>
                                            <p>
                                                (b) In no event shall Unplank
                                                Inc. or its directors, officers,
                                                employees, partners, agents,
                                                suppliers, or affiliates, be
                                                liable for any indirect,
                                                incidental, special,
                                                consequential or punitive
                                                damages, including without
                                                limitation, loss of profits,
                                                data, use, goodwill, or other
                                                intangible losses, resulting
                                                from (i) your access to or use
                                                of or inability to access or use
                                                the Service, (ii) any conduct or
                                                content of any third party on
                                                the Service, (iii) any content
                                                obtained from the Service and
                                                (iv) unauthorized access, use or
                                                alteration of your transmissions
                                                or content, whether based on
                                                warranty, contract, tort
                                                (including negligence) or any
                                                other legal theory, whether or
                                                not we have been informed of the
                                                possibility of such damage, and
                                                even if a remedy set forth
                                                herein is found to have failed
                                                of its essential purpose.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>10. Complaints:</h2>
                                            <p>
                                                (a) We take complaints very
                                                seriously and endeavor to
                                                resolve all issues at the
                                                earliest time after they are
                                                received.
                                            </p>
                                            <p>
                                                (b) We request you to make all
                                                complaints via the support
                                                function provided in-app or on
                                                our platform.
                                            </p>
                                            <p>
                                                (c) You may equally send
                                                electronic communication to the
                                                contact us/complaints form on
                                                the website.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>11. Intellectual Property:</h2>
                                            <p>
                                                (a) The Service and its original
                                                content, features and
                                                functionality are and will
                                                remain the exclusive property of
                                                Unplank Inc. and its licensors.
                                                The Service is protected by
                                                copyright, trademark, and other
                                                laws of both the United States
                                                and foreign countries.
                                            </p>
                                            <p>
                                                (b) Our trademarks and trade
                                                dress may not be used in
                                                connection with any productor
                                                service without the prior
                                                written consent of Unplank Inc.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>12. Disclaimer:</h2>
                                            <p>
                                                (a) Your use of the Service is
                                                at your sole risk. The Service
                                                is provided on an “AS IS” and
                                                “AS AVAILABLE” basis. The
                                                Service is provided without
                                                warranties of any kind, whether
                                                express or implied, including,
                                                but not limited to, implied
                                                warranties of merchantability,
                                                fitness for a particular
                                                purpose, non-infringement or
                                                course of performance.
                                            </p>
                                            <p>
                                                (b) Unplank Inc., its
                                                subsidiaries, affiliates, and
                                                its licensors make no warranties
                                                as to the nature of the Service
                                                and make no representations that
                                                (i) the Service will function
                                                uninterrupted, securely or be
                                                available at any particular time
                                                or location;
                                            </p>
                                            <p>
                                                (ii) any errors or defects will
                                                be corrected; (iii) the Service
                                                is free of viruses or other
                                                harmful components; or (iv) the
                                                results of using the Service
                                                will meet your requirements.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>13. Governing Law:</h2>
                                            <p>
                                                (a) These Terms shall be
                                                governed and construed in
                                                accordance with the laws of the
                                                state of Delaware, United
                                                States, without regard to its
                                                conflict of law provisions. Any
                                                failure to enforce any right or
                                                provision under these Terms will
                                                not be considered a waiver of
                                                those rights. If any provision
                                                of these Terms is held to be
                                                invalid or unenforceable by a
                                                court, the remaining provisions
                                                of these Terms will remain in
                                                effect.
                                            </p>
                                            <p>
                                                (b) These Terms constitute the
                                                entire agreement between the
                                                Company and you regarding our
                                                Service and supersede and
                                                replace any prior agreements
                                                regarding the Service.
                                            </p>
                                        </div>
                                        <div className="privacy_policy_info_tc">
                                            <h2>14. Changes:</h2>
                                            <p>
                                                (a) We reserve the right, at our
                                                sole discretion, to modify or
                                                replace these Terms at any time.
                                                If a revision is material, we
                                                will provide at least 30 days’
                                                notice prior to any new terms
                                                taking effect. What constitutes
                                                a material change will be
                                                determined at our sole
                                                discretion. By continuing to
                                                access or use our Service after
                                                any revisions become effective,
                                                you agree to be bound by the
                                                revised terms. If you do not
                                                agree to the new terms, you are
                                                no longer authorized to use the
                                                Service.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Button
                                size="medium"
                                variant="contained"
                                color="primary"
                                style={btnAgree}
                                onClick={() => acceptTermsAndConditions(true)}
                            >
                                Agree
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
