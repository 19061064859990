import React from "react";
import { connect } from "react-redux";
import { SIDENAV_ITEMS } from "../constants";
import { COLORS } from "../constants";
import { FONTS } from "../constants";

const SUPPORT_BASE_URL = process.env.REACT_APP_SUPPORT_BASE_URL;

class LeftPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftBar: [...SIDENAV_ITEMS],
      activeMenu: "Customer Acquisition",
    };
  }

  componentDidMount() {
    const { pathname = "" } = this.props.location;
    const screen = this.state.leftBar.find((item) =>
      pathname.includes(item.keyRoute)
    );
    this.setState({ activeMenu: screen ? screen.label : "Balance" });
  }

  componentDidUpdate(prevProps){
    const { pathname = "" } = this.props.location;
    if(pathname !== prevProps.location.pathname){
      const screen = this.state.leftBar.find((item) =>
      pathname.includes(item.keyRoute)
      );
      this.setState({ activeMenu: screen ? screen.label : "Balance" });
    }
  }

  render() {
    const {
      leftNav,
      itemsContainer,
      eachNavItem,
      activeNavItem,
      navText,
      collapseIcon,
      activenavText
    } = leftNavStyles;
    const { leftBar } = this.state;
    const { path } = this.props;
    return (
      <div style={leftNav} className="sideNavbar">
        <div style={itemsContainer} className="">
          {/* <p style={collapseIcon}>
            <img src="/images/collapse.svg" style={{ cursor: "pointer" }} />
          </p> */}
          {leftBar.map(({ activeImage, inactive, label, keyRoute }) => (
            <div
              onClick={() => {
                this.props.history.push(`${path}${keyRoute}`);
                // this.setState({ activeMenu: label });
              }}
              key={label}
              style={{
                ...eachNavItem,
                ...(this.state.activeMenu === label ? activeNavItem : {}),
              }}
            >
              <img
                src={this.state.activeMenu === label ? activeImage : inactive}
              />
              <p style={this.state.activeMenu === label ? activenavText : navText}>{label}</p>
            </div>
          ))}
          <a href={`${SUPPORT_BASE_URL}/ticketing/redirect?session=${this.props.token}`} target="_blank" style={{
            textDecoration: 'none',
            color: 'unset'
          }}>
          <div style={{
                ...eachNavItem}}>
            <img src="/images/customer_support_inactive.svg" alt="support" />
          <p style={navText}>Customer Support</p>
          <img src="/images/customer_support_blue.png" className="customerImg" alt="launch customer support" />
          </div>
          </a>
        </div>
      </div>
    );
  }
}

export default connect(({ auth: { userDetails = {} } }) => {
  let { token } = userDetails;
  return { token };
})(LeftPanel);

export const leftNavStyles = {
  leftNav: {
    position: "fixed",
    marginTop: "65px",
    top: "0",
    left: "0",
    bottom: "0",
    // width: "200px",
    width: "210px",
    boxShadow: "0 0 6px #ccc",
    paddingTop: "55px",
    backgroundColor: COLORS.default_white,
  },
  // const navItemStyles = {
  //     inactiveItems : {
  //         position:"fixed",
  //         marginTop : "65px",
  //         top : "0",
  //         left : "0",
  //         bottom : "0",
  //         width : "200px",
  //         boxShadow :"0 0 6px #ccc",
  //         paddingTop: "50px",
  //     },
  //     activeItems : {
  //         color : COLORS.juma_theme_clr,
  //     }
  // }
  eachNavItem: {
    display: "flex",
    padding: "2px 10px 2px 15px",
    alignItems: "center",
    fontSize: FONTS.size_normal,
    cursor: "pointer",
  },
  navText: {
    margin: "12px 0",
    marginLeft: "10px",
    fontSize: FONTS.size_medium,
    //fontWeight: 400,
  },
  activenavText: {
    margin: "12px 0",
    marginLeft: "10px",
    fontSize: FONTS.size_medium,
    fontFamily: "PlusJakartaSans-SemiBold",
  },
  activeNavItem: {
    color: COLORS.juma_theme_clr,
    fontFamily: "PlusJakartaSans-Bold",
    backgroundColor: "#e8f1fe",
    borderRight: "3px solid",
    borderColor: COLORS.juma_theme_clr,
  },
  itemsContainer: {
    position: "relative",
  },
  collapseIcon: {
    position: "absolute",
    top: "-50px",
    right: "-10px",
  },
};
