// styles --->

// export const BUTTON_GREEN_COLOR = "#09f4c7";
export const COLORS = {
    BUTTON_GREEN_COLOR: "#09f4c7",
    default_white: "#fff",
    juma_theme_clr: "#1A7CFA",
    default_black: "#000",
    table_head: "#F4F9FF",
    theme_light_blue: "#F4F9FF",
};
export const FONTS = {
    size_max: "25px",
    size_macro: "22px",
    size_mega: "20px",
    size_xtra: "18px",
    size_large: "16px",
    size_normal: "15px",
    size_medium: "14px",
    size_small: "13px",
    size_minimum: "12px",
    size_mini: "11px",
    size_micro: "10px",
    size_tiny: "8px",
    font_heading: "32px",
};
export const SIDENAV_ITEMS = [
    {
        activeImage: "/images/balance_active.svg",
        inactive: "/images/balance_inactive.svg",
        label: "Balance",
        keyRoute: "/balance",
    },
    {
        activeImage: "/images/transaction_record_active.svg",
        inactive: "/images/transaction_record_inactive.svg",
        label: "Transaction Record",
        keyRoute: "/credits",
    },
    {
        activeImage: "/images/set_rewards_active.svg",
        inactive: "/images/set_rewards_inactive.svg",
        label: "Customer Acquisition",
        keyRoute: "/set-rewards",
    },
    {
        activeImage: "/images/activate_store_location_active.svg",
        inactive: "/images/active_store_location_inactive.svg",
        label: "Manage Storefront",
        keyRoute: "/stores",
    },
    {
        activeImage: "/images/admin_active.svg",
        inactive: "/images/admin_inactive.svg",
        label: "Admin",
        keyRoute: "/admin",
    },
    {
        activeImage: "/images/admin_active.svg",
        inactive: "/images/admin_inactive.svg",
        label: "Customer Support",
        externalLink: true,
    },
    {
      inactive: '/images/logout_inactive.svg',
      label: 'Logout',
      keyRoute: '/balance',
    },
];

export const Email_regex =
    /^\w+([\.-]?\w+)+(\+\d)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const IMAGE_LOGO_TYPES = ["image/png", "image/jpeg", "image/jpg"];

export const DOCUMENT_TYPES = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];


export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY
export const DISABLE_LINK_ACCOUNT_BTN = process.env.REACT_APP_DISABLE_LINK_ACCOUNT_BTN === "true";
export const DSN_KEY = process.env.REACT_APP_DSN_KEY
export const DISABLE_STATEMENT_TABLE = process.env.REACT_APP_DISABLE_STATEMENT_TABLE === "true";

//* Dashboard Routes
export const DASHBOARD_ROUTE = '/portal'
export const REWARDS_ROUTE = `${DASHBOARD_ROUTE}/set-rewards`


//* Discount Routes

export const ONE_OFF_DISCOUNT_ROUTE = `${REWARDS_ROUTE}/one-off`
export const CONDITIONAL_DISCOUNT_ROUTE = `${REWARDS_ROUTE}/conditional`
export const ADD_NEW_DISCOUNT_ROUTE = `${ONE_OFF_DISCOUNT_ROUTE}/add`
export const EDIT_DISCOUNT_ROUTE = `${ONE_OFF_DISCOUNT_ROUTE}/edit`
export const ADD_NEW_LOYALTY_ROUTE = `${CONDITIONAL_DISCOUNT_ROUTE}/add`
export const EDIT_LOYALTY_ROUTE = `${CONDITIONAL_DISCOUNT_ROUTE}/edit`

