import { GET_CREDIT_NOTES, GET_CREDIT_NOTES_SUCCESS } from "../types/creditNotes";
import { LOGOUT_SUCCESS } from "../types/auth";

const initState = {
  docs : [],
  page : 0,
  limit : 0,
  pages : 0,
  total : 0
}



export default function transactionReducer(state = initState, action) {
  switch (action.type) {
    case GET_CREDIT_NOTES_SUCCESS: return action.body;

    case LOGOUT_SUCCESS: {
      return initState;
    }


    default:
      return state;
  }
}
