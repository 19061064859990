import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function OtpProtectedRoute({ token = null, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !!token ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default connect(({ auth: { intialToken = '' } }) => {
  return { token : intialToken };
})(OtpProtectedRoute);
