export const GET_STORES = "GET_STORES";
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS";
export const UPLOAD = "UPLOAD";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";


export const ADD_STAFF = "ADD_STAFF";
export const ADD_AISLE = "ADD_AISLE";
export const DISABLE_STAFF = "DISABLE_STAFF";
export const DISABLE_AISLE = "DISABLE_AISLE";
export const DISABLE_STORE = "DISABLE_STORE";
export const GET_AISLE_TOKEN = "GET_AISLE_TOKEN";
export const UPDATE_AISLE = "UPDATE_AISLE";
export const UNLINK_DEVICE = "UNLINK_DEVICE";
export const TOGGLE_CHAT = 'TOGGLE_CHAT';