import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";


export default function VideoDialog({ open, closeVideoPopup }) {

    return (
        <Dialog
            open={open}
            onClose={closeVideoPopup}
            className="video_popup_sec"
            fullWidth={true}
            maxWidth="sm"
        >   
            <DialogTitle>
            <Box onClick={() => closeVideoPopup()}>
                <img
                    src="/images/close_menu.svg"
                    alt="close"
                    style={{ float: "right", cursor: 'pointer' }}
                />
            </Box> 
            </DialogTitle>
            <Box>
                <DialogContent>
                    <iframe className="BannerVideo"
                        src="https://www.youtube.com/embed/exGksEkpQeY?autoplay=1&rel=0" frameBorder="0" allow="autoplay">
                    </iframe>
                </DialogContent>
            </Box>
        </Dialog>
    )
}