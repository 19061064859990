import { combineReducers } from "redux";
import auth, { scrollIntoViewReducer } from "./auth";
import loaderReducer from "./loader";
import parametersReducer from "./parameters";
import rewardsReducer from "./rewards";
import storesReducer from './stores';
import transactionReducer from "./transaction";

export default (history) => {
  return combineReducers({
    router: history,
    auth,
    loader: loaderReducer,
    rewardsReducer,
    stores: storesReducer,
    scroll: scrollIntoViewReducer,
    transaction: transactionReducer,
    parameters: parametersReducer
  });
};
