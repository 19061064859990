import React from "react";
import Modal from "@material-ui/core/Modal";
import { Button, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    removeScrollToView,
    scrollToView,
    sendEmail,
} from "../../store/actions/auth";
import { Formik, useField } from "formik";
import * as yup from "yup";

export function FormikTextField({
    name,
    style,
    value,
    required = true,
    label,
    variant = "outlined",
    onChange,
    multiline = false,
    minRows = 1,
}) {
    const [field, meta] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : "";

    onChange = onChange || field.onChange;

    value = value || field.value;

    return (
        <TextField
            {...field}
            value={value}
            style={{ ...style }}
            required={required}
            onChange={onChange}
            label={label}
            variant={variant}
            multiline={multiline}
            minRows={minRows}
            error={!!errorText}
            helperText={errorText ? errorText : ""}
        />
    );
}

const initialModalState = {
    fullName: "",
    email: "",
    subject: "",
    message: "",
};

const emailRegex =
    /^[a-zA-Z0-9](?!.*?[^\na-zA-Z0-9]{2})[^\s@]+@[^\s@]+\.[^\s@]+[a-zA-Z0-9]$/;

const validationSchema = yup.object({
    fullName: yup.string().required("Full name is required"),
    email: yup
        .string()
        .matches(emailRegex, "Invalid email id")
        .required("Email is required"),
    subject: yup.string().required("Subject is required"),
    message: yup.string().required("Message is required"),
});

const subjectMasters = [
    { value: "Contact us", heading: "Contact us", key: "contact_us" },
    { value: "Partnerships", heading: "Partnerships", key: "partnership" },
    { value: "Complaints", heading: "Complaints", key: "complaints" },
    { value: "Work at Unplank", heading: "Work with us", key: "work_with_us" },
];
export default function Footer({ footerRef }) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [modelSubject, setModelSubject] = React.useState("");
    const [modalState, setModalState] = React.useState(initialModalState);
    const [successModal, setSuccessModal] = React.useState(false);

    const handleOpen = (subject) => {
        setOpen(true);
        setModelSubject(subject);
        if (subject !== "contact_us") {
            setModalState({
                ...modalState,
                subject,
            });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setModelSubject("");
        setModalState(initialModalState);
    };

    const history = useHistory();

    const handleEmailSubmission = async (values) => {
        await dispatch(
            sendEmail({
                ...values,
            })
        );
        handleClose();
        setSuccessModal(true);
    };

    return (
        <div className="landing_footer_sec" ref={footerRef}>
            <div className="max-width">
                <div className="footer_sec">
                    <div className="footer_left_sec">
                        <img
                            src="/images/unplank_white_logo.svg"
                            alt="footer logo icon"
                        />
                        <p>
                            Unplank is a frictionless point-of-sale interface
                            that empowers merchants to acquire customers and
                            take payment in-store, free of interchange fees and
                            platform commissions. It simultaneously allows
                            institutional owners of capital to lend to borrowers
                            at scale without the cost or need for
                            securitization. The intended consequence of these
                            cost savings for customers is a higher level of
                            direct rewards from merchants and a flexible credit
                            line at competitive rates, if and when they need it.
                        </p>
                        <p>
                            We at Unplank, combine financial expertise with deep
                            engineering talent to deliver an integrated platform
                            that facilitates this symbiotic eco-system of
                            customers, merchants and lenders.{" "}
                        </p>
                        <div className="copy_right_sec">
                            <hr className="desktop_none hr_line" />
                            <span>
                                © Unplank Inc. 2022 All rights reserved.
                            </span>
                            {/* <a href="">Privacy Policy</a> */}
                        </div>
                    </div>
                    <div className="footer_list_sec">
                        <div className="footer_list_inner">
                            <div className="footer_list">
                                <ul>
                                    {/* <li
                                        onClick={() => history.push("merchant")}
                                    >
                                        Merchants
                                    </li> */}
                                    <li
                                        onClick={() => history.push("shop-with-unplank")}
                                    >
                                        Shop with Unplank
                                    </li>
                                    <li onClick={() => history.push("lenders")}>
                                        Lenders
                                    </li>
                                    <li
                                        onClick={() => {
                                            dispatch(removeScrollToView());
                                            history.push("/new-merchant-signup");
                                            dispatch(scrollToView());
                                        }}
                                    >
                                        Sign-up
                                    </li>
                                    <li onClick={() => history.push("merchant-signin")}>Business sign-in</li>
                                    <li
                                        onClick={() =>
                                            history.push("privacy-policy")
                                        }
                                    >
                                        Privacy Policy
                                    </li>
                                </ul>
                            </div>
                            <div className="footer_list">
                                <ul>
                                    {subjectMasters.map((ele) => (
                                        <li
                                            onClick={() => handleOpen(ele.key)}
                                            key={ele.key}
                                        >
                                            {ele.heading}
                                        </li>
                                    ))}
                                    <li
                                        onClick={() =>
                                            history.push("terms-of-use")
                                        }
                                    >
                                        Terms of use
                                    </li>
                                </ul>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className="modal_main_sec"
                                >
                                    <div className="modal_sec">
                                        <div className="modal_content">
                                            <img
                                                src="/images/close_icon.svg"
                                                alt="close icon"
                                                className="close_icon"
                                                onClick={handleClose}
                                            />
                                            <h2 id="simple-modal-title">
                                                {
                                                    subjectMasters.find(
                                                        (e) =>
                                                            e.key ===
                                                            modelSubject
                                                    )?.heading
                                                }
                                            </h2>
                                            <Formik
                                                initialValues={modalState}
                                                onSubmit={handleEmailSubmission}
                                                validationSchema={
                                                    validationSchema
                                                }
                                                enableReinitialize={true}
                                            >
                                                {({
                                                    setFieldValue,
                                                    errors,
                                                    handleSubmit,
                                                    values,
                                                }) => (
                                                    <div className="modal_form_list">
                                                        <FormikTextField
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            label={"Full Name"}
                                                            name={"fullName"}
                                                        />
                                                        <FormikTextField
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            label={"Email-id"}
                                                            name={"email"}
                                                        />
                                                        <FormikTextField
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            label={"Subject"}
                                                            name={"subject"}
                                                            required={true}
                                                            value={
                                                                modelSubject ===
                                                                "contact_us"
                                                                    ? values.subject
                                                                    : subjectMasters.find(
                                                                          (
                                                                              ele
                                                                          ) =>
                                                                              ele.key ===
                                                                              values.subject
                                                                      ).value
                                                            }
                                                            onChange={({
                                                                target: {
                                                                    value,
                                                                },
                                                            }) =>
                                                                modelSubject ===
                                                                "contact_us"
                                                                    ? setFieldValue(
                                                                          "subject",
                                                                          value
                                                                      )
                                                                    : null
                                                            }
                                                        />
                                                        <FormikTextField
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            label={"Message"}
                                                            name={"message"}
                                                            multiline={true}
                                                            minRows={5}
                                                        />
                                                        <Button
                                                            className="submit_btn"
                                                            type="submit"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal
                                    open={successModal}
                                    onClose={() => setSuccessModal(false)}
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    className="success_modal_sec"
                                >
                                    <div className="success_msg_sec">
                                        <img
                                            src="/images/cross_icon.svg"
                                            alt="modal close icon"
                                            className="modal_close_icon"
                                            onClick={() =>
                                                setSuccessModal(false)
                                            }
                                        />
                                        <img
                                            src="/images/tick_icon.svg"
                                            alt="icon"
                                        />
                                        <p>
                                            Thank you for reaching out to us.
                                            <br /> We will respond shortly.
                                        </p>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div className="social_links_sec">
                            <p>SOCIAL MEDIA</p>
                            <div>
                                <img
                                    src="/images/facebook.png"
                                    alt="facebook"
                                    onClick={()=>window.open("https://m.facebook.com/100084899503679/","_blank")}
                                />
                                <img
                                    src="/images/instagram.png"
                                    alt="instagram"
                                    onClick={()=>window.open("https://www.instagram.com/getunplank/?hl=en","_blank")}
                                    //onClick={window.open("https://www.instagram.com/getunplank/?hl=en", "_blank")}
                                />
                                <img
                                    src="/images/linkedin.png"
                                    alt="likedin"
                                    onClick={()=>window.open("https://www.linkedin.com/company/unplank/","_blank")}
                                    //onClick={window.open("https://www.linkedin.com/company/unplank/", "_blank")}
                                />
                            </div>
                        </div>
                        {/* <div className="copy_right_sec">
                            <hr className="desktop_none hr_line" />
                            <span>
                                © Unplank Inc. 2022 All rights reserved.
                            </span>
                            <a href="">Privacy Policy</a>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
