import { CALL_API } from "../api.middleware";
import { GET_CREDIT_NOTES, GET_CREDIT_NOTES_SUCCESS } from "../types/creditNotes";

export const getCreditNotes = (parameters) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/payments/merchant/history`,
        showLoader: true,
        method: "GET",
        types: [GET_CREDIT_NOTES, GET_CREDIT_NOTES_SUCCESS],
        params: parameters,
        // showToast: true,
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};
