import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";


export default function LinkBankDialog({open, closeDialog}){

    return(
        <Dialog
        open={open}
        onClose={closeDialog}
        className="bank_popup_sec"
    >
        <Box>
            <Box onClick={() => closeDialog()}>
                <img
                    src="/images/close_menu.svg"
                    alt="close"
                    style={{ float: "right", cursor: 'pointer' }}
                />
            </Box>
            <DialogTitle>
                <Box className="VerifyAccountHeading">
                    <Typography>Link Bank Account</Typography>
                    {/* <div className="commingSoonSec">
                        <img src="/images/comming_soon.svg" alt="comming soon" />
                        <p>coming soon!</p>
                    </div> */}
                </Box>
            </DialogTitle>
            <DialogContent>
                <div className="accountQuestionsSec">
                    <div className="accountQuestion">
                        How do I link my bank account?
                    </div>
                    <div className="accountAnswere">
                        Go to “Link Bank Account” tab in the Admin section on 
                        the left-hand side menu and press on the “Link Bank Account” tab.
                    </div>
                    <div className="accountQuestion">
                        What happens when I click on the “Link Bank Account” tab?
                    </div>
                    <div className="accountAnswere">
                        <ul className="accountAnswereList">
                            <li>You will be asked to log into your online banking service through a secure link provided by our trusted partner 
                            <span className="directTxt">DirectID.</span></li>
                            <li>Neither DirectID nor Unplank will be able to see your login credentials.</li>
                            <li>By logging in, you will be consenting to sharing read-only account details for a limited time.</li>
                        </ul>
                    </div>
                    <div className="accountQuestion">
                        Why do I have to link my bank account?
                    </div>
                    <div className="accountAnswere">
                        To protect our business users from fraud, we verify that the bank account
                        we are paying funds to belongs to the signed-up business entity.
                    </div>
                    <div className="linkDialogFooter">
                        <Typography>Stay alert to fraud</Typography>
                        <Typography>We will never ask for your login credentials</Typography>
                        <Typography>Never reveal your username or password of your bank or Unplank account to anyone</Typography>
                    </div>
                </div>
            </DialogContent>
        </Box>
    </Dialog>
    )
}