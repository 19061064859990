import React, { Fragment, useEffect } from "react";
import { Button } from "@material-ui/core";
import {
  COLORS,
  CONDITIONAL_DISCOUNT_ROUTE,
  EDIT_LOYALTY_ROUTE,
} from "../../constants";
import Table from "@material-ui/core/Table";
import {
  createLoyalty,
  deleteLoyatly,
  editLoyalty,
  getLoyaltyById,
  loyaltyList,
} from "../../store/actions/rewards";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { SelectInput, TextInput } from "../../forms/textinput";
import DatePicker from "../../forms/datePicker";
import DeleteDialog from "./DeleteDialog";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  addDiscount,
  contentWrapper,
  eachInput,
  setBtn,
  loyaltyWrapper,
  loyaltyAdd,
} from "./rewardStyle";
import moment from "moment";
import { useState } from "react";
import SetLoyaltyConfirmation from "./SetLoyaltyConfirmation";
import { formatDate } from "../../utils/commonFunc";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export function LoyaltyList() {
  const [state, setState] = useState({ id: null });
  const dispatch = useDispatch();
  const { loyalty } = useSelector(({ rewardsReducer }) => rewardsReducer);

  useEffect(() => {
    dispatch(loyaltyList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDays = (toDate, fromDate) => {
    const a = moment(toDate);
    const b = moment(fromDate);
    const c = a.diff(b, "days");
    return c + 1;
  };

  const closeDialog = () => {
    setState({ id: null });
  };

  const onDeleteLoyalty = (id) => async () => {
    await dispatch(deleteLoyatly(id));
    dispatch(loyaltyList());
  };

  const onDelete = (item) => () => {
    const { id } = item;
    setState({
      id: id,
    });
  };

  return (
    <div style={loyaltyWrapper} className="loyalty_wrapper">
      <Fragment>
        <Link
          to={`${CONDITIONAL_DISCOUNT_ROUTE}/add`}
          style={{ textDecoration: "none" }}
        >
          <Button style={loyaltyAdd} className="fontBold add_tierBtn">
            {" "}
            + Add Promotion
          </Button>
        </Link>
        <Fragment>
          <div
            style={{ marginTop: "70px" }}
            className="tableWrapper table_desktop"
          >
            <TableContainer>
              <Table aria-label="simple table" className="rewardsTable">
                <TableHead style={{ backgroundColor: COLORS.table_head }}>
                  <TableRow style={{ verticalAlign: "top" }}>
                    <TableCell>$ Spend threshold</TableCell>
                    <TableCell>
                      Reward
                      <br />
                      <span style={{ fontSize: 10, color: "#0E0E0E" }}>
                        (% of $ spend)
                      </span>
                    </TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>
                      Time Window <br />
                      <span style={{ fontSize: 10, color: "#0E0E0E" }}>
                        (No. of Days)
                      </span>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loyalty.map((item, index) => (
                    <TableRow key={`row-${index + 1}`}>
                      <TableCell>{item.spend}</TableCell>
                      {/* <TableCell>{`${item.days} days`}</TableCell> */}
                      <TableCell>{`${item.reward}%`}</TableCell>
                      <TableCell>{formatDate(item.fromDate)}</TableCell>
                      <TableCell>{formatDate(item.toDate)}</TableCell>
                      <TableCell>
                        {getDays(item.toDate, item.fromDate)} days
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`${EDIT_LOYALTY_ROUTE}/${item.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <p
                            className={
                              new Date(item.fromDate).getTime() -
                                new Date().getTime() >
                              0
                                ? "editBtn"
                                : "hideBtn"
                            }
                          >
                            {new Date(item.fromDate).getTime() -
                              new Date().getTime() && "Edit"}
                          </p>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <p
                          className={`${
                            new Date(item.fromDate).getTime() >=
                            new Date().getTime()
                              ? "deleteBtn"
                              : "hideBtn"
                          }`}
                          onClick={onDelete(item)}
                        >
                          {new Date(item.fromDate).getTime() >=
                            new Date().getTime()
                            ? "Delete"
                            : null}
                        </p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {/* mobile table start */}
          <div style={{ marginTop: 70 }}>
            {loyalty.map((item, index) => (
              <div key={index} className="tableWrapper table_mobile">
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>$ Spend threshold </TableCell>
                        <TableCell>End Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={`row-${index + 1}`}>
                        <TableCell>{item.spend}</TableCell>
                        <TableCell>{formatDate(item.toDate)}</TableCell>
                      </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Reward <br />
                          (% of $ spend)
                        </TableCell>
                        <TableCell>
                          Time Window <br />
                          (No. of Days)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="date_table_border_bottom">
                      <TableRow key={`row-${index + 1}`}>
                        <TableCell>{`${item.reward}%`}</TableCell>
                        <TableCell>{`${getDays(
                          item.toDate,
                          item.fromDate
                        )} days`}</TableCell>
                      </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell>Start Date</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={`row-${index + 1}`}>
                        <TableCell>{formatDate(item.fromDate)}</TableCell>
                        <TableCell>
                          <div className="loyalty_table_btn_mob">
                            <Link
                              to={`${EDIT_LOYALTY_ROUTE}/${item.id}`}
                              style={{ textDecoration: "none" }}
                            >
                              <p
                                className={
                                  new Date(item.fromDate).getTime() -
                                    new Date().getTime() >
                                  0
                                    ? "editBtn"
                                    : "hideBtn"
                                }
                              >
                                {new Date(item.fromDate).getTime() -
                                  new Date().getTime() && "Edit"}
                              </p>
                            </Link>
                            <p
                              className={`${
                                new Date(item.fromDate).getTime() >=
                                new Date().getTime()
                                  ? "deleteBtn"
                                  : "hideBtn"
                              }`}
                              onClick={onDelete(item)}
                            >
                              {new Date(item.fromDate).getTime() >=
                              new Date().getTime()
                                ? "Delete"
                                : null}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ))}
          </div>
          <DeleteDialog
            id={state.id}
            closeDialog={closeDialog}
            deleteDiscount={onDeleteLoyalty(state.id)}
          />
        </Fragment>
      </Fragment>
    </div>
  );
}


const validationSchema = yup.object({
  spend: yup.string().required("Spend is required").test('reward', 'Spend must be greater than 0',(value) => (value>=1)),
  reward: yup.string().required("Reward is required").test('reward', 'Reward must be in between 1% to 100 %',(value) => (value>=1 && value<=100)),
  fromDate: yup.string().test('fromDate', 'Loyalty can not be created for current date and anyday before the current date', (value) => {
    console.log(new Date(value), new Date(), 'fromDate')
    return new Date(value).getTime() > new Date().getTime()
  }),
  toDate: yup.string().test('toDate', 'Valid To Date should be after the Valid From date', (value, context) => {
    const {parent} = context
    // console.log(new Date(value), new Date(parent.fromDate), 'toDate')
    return new Date(value).getTime() >=  new Date(parent.fromDate).getTime()
  }),
  description: yup.string().required("Description is required"),
});


export function LoyaltyAdd() {
  const [open, setOpen] = useState(false);

  const onConfirmLoyaltyDialog = (values) => {
    onCreateLoyalty(values);
    setOpen(false);
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const { id: editLoyaltyId } = useParams();

  const options = [
    { label: "Cashback T&Cs apply", value: "Cashback T&Cs apply" },
    { label: "Promo", value: "Promo" },
  ];

  let date = new Date();
  date.setDate(date.getDate() + 1);


  useEffect(() => {
    editLoyaltyId &&
      dispatch(getLoyaltyById(editLoyaltyId)).then(
        ({ spend, reward, fromDate, toDate, description }) => {
         formik.setFormikState({values:{
            spend: parseInt(Number(spend)),
            reward: reward,
            fromDate: fromDate,
            toDate: toDate,
            description: description
         }})
        }
      );
  }, [editLoyaltyId]);





  const onCreateLoyalty = async (values) => {
    const isEditCall = !!editLoyaltyId ? true : false;
    console.log(values)
    const body = {
      spend: Number(values.spend),
      reward: Number(values.reward),
      fromDate: moment(values.fromDate).startOf("day").toISOString(),
      toDate: moment(values.toDate).endOf("day").toISOString(),
      description: values.description,
    };

    console.log('body',body)

    if (open) {
      let res = isEditCall
        ? await dispatch(editLoyalty(editLoyaltyId, body))
        : await dispatch(createLoyalty(body));
      if (res) {
        formik.resetForm()
        history.goBack();
      }
    } 
  };


  const formik = useFormik({
    initialValues: {
      spend: "",
      reward: "",
      fromDate: date,
      toDate: date,
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log('values', values, formik.isValid);
      try{
        setOpen(true)
      }
      catch(e){
         console.error(e)
      }
    }
  });


  // console.log(formik.errors)
  return (
    <>
      <form onSubmit={formik.handleSubmit} style={addDiscount} className="add_discount discount_sec">
        <p className="fontBold" style={{ display: "flex" }}>
          <img
            onClick={history.goBack}
            src="/images/back.svg"
            style={{ marginRight: "15px", marginTop: "2px", cursor: "pointer" }}
            alt="back icon"
          />
          {!!editLoyaltyId ? "Edit Promotion" : " Set Promotion"}
        </p>
        <div style={contentWrapper}>
          <div className="new_discount_main promotionWrapper">
            <div style={eachInput} className="new_discount_ip edit-wrapper">
              <TextInput
                style={{ width: "100%" }}
                label={"$ Spend threshold"}
                value={formik.values.spend}
                onChange={({target: {value}}) => {
                  if (!/^[\d]*$/.test(value)) {
                    return;
                  }
                  formik.setFieldValue('spend', value)
                }}
                error={formik?.touched?.spend && Boolean(formik?.errors?.spend)}
                helperText={formik?.touched?.spend && formik?.errors?.spend}
                // type="number"
                name="spend"
                inputProps={{ inputMode: "numeric" }}
              ></TextInput>
            </div>
            <div style={eachInput} className="new_discount_ip edit-wrapper">
              <TextInput
                style={{ width: "100%" }}
                label={"Reward (% of $ spend )"}
                value={formik.values.reward}
                onChange={({target: {value}}) => {
                  if (!/^[\d]*$/.test(value)) {
                    return;
                  }
                  formik.setFieldValue('reward', value)
                }}
                error={formik?.touched?.reward && Boolean(formik?.errors?.reward)}
                helperText={formik?.touched?.reward && formik?.errors?.reward}
                // type="number"
                name='reward'
                inputProps={{ inputMode: "numeric" }}
              ></TextInput>
            </div>
          </div>
          <div className="new_discount_main promotionWrapper">
            <div style={eachInput} className="new_discount_ip">
              <DatePicker
                minDate={new Date(date)}
                // minDate={new Date("2022-09-10")}
                name="fromDate"
                label="Valid From Date"
                selectedDate={formik?.values?.fromDate}
                handleDateChange={(date) => formik.setFieldValue('fromDate', date)}
                error={formik?.touched?.fromDate && Boolean(formik?.errors?.fromDate)}
                helperText={formik?.touched?.fromDate && formik?.errors?.fromDate}
              />
            </div>
            <div style={eachInput} className="new_discount_ip">
              <DatePicker
                minDate={new Date(date)}
                // minDate={new Date("2022-09-10")}
                name='toDate'
                label="Valid To Date"
                selectedDate={formik?.values?.toDate}
                handleDateChange={(date) => formik.setFieldValue('toDate', date)}
                error={formik?.touched?.toDate && Boolean(formik?.errors?.toDate)}
                helperText={formik?.touched?.toDate && formik?.errors?.toDate}
              />
            </div>
          </div>
          <div className="new_discount_main promotionWrapper">
            <div style={eachInput} className="new_discount_ip descriptionSec">
              <SelectInput
                style={{ width: "100%" }}
                name='description'
                label={"Choose description"}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik?.touched?.description && Boolean(formik?.errors?.description)}
                helperText={formik?.touched?.description && formik?.errors?.description}
                type="text"
                options={options}
                placeHolder="Choose description"
              ></SelectInput>
            </div>
            <div style={eachInput} className="new_discount_ip"></div>
          </div>
          <div style={{ marginTop: 30 }}>
            <Button
              // onClick={() => {
              //   setOpen(true)
              //   }}
              color="primary"
              style={setBtn}
              type='submit'
            >
              {!!editLoyaltyId ? "SAVE" : "SET"}
            </Button>
          </div>
        </div>
      </form>
      <SetLoyaltyConfirmation
        open={open}
        setOpen={setOpen}
        createLoyalty={onConfirmLoyaltyDialog}
        // spend={formik?.values?.spend}
        // reward={formik?.values?.reward}
        // fromDate={formik?.values?.fromDate}
        // toDate={formik?.values?.toDate}
        values={formik?.values}
      />
    </>
  );
}
