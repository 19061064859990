import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_KEY } from "../../constants";
import { bookDemo } from "../../store/actions/auth";

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  title: yup
    .string()
    .required('Title is required'),
  businessCategory: yup
    .string(),
  // .required('Business category is required'),
  businessName: yup
    .string()
    .required('Business name is required'),
});

export default function BookADemoDialog({
  open,
  closeVideoPopup,
  header,
  subject,
}) {
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useDispatch();

  const onChange = (value) => {
    setIsVerified(true);
  };

  const resetCaptcha = () => {
    setIsVerified(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      title: "",
      businessCategory: "",
      businessName: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (isVerified) {
        const { name, businessCategory, ...rest } = values;
        await dispatch(
          bookDemo({
            fullName: name,
            ...rest,
            category: businessCategory,
            subject: subject ? subject : null,
          })
        );
        resetForm();
        resetCaptcha();
        closeVideoPopup();
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        formik.resetForm()
        resetCaptcha()
        closeVideoPopup()
      }}
      className="demo_popup_sec"
      fullWidth={true}
      maxWidth="sm"
    >
      <Box>
        <Box onClick={() => {
          formik.resetForm()
          resetCaptcha()
          closeVideoPopup()
        }}>
          <img
            src="/images/close_menu.svg"
            alt="close"
            style={{ float: "right", cursor: "pointer" }}
          />
        </Box>
        <DialogTitle>{!!header ? header : "Book a Demo"}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Box className="demo_form_list">
              <TextField
                fullWidth
                type="text"
                id="name"
                name="name"
                variant="outlined"
                label="Full Name*"
                autoComplete="off"
                value={formik.values.name}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue("name", value.trim() ? value : "");
                }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                type="text"
                id="email"
                name="email"
                variant="outlined"
                label="Email*"
                autoComplete="off"
                value={formik.values.email}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue("email", value.trim() ? value : "");
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                fullWidth
                type="text"
                id="title"
                name="title"
                variant="outlined"
                label="Title*"
                autoComplete="off"
                value={formik.values.title}
                // onChange={formik.handleChange}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue("title", value.trim() ? value : "");
                }}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              <TextField
                fullWidth
                type="text"
                id="businessCategory"
                name="businessCategory"
                variant="outlined"
                label="Business Category"
                autoComplete="off"
                value={formik.values.businessCategory}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue("businessCategory", value.trim() ? value : "");
                }}
                error={formik.touched.businessCategory && Boolean(formik.errors.businessCategory)}
                helperText={formik.touched.businessCategory && formik.errors.businessCategory}
              />
              <TextField
                fullWidth
                type="text"
                id="businessName"
                name="businessName"
                variant="outlined"
                label="Name of Business*"
                autoComplete="off"
                value={formik.values.businessName}
                onChange={({ target: { value } }) => {
                  formik.setFieldValue("businessName", value.trim() ? value : "");
                }}
                error={formik.touched.businessName && Boolean(formik.errors.businessName)}
                helperText={formik.touched.businessName && formik.errors.businessName}
              />
              <Box className="reCapchaSecDemo">
                <ReCAPTCHA
                  sitekey={CAPTCHA_KEY}
                  onChange={onChange}
                  type="image"
                  onExpired={resetCaptcha}
                />
              </Box>
              <Button
                className="submit_btn"
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={formik.isSubmitting || !isVerified}
              >
                Submit
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
