import {
	Button,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { SelectInput, TextInput } from '../../forms/textinput';
import DatePicker from '../../forms/datePicker';
import FileUpload from '../../forms/fileUpload';
import {
	rewardTabOptions,
	addBtn,
	listwrapper,
	btnActions,
	addDiscount,
	contentWrapper,
	eachInput,
	saveBtn,
} from './rewardStyle';
import {
	deleteDiscount,
	getDiscountList,
	getDiscountById,
	editDiscount,
	createDiscount,
	fileUpload,
} from '../../store/actions/rewards';
import moment from 'moment';
import DeleteDialog from './DeleteDialog';
import { formatDate } from '../../utils/commonFunc';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
	ADD_NEW_DISCOUNT_ROUTE,
	EDIT_DISCOUNT_ROUTE,
	ONE_OFF_DISCOUNT_ROUTE,
} from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleBackdrop } from '../../rootRouter';

export function DiscountList(props) {
	const [state, _setState] = useState({ id: null, filter: 'current' });
	function setState(_state) {
		return _setState(s => ({...s, ..._state}));
	}
	const dispatch = useDispatch();

	const { discounts, showLoader } = useSelector(
		({ rewardsReducer }) => rewardsReducer
	);
	useEffect(() => {
		dispatch(getDiscountList());
	}, []);

	const closeDialog = () => {
		setState({ id: null });
	};

	const onChange = (e) => {
		setState({ filter: e.target.value });
	};

	const onDelete =
		({ id }) =>
		() => {
			setState({ id: id });
		};

	const onDeleteDiscount = (id) => async () => {
		await dispatch(deleteDiscount(id));
		dispatch(getDiscountList());
	};

	const filterDiscounts = (items = []) => {
		try {
			const filetredList = items
				.sort((a, b) => new Date(a.toDate) - new Date(b.toDate))
				.reduce((prev, curr) => {
					const start = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
					const fromTime = new Date(curr.fromDate).getTime();
					const endTime = new Date(curr.toDate).getTime();
					if (fromTime - start > 0) {
						return { ...prev, upcoming: [...(prev.upcoming || []), curr] };
					} else if (start - endTime > 0) {
						return { ...prev, archived: [...(prev.expired || []), curr] };
					} else {
						return { ...prev, current: [...(prev.current || []), curr] };
					}
				}, {});
			return filetredList;
		} catch (err) {
			return items;
		}
	};

	const filteredDiscounts = filterDiscounts(discounts);
	return (
    <div className='discount_main_sec'>
		<Fragment>
			<div className='rewardTab_options' style={rewardTabOptions}>
				<div className='reward_option_wid'>
					<RadioGroup
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
						value={state.filter}
						onChange={onChange}>
						<FormControlLabel
							value='current'
							control={<Radio size='small' color='primary' />}
							label='Current'
						/>
						<FormControlLabel
							value='upcoming'
							control={<Radio size='small' color='primary' />}
							label='Upcoming'
						/>
						<FormControlLabel
							value='archived'
							control={<Radio size='small' color='primary' />}
							label='Archived'
							// className="archived_label"
						/>
					</RadioGroup>
					<Link
						to={`${ADD_NEW_DISCOUNT_ROUTE}`}
						style={{ textDecoration: 'none' }}>
						<Button style={addBtn}>+ Add New</Button>
					</Link>
				</div>
				<div style={listwrapper} className='listItemwrapper'>
					{!!(filteredDiscounts[state.filter] || []).length ? (
						(filteredDiscounts[state.filter] || []).map((item, index) => (
							<div key={index} className='discount_Item'>
								{' '}
								{/* list of items to be looped */}
								<div
									style={{
										backgroundImage: `url(${
											item.logo
												? item.logo?.url
												: '/images/store-default-image.svg'
										})`,
									}}
									className='discount_imagewrapper'></div>
								<div className='fontBold discountText'>
									<img
										src='/images/offer_icon.svg'
										alt='edit'
										style={{ marginRight: '10px', marginBottom: '-2px' }}
									/>
									{`${parseFloat(item?.discount)}% ${item.discription}`}
								</div>
								<p className='expireOnTxt'>{`Expires on ${formatDate(
									item.toDate
								)}`}</p>
								{state.filter === 'upcoming' ? (
									<div style={btnActions} className='actionBtn'>
										{' '}
										{/* show/hide this div based on tab */}
										<Link
											to={`${EDIT_DISCOUNT_ROUTE}/${item.id}`}
											style={{ textDecoration: 'none' }}>
											<Button
												variant='outlined'
												style={{ margin: '0px 10px' }}>
												Edit
											</Button>
										</Link>
										<Button
											variant='contained'
											onClick={onDelete(item)}
											style={{ margin: '0px 10px 0px 0px' }}>
											Delete
										</Button>
									</div>
								) : null}
								{state.filter === 'current' ? (
									<div style={btnActions} className='actionBtn'>
										{' '}
										{/* show/hide this div based on tab */}
										<Link
											to={`${EDIT_DISCOUNT_ROUTE}/${item.id}`}
											style={{ textDecoration: 'none' }}>
											<Button
												variant='outlined'
												style={{ margin: '0px 10px' }}>
												Edit
											</Button>
										</Link>
									</div>
								) : null}
							</div>
						))
					) : (
						<Typography className='no_discount_available'>
							{showLoader ? 'Loading...' : 'No Discounts Available'}
						</Typography>
					)}
				</div>
				<DeleteDialog
					id={state.id}
					closeDialog={closeDialog}
					deleteDiscount={onDeleteDiscount(state.id)}
				/>
			</div>
		</Fragment>
    </div>
	);
}

export const DiscountAdd = (props) => {
	let date = new Date();
	date.setDate(date.getDate() + 1);
    
	const initState = {
		discount: { value: '' },
		description: { value: 'Sale' },
		fromDate: { value: date },
		toDate: { value: date },
		logo: { value: '' },
		image_type: { value: 'upload_image' },
		showLoader: false,
	};

	const [state, _setState] = useState(initState);
	function setState(newState) {
		_setState(_state => ({..._state, ...newState}));
	}

	const { id: editId } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		if (editId) {
			dispatch(getDiscountById(editId)).then(
				({ discount, discription, fromDate, toDate, logo }) => {
					setState({
						discount: { value: discount },
						description: { value: discription },
						fromDate: { value: fromDate },
						toDate: { value: toDate },
						logo: { value: logo },
						image_type: { value: logo ? 'upload_image' : 'store_image' },
					});
				}
			);
		}
	}, []);

	const onInputChange = (key) => (e) => {
		setState({
			[key]:
				key === 'discount'
					? Number(e.target.value) <= 99
						? { value: e.target.value }
						: { value: state.discount.value }
					: { value: e.target.value },
		});
	};

	const handleDateChange =
		(key, label = '') =>
		(date) => {
			if (isNaN(Date.parse(date))) {
				setState({
					[key]: {
						value: date,
						isError: true,
						errorMessage: `${label} should be in a valid format`,
					},
				});
				return;
			}
			if (
				new Date(date).getTime() <=
					new Date().getTime()
			) {
				setState({
					[key]: {
						...state[key],
						isError: true,
						errorMessage: `Discounts can not be created for current date and anyday before the current date`,
					},
				});
				return;
			}
			setState({
				[key]: { value: date },
			});
		};

	const onFileUpload = (key) => async (files) => {
		if (files.length) {
			let res = await dispatch(fileUpload(files[0]?.file));
			console.log(res);
			if (!!res.url) {
				setState({ logo: { value: res } });
			}
		}
	};

	const onImageTypeChange = (type) => async (event) => {
		setState({
			image_type: { value: event.target.value },
		});

		if (event.target.value === 'store_image') {
			setState({
				image_type: { value: event.target.value },
			});
		}
	};

	const onSetDiscount = (isEditCall) => async () => {
		let body = {
			discount: Number(state.discount.value),
			fromDate: moment(state.fromDate.value).startOf('day').toISOString(),
			toDate: moment(state.toDate.value).endOf('day').toISOString(),
			discription: state.description.value,
			logo: state.image_type.value === 'store_image' ? null : state.logo.value,
		};

		if (!state.discount.value || state.discount.value < 3) {
			setState({
				discount: {
					...state.discount,
					isError: true,
					errorMessage: !state.discount.value
						? 'Discount value cannot be empty'
						: 'Please enter valid discount amount between 3% to 99%',
				},
			});
			return;
		}

		if (
			new Date(state.toDate.value).getTime() <
				new Date(state.fromDate.value).getTime() ||
			state.fromDate.isError ||
			state.toDate.isError
		) {
			setState({
				toDate: {
					...state.toDate,
					isError: true,
					errorMessage: state.fromDate.isError
						? 'Valid From Date should be in a valid format'
						: state.toDate.isError
						? 'Valid To Date should be in a valid format'
						: ' Valid To Date should be after the  Valid From date ',
				},
			});
			return;
		}

		if (state.image_type.value === 'upload_image' && !state.logo.value) {
			setState({
				logo: {
					...state.logo,
					isError: true,
					errorMessage: 'Image is required',
				},
			});
			return;
		}

		const res = isEditCall
			? dispatch(editDiscount(editId, body))
			: dispatch(createDiscount(body));
		if (res) {
			dispatch(getDiscountList()).then(() => history.goBack());
		}
	};

	const changeImage = () => {
		setState({ logo: { value: '' } });
	};

	const isDisabledField =
		editId && Date.parse(state.fromDate.value) < Date.parse(new Date())
			? true
			: false;
	const history = useHistory();

	return (
		<div style={addDiscount} className='add_discount'>
			<SimpleBackdrop open={state.showLoader} />
			<p className='fontBold' style={{ display: 'flex' }}>
				{' '}
				<img
					onClick={() => history.push(ONE_OFF_DISCOUNT_ROUTE)}
					src='/images/back.svg'
					style={{ marginRight: '15px', marginTop: '2px', cursor: 'pointer' }}
					alt='back icon'
				/>
				{!!editId ? 'Edit Discount' : ' Set New Discount'}
			</p>
			<div style={contentWrapper}>
				<div className='new_discount_main discounts_wrapper'>
					<div style={eachInput} className='new_discount_ip'>
						<TextInput
							style={{ width: '100%' }}
							onChange={onInputChange(`discount`)}
							value={state.discount?.value}
							label={'% Discount'}
							errorMessage={state.discount.errorMessage}
							isError={state.discount.isError}
							inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
							disabled={isDisabledField}></TextInput>
					</div>

					<div className='select_desc'>
						<SelectInput
							style={{ width: '100%' }}
							onChange={onInputChange(`description`)}
							value={state.description.value}
							label={'Select Description'}
							options={[
								{ label: 'Sale', value: 'Sale' },
								{ label: 'Off', value: 'Off' },
								{ label: 'Discount', value: 'Discount' },
							]}
							isError={state.description.isError}
							errorMessage={state.description.errorMessage}
							disabled={isDisabledField}></SelectInput>
					</div>
				</div>

				<div className='new_discount_main discounts_wrapper'>
					<div style={eachInput} className='new_discount_ip'>
						<DatePicker
							handleDateChange={handleDateChange('fromDate', 'Valid From Date')}
							selectedDate={state.fromDate.value}
							minDate={new Date(date)}
							// minDate={new Date("2022-09-10")}
							//minDate={new Date(new Date().getTime() + 1000 * 60 * 60 * 24)}
							label='Valid From Date'
							errorMessage={state.fromDate.errorMessage}
							isError={state.fromDate.isError}
							disabled={isDisabledField}
						/>
					</div>
					<div style={eachInput} className='new_discount_ip'>
						<DatePicker
							handleDateChange={handleDateChange('toDate', 'Valid To Date')}
							selectedDate={state.toDate.value}
							minDate={new Date(date)}
							// minDate={new Date("2022-09-10")}
							//minDate={new Date(fromDate.value)}
							label='Valid To Date'
							errorMessage={state.toDate.errorMessage}
							isError={state.toDate.isError}
						/>
					</div>
				</div>
				{/* <div style={inputWrapper}>
            <TextInput
              style={{ width: "100%" }}
              onChange={onInputChange(`description`)}
              value={description.value}
              label={"Description"}
              fullWidth={true}
            ></TextInput>
          </div> */}
				<RadioGroup
					className='radioWrapper'
					value={state.image_type.value}
					onChange={onImageTypeChange()}>
					<FormControlLabel
						value='upload_image'
						control={<Radio size='small' color='primary' />}
						label='Upload image'
						disabled={isDisabledField}
					/>
					<FormControlLabel
						value='store_image'
						control={<Radio size='small' color='primary' />}
						label='Use store image'
						disabled={isDisabledField}
					/>
				</RadioGroup>
				{state.image_type.value === 'upload_image' && (
					<div style={{ width: '60%', marginTop: 20 }} className='uploadfile'>
						<FileUpload
							onChange={onFileUpload('logo')}
							// label={'Upload Display Logo/Campaign'}
							isError={state.logo.isError}
							errorMessage={state.logo.errorMessage}
							isFileAdded={!!state.logo.value}
							imgSrc={state.logo.value}
							discount={state.discount?.value}
							description={state.description?.value}
							changeImage={changeImage}
							disabled={isDisabledField}
						/>
						<Typography style={{ color: 'red' }} variant='caption'>
							{state.logo.errorMessage}
						</Typography>
					</div>
				)}

				<div style={{ marginTop: 30 }}>
					<Button
						onClick={onSetDiscount(!!editId)}
						color='primary'
						style={saveBtn}>
						{!!editId ? 'Save' : 'Save'}
					</Button>
				</div>
			</div>
		</div>
	);
};
