export const getQueryParams = (search = " ") => {
  return (
    search
      .slice(1)
      .split(["&"])
      .map((item) => {
        let arr = item.split("=");
        return arr[1];
      }) || []
  );
};

export function validateFields(fields = {}, callBack) {
  let error = false;
  for (let key in fields) {
    if (!fields[key]) {
      error = true;
      callBack(key);
    }
  }
  return error;
}


export function formatAmount({
  currency = "USD",
  country = "en-US",
  amount = 0,
}) {
  let formatTypes = {
    style: "currency",
    currency,
  };
  return Number(amount).toLocaleString(country, formatTypes);
}

export function formatDate(date) {
    const d = new Date(date);
    const day = d.getDate();
    return (
        day + " " +
        d.toLocaleString("en-US", {
            month: "short",
            year: "numeric",
        })
    );
}


export function formatTime(date) {
  const d = new Date(date);
  return d.toLocaleTimeString('en-US');
}