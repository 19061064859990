import { CALL_API } from "../api.middleware";
import { GET_PENDING_STATEMENT_SUCCESS } from "../types/statement";
import { GET_PENDING_STATEMENT } from "../types/statement";
import { GET_CONFIRMED_STATEMENT_SUCCESS } from "../types/statement";
import { GET_CONFIRMED_STATEMENT } from "../types/statement";

export const getConfirmedStatement = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/payments/statements/cash/merchant`,
        showLoader: true,
        method: "GET",
        types: [GET_CONFIRMED_STATEMENT, GET_CONFIRMED_STATEMENT_SUCCESS],
        // params: parameters,
        // showToast: true,
      },
    });
  } catch (error) {
    console.error(error)
    throw error
  }
};


export const getPendingStatement = () => async (dispatch) => {
    try {
      return await dispatch({
        [CALL_API]: {
          url: `/payments/statements/cash/merchant/pending`,
          showLoader: true,
          method: "GET",
          types: [GET_PENDING_STATEMENT, GET_PENDING_STATEMENT_SUCCESS],
        //   params: parameters,
          // showToast: true,
        },
      });
    } catch (error) {
      console.error(error)
      throw error
    }
  };
