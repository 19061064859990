import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export function StoreFormHeader({ title = 'ADD STORE' }) {
    const history = useHistory();

    return <Box
      className="forHeaderText"
    >
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: 'pointer',
        marginTop: 20
      }}
      onClick={() => history.push("/portal/stores")}
    >
      <img
        style={{ height: 15, marginRight: 12 }}
        src="/images/back_icon.svg"
        alt="Go Back"
      />
      <Typography>
        {title}
      </Typography>
    </Box>
  </Box>
}