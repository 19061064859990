import { CALL_API } from "../api.middleware";
import { DOCUMENT_UPLOAD, DOCUMENT_UPLOAD_SUCCESS, SET_DEFAULT_BANK, SET_DEFAULT_BANK_SUCCESS } from "../types/bankRegistration";


export const uploadBankDocument =
  ( files ) =>
  async (dispatch) => {
    try {
      return await dispatch({
        [CALL_API]: {
          url: `/businesses/businesses/upload-bank-documents`,
          showLoader: true,
          method: "POST",
          types: [DOCUMENT_UPLOAD, DOCUMENT_UPLOAD_SUCCESS],
          body: { files: files },
          showToast: true,
          toastMessage: 'Document uploaded successfully'
        },
      });
    } catch (error) {
      console.error(error)
      throw error
    }
  };




  export const setDefaultBank =
  ( accountNo ) =>
  async (dispatch) => {
    try {
      return await dispatch({
        [CALL_API]: {
          url: `/businesses/businesses/set-default-account`,
          showLoader: true,
          method: "POST",
          types: [SET_DEFAULT_BANK, SET_DEFAULT_BANK_SUCCESS],
          body: { accountNo },
          // showToast: true,
        },
      });
    } catch (error) {
      console.error(error)
      throw error
    }
  };