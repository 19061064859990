import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  Radio,
  InputAdornment,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import PreviewModal from "./PreviewModal";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from './styles.jsx';
import {
  uploadFile,
} from "../../store/actions/activateMerchantApp";
import MapContainer from "./Map";
import { useFormik } from 'formik';
import * as yup from 'yup';
import {Close} from "@material-ui/icons";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { IMAGE_LOGO_TYPES } from "../../constants";


registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


const validationSchema = yup.object({
  storeType: yup.string().required('Store type is required'),
  name: yup.string().trim().required('Store name is required'),
  storeNo: yup.string().trim().typeError('Store number must be a string'),
  location: yup.object({
  }).nullable(),
  logo: yup.object({
    id: yup.string().required("Store image is required")
  }).noUnknown(false).nullable().required("Store image is required")
});

export default function StoreForm({ initialValues, type, onSubmit }) {
  const [mToS, setMToS] = useState(false)
  const [sToM, setSToM] = useState(false)

  const [address, setAddress] = useState(null);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { businessData } = useSelector((state) => state.auth);

  const classes = useStyles();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      onSubmit(values, address);
    },
  });



  const fetch = async () => {
    const {address, business} = businessData
    const {address1, address2, city, state, country, zipCode} = address;
    setAddress({address1, address2, city, state, country, zipCode});

    const { name = '' } = business;
    formik.setFieldValue('name', name);
  };


  useEffect(() => {
     if(formik.values.storeType === 'single-device'){
      if(type === 'CREATE_STORE'){
        fetch();
      }
      if(type === 'UPDATE_STORE'){
        if(mToS){
          fetch();
        }
        else{
          setAddress(initialValues?.address);
        }
      }
      setDisabled(true);
     } 
     else if(formik.values.storeType === 'multi-device' ){
      if(type === 'UPDATE_STORE'){
        setAddress(initialValues?.address);
        if(sToM){
          setAddress(null)
          formik.setFieldValue('name', '')
          formik.setFieldValue('storeNo', '')
        }
      }
      if(type === 'CREATE_STORE'){
        setAddress(null)
        formik.setFieldValue('name', '')
        formik.setFieldValue('storeNo', '')
      }
      setDisabled(false);
     }
     else{
      setAddress(null);
      setDisabled(false);
     }
  }, [formik.values.storeType, initialValues.address]);

  // console.log("values from the parent",initialValues)
  // console.log("formik values: ", formik.values)
  // console.log("address", address)

  const dispatch = useDispatch();

  const isIphoneSE = useMediaQuery("(max-width: 320px)");
  const isXsMobile = useMediaQuery("(max-width : 360px)");
  const isMobile = useMediaQuery("(max-width : 480px)");
  const isTablet = useMediaQuery("(max-width : 768px)");
  const isBigTablet = useMediaQuery("(max-width : 1180px)");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const textFieldStyle = {
    fontFamily: "PlusJakartaSans-Regular",
    fontSize: 14,
    color: '#282828',
    letterSpacing: 0.7,
  };
  const textFieldInput = {
    fontFamily: "Notosans",
     fontSize: 14,
     color: '#282828',
     opacity: 0.5,
  }

  const mapStyle = {
    height: 400,
    width: isMobile || isXsMobile || isTablet || isBigTablet ? "auto" : 400,
  };
  const imgStyle = {
    width: isMobile || isXsMobile || isTablet || isBigTablet ? 25 : 32,
    height: isMobile || isXsMobile || isTablet || isBigTablet ? 25 : 32,
    objectFit: "contain",
  };
  const imagePreviewParentStyle = {
    display: "flex",
    flexDirection:
      isMobile || isXsMobile || isTablet || isBigTablet ? "column" : "row",
    // alignItems: "center",
  };

  const previewParentStyle = {
    display: "flex",
    justifyContent: isMobile || isXsMobile || isTablet || isBigTablet ? "flex-start" : "center",
    marginTop: isMobile || isXsMobile || isTablet || isBigTablet ? 5 : 0,
  };
  const previewStyle = {
    display: "flex",
    marginLeft: isMobile || isXsMobile || isTablet || isBigTablet ? 0 : 30,
    backgroundColor: isMobile || isXsMobile || isTablet || isBigTablet ? "white" : "#F8F8F8",
    padding: isMobile || isXsMobile || isTablet || isBigTablet ? 0 : 20,
    width: isMobile || isXsMobile || isTablet || isBigTablet ? "auto" : 130,
    height: 80,
    boxSizing: 'border-box',
    alignItems: "center",
    justifyContent: isMobile || isXsMobile ? "flex-start" : "center",
    cursor: "pointer",
  };

  const uploadPic = async (file) => {
    return dispatch(uploadFile({ file: file[0]?.file }));
  };

  const previewImg = formik.values.logo?.thumbnailUrl;

  const {address1 = '', address2 = '', city = '', state = '', country = '', zipCode = ''} = address || {};

  const logoErrorExists = formik.touched.logo && Boolean(formik.errors.logo);

  const logoErrorMessage = formik.touched.logo ? (formik.errors.logo?.id || formik.errors.logo) : '';
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className="rewards-wrapper reward_wid_sec">
        {open && (
          <PreviewModal
            handleOpen={handleOpen}
            handleClose={handleClose}
            open={open}
            previewImg={previewImg}
            storeName={formik.values.name}
          />
        )}
        <Paper 
            style={{ padding: isMobile || isXsMobile || isTablet || isBigTablet ? "10px"  : "20px 40px" }} 
            square elevation={0}
        >
          <FormControl
            error={formik.touched.storeType && Boolean(formik.errors.storeType)}
            style={{ 
              width: "100%", 
              padding: isMobile || isXsMobile || isTablet || isBigTablet ? "10px"  : "10px 20px" 
            }}
            className= "storeRadioGroup"
          >
            <RadioGroup
              aria-labelledby="type of the store"
              name="storeType"
              id="storeType"
              value={formik.values.storeType}
              // onChange={formik.handleChange}
              onChange={({target: {value}}) => {
                formik.setFieldValue("storeType", value)
                if(value === 'single-device'){
                  setMToS(true)
                }
                if(value === 'multi-device'){
                  setSToM(true)
                }
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-start",
                gap: isMobile || isXsMobile || isTablet || isBigTablet ? "0rem"  : "3rem",
              }}
            >
              <FormControlLabel
                value="single-device"
                control={<Radio style={{ color: "#0180F7" }} />}
                label="Set Location In-App (Single Device Only)"
              />
              <FormControlLabel
                value="multi-device"
                control={<Radio style={{ color: "#0180F7" }} />}
                label="Set Location Using Google Maps"
              />
            </RadioGroup>
            <FormHelperText>
              {formik.touched.storeType && formik.errors.storeType}
            </FormHelperText>
          </FormControl>

          <Box
            style={{
              display: "flex",
              flexDirection:
                isMobile || isXsMobile || isTablet || isBigTablet
                  ? "column"
                  : "row",
              justifyContent: "space-between",
              alignItems:
                isMobile || isXsMobile || isTablet || isBigTablet
                  ? "center"
                  : "flex-start",
              padding: "20px",
            }}
          >
            <Box
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControl
                error={Boolean(formik.errors.location)}
                style={{
                  height: isMobile || isXsMobile || isTablet || isBigTablet ? 300 : 398,
                  width: isIphoneSE ? 260 : isMobile || isXsMobile || isTablet || isBigTablet ? 300 : 432,
                  padding: isIphoneSE ? 10 : 20,
                  border: "0.1rem #ddd solid",
                }}
              >
                <MapContainer
                  location={formik?.values?.storeType === 'multi-device' ? formik.values.location : null}
                  onChange={function ({ location, address, storeName }) {
                    formik.setFieldValue("location", {
                      lng: location.lng,
                      lat: location.lat,
                    });
                    setAddress(address);
                    formik.setFieldValue("name", storeName);
                  }}
                  style={mapStyle}
                  disabled={disabled}
                  address={formik?.values?.storeType === 'multi-device' ? formik?.values?.address?.address1 : ''}
                />
                <FormHelperText>{formik.errors.location}</FormHelperText>
              </FormControl>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                minWidth: isIphoneSE ? 280 : 300,
                maxWidth: 400,
                padding:
                  isMobile || isXsMobile || isTablet || isBigTablet
                    ? "2rem 1rem"
                    : "0 3rem",
                gap: isIphoneSE? "1rem" : "3rem",
              }}
              className="storeAddress"
            >
              <Box>
                <TextField
                  style={textFieldStyle}
                  id="name"
                  name="name"
                  label="Store Name"
                  fullWidth={true}
                  value={formik.values.name}
                  autoComplete='off'
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="/images/edit_icon.svg" alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <TextField
                  style={textFieldStyle}
                  id="storeNo"
                  name="storeNo"
                  label="Add Store Number (optional)"
                  // placeholder="Add Store Number (optional)"
                  fullWidth={true}
                  value={formik.values.storeNo}
                  autoComplete='off'
                  // onChange={formik.handleChange}
                  onChange={({target: {value}}) => {
                     formik.setFieldValue('storeNo', value.trim() ? value : '')
                  }}
                  error={
                    formik.touched.storeNo && Boolean(formik.errors.storeNo)
                  }
                  helperText={formik.touched.storeNo && formik.errors.storeNo}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="/images/edit_icon.svg" alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <Typography
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 600,
                    fontFamily: "PlusJakartaSans-Regular",
                    margin: 0,
                  }}
                >
                  Address
                </Typography>
                <Typography
                  style={{
                    padding: "0.5rem 0rem",
                    fontSize: "15px",
                    color: "#000",
                    opacity: 0.8,
                    fontWeight: 600,
                    fontFamily: "PlusJakartaSans-Regular",
                    margin: 0,
                    textTransform: 'capitalize'
                  }}
                >
                  {(formik?.values?.storeType === 'single-device') ? 
                  `${address1}, ${address2 ? `${address2},` : ``} ${city}, ${state} ${zipCode}, ${country}` 
                  : 
                  `${address1}`}
                </Typography>
              </Box>

            </Box>
          </Box>
          <Box className="horizontalLine"></Box>
          <Box>
            <FormControl
              // error={Boolean(formik.errors.logo)}
              error={logoErrorExists}
              style={{ 
                width: "90%", 
                padding: isMobile || isXsMobile || isTablet || isBigTablet ? 10 : 30, 
              }}
              className="storeUploadSec"
            >
              <Typography
                style={{
                  fontFamily: "PlusJakartaSans-Bold",
                  fontSize: 12,
                  color: "#0E0E0E",
                  fontWeight: 400,
                  letterSpacing: 0.5,
                  opacity: 0.8,
                  marginBottom: 10,
                }}
              >
                Upload Store Image
              </Typography>
              <Box style={imagePreviewParentStyle}>
                {!formik.values.logo ? (
                  <Box className= "fileUploadWrapper heightUpload">
                    <Box className="fileUploadImgStore">
                      <img
                        src="/images/cloud-computing.svg"
                        alt="cloud-computing"
                        className="cloudImg"
                      />
                    </Box>
                    <Divider style={{opacity:0.5}} orientation="vertical" />
                    <Box className="dragAndDropSec">
                      <FilePond
                        files={formik.values.logo ? [formik.values.logo] : []}
                        allowMultiple={false}
                        onupdatefiles={async (val) => {
                          if (!val || !val.length) {
                            formik.setFieldValue("logo", undefined);
                            return;
                          }
                          const [file] = val;
                          const { fileType, fileSize } = file;
                          if (IMAGE_LOGO_TYPES.includes(fileType) && fileSize <= 5.1 * 1024 * 1024) {
                              const f = await uploadPic(val);
                              formik.setFieldValue("logo", f);
                          }
                        }}
                        onerror={(err) => {
                          if (err.main) {
                            formik.setFieldError("logo", `${err.main}. ${err.sub}`);
                          } else {
                            formik.setFieldError("logo", 'Error while uploading the file. Please try again.');
                          }
                        }}
                        allowFileTypeValidation={true}
                        acceptedFileTypes={IMAGE_LOGO_TYPES}
                        labelFileTypeNotAllowed={'File of invalid type'}
                        allowFileSizeValidation={true}
                        maxFileSize={'5.1MB'}
                        name="files"
                        credits={false}
                        labelIdle='<div class="dragWrapper">
                                        <p class="dragTxt">
                                          Drag and Drop .jpg, .png here or
                                        </p>
                                        <span 
                                          class="filepond--label-action dragButton"
                                          style="
                                          text-decoration:none;
                                        ">
                                          Browse Files
                                        </span>
                                    </div>'
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box className="fileUploadWrapper heightUpload">
                    <Box className='fileUploadImgStore'>
                      <img
                        src="/images/cloud-computing.svg"
                        alt="cloud-computing"
                        className="cloudImg"
                      />
                    </Box>
                    <Divider style={{ opacity: 0.5 }} orientation="vertical" />
                    <Box className='uploadSec'>
                      <Typography className={classes.uploadText}>
                        {formik.values.logo.name}
                      </Typography>
                      {isMobile || isXsMobile || isTablet || isBigTablet ? (
                        <Close
                          onClick={() => formik.setFieldValue("logo", null)}
                        />
                      ) : (
                        <Button
                          size="medium"
                          variant="contained"
                          onClick={() => formik.setFieldValue("logo", null)}
                          color="primary"
                          className={classes.changeButton}
                        >
                          Change
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
                <Box style={previewParentStyle}>
                  <Box
                    style={previewStyle}
                    onClick={() => {
                      if (!formik.values.logo) {
                        return;
                      }
                      handleOpen();
                    }}
                  >
                    <img
                      style={imgStyle}
                      src="/images/preview_img.svg"
                      alt="Show Preview"
                    />
                    <Typography className={classes.imgPreviewText}>
                      Image Preview
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography className={classes.uploadFileTxt}>
                * Attach jpg or png file
              </Typography>
              <FormHelperText>{logoErrorMessage}</FormHelperText>
            </FormControl>
            <Box className="horizontalLine"></Box>
            <Box className={classes.saveButton}>
              <Button
                size="small"
                color="primary"
                variant={"contained"}
                type="submit"
                className={classes.saveButtonTxt}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </form>
  );
}
