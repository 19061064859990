import {
  CLEAR_TOKEN,
  FORGOT_PASSWORD_LOGIN_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  OTP_LOGIN_SUCCESS,
  REMOVE_SCROLL_INTO_VIEW,
  SCROLL_INTO_VIEW,
  SET_PASSWORD_SUCCESS,
  SIGN_UP_SUCCESS,
  VALIDATE_CODE_SUCCESS,
} from "../types/auth";
import { BUSINESS_ME_SUCCESS, BUSINESS_PREMIUM_SUCCESS } from "../types/rewards";

const initState = {
  userDetails: {},
  regToken: null,
  businessData: {},
  intialToken: null
};

export default function auth(state = initState, action) {
  switch (action.type) {
    case VALIDATE_CODE_SUCCESS: {
      const {body = {}} = action
      const { business = null } = body;
      const payload = business === null ? { regToken: body?.token } : { userDetails: body }
      return {
        ...state, ...payload
      }
    }
    case LOGIN_SUCCESS: {
      const { body = {} } = action;
      return {
        ...state,
        userDetails: body,
      };
    }

    case SIGN_UP_SUCCESS:{
      const { body = {} } = action;
      return {
        ...state,
        intialToken: body.otpToken,
        regToken: null
      };
    }

    case OTP_LOGIN_SUCCESS:{
      const { body = {} } = action;
      return {
        ...state,
        intialToken: body.otpToken,
        regToken: null
      };

    }

    case CLEAR_TOKEN: {
      return {
        ...state,
        intialToken: null,
        // regToken: null
      }; 
    }

    case FORGOT_PASSWORD_LOGIN_SUCCESS:{
      const { body = {} } = action;
      return {
        ...state,
        intialToken: body.otpToken,
        regToken: null
      };

    }

    case SET_PASSWORD_SUCCESS: {
      const { body = {} } = action;
       return {...state, regToken: null, userDetails: body}
    }

    case BUSINESS_ME_SUCCESS: {
      const {body = {}} = action
      return {...state, businessData: body}
    }

    case BUSINESS_PREMIUM_SUCCESS: {
      const premium = action.body?.premium;
      let businessData = state.businessData;
      businessData.business.premium = premium;
      return { ...state, businessData: businessData };
    }

    case LOGOUT_SUCCESS: {
      return initState;
    }
    default:
      return state;
  }
}

const scrollState = {
  scrollInto: false,
};
export function scrollIntoViewReducer(state = scrollState, action) {
  switch (action.type) {
    case SCROLL_INTO_VIEW:
      return {
        ...state,
        scrollInto: true,
      };
    case REMOVE_SCROLL_INTO_VIEW:
      return {
        ...state,
        scrollInto: false,
      };
    case LOGOUT_SUCCESS:
      return scrollState;
    default:
      return state;
  }
}
