import { 
  Box, 
  Button, 
  Divider, 
  Typography } from "@material-ui/core";
import { useState } from "react";
import { IMAGE_LOGO_TYPES } from "../constants";
import DiscountImagePreview from "../views/rewards/DiscountImagePreview";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { useStyles } from '../merchant-stores/ActivateMerchantApp/styles.jsx';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

export default function FileUpload({
  label = "",
  isError = false,
  isFileAdded = false,
  imgSrc = "",
  discount,
  onChange,
  changeImage,
  description,
  disabled,
}) {

  registerPlugin(FilePondPluginFileValidateType);

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      {open && (
        <DiscountImagePreview
          handleOpen={handleOpen}
          handleClose={handleClose}
          open={open}
          previewImg={imgSrc?.thumbnailUrl}
          discount={discount}
          description={description}
        />
      )}
      <label>{label}</label>
      <Box
        className= "fileUploadSec"
      >
        <Box style={{
            flexGrow: 1,
        }}>
        {!imgSrc ? (
          <Box className= "fileUploadWrapper heightUpload">
            <Box className="fileUploadImgStore">
              <img
                src="/images/jpg_icon.svg"
                alt="jpg icon"
                style={{ height: "30px" }}
              />
            </Box>
            <Divider orientation="vertical" />
            <Box className="dragAndDropSec">
              <FilePond
                allowMultiple={false}
                onupdatefiles={async (val) => {
                  if (!val || !val.length) {
                    // formik.setFieldValue("logo", undefined);
                    return;
                  }

                  const [file] = val;
                  const { fileType, fileSize } = file;
                  if (IMAGE_LOGO_TYPES.includes(fileType) && fileSize <= 5e+6) {
                    await onChange(val);
                      // formik.setFieldValue("logo", f);
                  }
                  
                }}
                allowFileTypeValidation={true}
                acceptedFileTypes={IMAGE_LOGO_TYPES}
                labelFileTypeNotAllowed={'File of invalid type'}
                allowFileSizeValidation={true}
                maxFileSize={'5MB'}
                name="files"
                credits={false}
                labelIdle='<div class="dragWrapperDiscount">
                                        <p class="dragTxt">
                                          Drag and Drop .jpg, .png here or
                                        </p>
                                        <span 
                                          class="filepond--label-action dragButtonDiscount"
                                          style="
                                          text-decoration:none;
                                        ">
                                          Browse Files
                                        </span>
                                    </div>'
                />
            </Box>
          </Box>
        ) : (
          <Box className="fileUploadWrapper heightUpload">
            <Box className="fileUploadImgStore">
              <img
                src="/images/jpg_icon.svg"
                alt="jpg icon"
                style={{ height: "30px" }}
              />
            </Box>
            <Divider orientation="vertical" />
            <Box className="changeSec">
              <Typography className={classes.uploadText} >{imgSrc.name}</Typography>
              <Button
                size="medium"
                variant="outlined"
                onClick={() => changeImage()}
                className={`${classes.changeButton} ${classes.jumnaBlue}`}
                disabled={disabled}
              >
                Change
              </Button>
            </Box>
          </Box>
        )}
        </Box>
        {/* <Box
          style={{
            flexGrow: 1,
          }}
        >
          <FilePond
            allowMultiple={false}
            onupdatefiles={async (val) => {
              if (!val || !val.length) {
                // formik.setFieldValue("logo", undefined);
                return;
              }
              console.log("origin =", val[0]);
              // const f = await uploadPic(val);
              await onChange(val);
              // console.log(f)
              // formik.setFieldValue("logo", f);
            }}
            name="files"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          />
        </Box> */}

        <Box
          className="image_preview_wrapper"
          onClick={() => {
            if (!imgSrc) {
              return;
            }
            handleOpen();
          }}
        >
          <img src="/images/preview_img.svg" alt="Show Preview" />
          <Typography
            style={{
              fontFamily: "PlusJakartaSans-Regular",
              fontSize: 16,
              color: "#2F80ED",
              //width: '5rem'
            }}
          >
            Image Preview
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
