import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function RegProtectedRoute({ token = null, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !!token ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default connect(({ auth: { regToken = '' } }) => {
  return { token : regToken };
})(RegProtectedRoute);
