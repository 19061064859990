import { 
    Typography, 
    Button,
    AppBar,
    Toolbar,
    TextField,
    Checkbox,
    Switch,
    FormGroup,
    FormControlLabel,
    styled,
    Box,
    useMediaQuery
 } from "@material-ui/core";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_KEY, COLORS } from "../../constants";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import {  forgotPasswordSignIn, merchantSignIn, onLogin } from "../../store/actions/auth";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import OnBoardingHeader from "../../views/landing/onBoardingHeader";

const signUpContainer = {
    backgroundColor : '#F4F4F4',
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: '100vh',
    overflow: 'auto'
}; 
const textFieldStyle = {
    fontFamily: "PlusJakartaSans-Regular",
    fontSize: 14,
    color: '#282828',
    //opacity: 0.5,
};
const checkBoxwrapper = {
    display: 'flex',
    alignItems: 'center'
};
const btnContinue ={
    width: '100%',
    background: '#1A7CFA',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0605059)',
    borderRadius: 2,
    color: COLORS.default_white,
    marginTop: 30
};
const accountTxt = {
    fontSize: 13,
    color: '#323232',
    textAlign: 'center',
    marginTop: 30,
    fontFamily: "PlusJakartaSans-Regular"
};
const spanTxt = {
    color: COLORS.juma_theme_clr,
    fontSize: 14,
    fontFamily: "PlusJakartaSans-Bold",
    cursor: 'pointer'
};
const robotTxt = {
    color: '#4f4f4f',
    fontSize: 14
};
const passwordSec ={
    display: 'flex',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 10
};




  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
  });

export default function ForgotPassword() {

  const isXsMobile = useMediaQuery("(max-width : 360px)");
  const isMobile = useMediaQuery("(max-width : 480px)");
  const isTablet = useMediaQuery("(max-width : 768px)");
  const isBigTablet = useMediaQuery("(max-width : 1180px)");

  const signUpContainer = {
    backgroundColor : isMobile || isXsMobile || isTablet ? "#FFFFFF" : "#F4F4F4",
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: '100vh',
    overflow: 'auto'
  }; 
  const formContainer = {
      backgroundColor: COLORS.default_white,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center',
      boxSizing: "border-box",
      width: isMobile || isXsMobile ? "94%" : isTablet || isBigTablet ? "50%" : "50%",
      maxWidth: 520,
      margin: " 30px auto",
      padding: isMobile || isXsMobile ? "20px 12px" : isTablet || isBigTablet ? 30 : 40,
  };
  const signUpMerchant={
      marginTop: isMobile || isXsMobile || isTablet || isBigTablet ? 80 : 120,
      fontSize: isMobile || isXsMobile || isTablet ? 28 : 32,
      fontWeight: 600,
      fontStyle: "PlusJakartaSans-Bold",
  };
  const inputWrapper = {
    margin: isMobile || isXsMobile || isTablet || isBigTablet ? 10 : "10px 30px",
    width: isMobile || isXsMobile || isTablet || isBigTablet ? "" :'85%'
  };
    const [isVerified, setIsVerified] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    
    
    const onChange = (value) => {
      setIsVerified(true)  
    }
  
    const resetCaptcha = () => {
      setIsVerified(false)
    }

    const formik = useFormik({
        initialValues: {
          email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log(values)
            if (values?.email && isVerified) {
              const token = await dispatch(forgotPasswordSignIn({...values, role: 'owner'}));
              console.log(token);
              if (token) history.push("/validate-otp", {token, action: 'RESET_PASSWORD', email: values});
            }
        },
    });




    return (
      <div>
        <OnBoardingHeader />
        <div style={signUpContainer} className="createMerchant">
          <Typography style={signUpMerchant}>Merchant Sign-in</Typography>
          <form onSubmit={formik.handleSubmit} style={formContainer}>
            <div style={inputWrapper} className="forgotPswdSec">
              <Typography>
                Forgot Password
              </Typography>
              <TextField
                style={textFieldStyle}
                label="Email Id"
                id="email"
                name="email"
                autoComplete="off"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />


              <Box className="reCapchaSec">
              <ReCAPTCHA
                    sitekey={CAPTCHA_KEY}
                    onChange={onChange}
                    type='image'
                    onExpired={resetCaptcha}
              />

              </Box>
              <Button
                size="large"
                variant="contained"
                style={btnContinue}
                disabled={!isVerified}
                type="submit"
              >
                {`Continue`}
              </Button>
              <Typography style={accountTxt}>
                Return to <Link to={{pathname: '/merchant-signin'}} className="underlineNone"><span style={spanTxt}>Sign-in</span></Link>
              </Typography>
            </div>
          </form>
        </div>
      </div>
    );
  
}
