import { COLORS } from "../../constants";
import { FONTS } from "../../constants";

export const addDiscount = {
  padding: "20px",
  boxSizing: "border-box",
};
export const inputWrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "60%",
  marginBottom: "20px",
};
export const contentWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
export const eachInput = {
  width: "45%",
};
export const setBtn = {
  backgroundColor: COLORS.juma_theme_clr,
  color: COLORS.default_white,
  width: "120px",
};
export const saveBtn = {
  backgroundColor: COLORS.juma_theme_clr,
  color: COLORS.default_white,
  width: "250px",
  height: 48
};

export const addBtn = {
  backgroundColor: COLORS.juma_theme_clr,
  color: COLORS.default_white,
  width: "100px",
  fontFamily: "PlusJakartaSans-Regular",
  textTransform: 'none',
  fontSize: 12,
  letterSpacing: 0.7,
  fontWeight: 700
};
export const rewardTabOptions = {
  width: "90%",
  margin: "auto",
  paddingTop: "20px",
};
export const listwrapper = {
  display: "flex",
  // justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "40px",
  marginLeft: "10px",
};
export const eachlistItem = {
  // backgroundColor : "red",
  // boxShadow: "0 0 4px #ccc",
  // padding: "10px",
  // boxSizing: "border-box",
  // margin: "12px 12px",
  // minWidth : "220px",
  // minHeight : "310px",
};
export const discountText = {
  textAlign: "center",
  position: 'absolute',
  bottom: 0,
  top: 134,
  color: "white",
  padding: '5px 10px',
};
export const btnActions = {
  display: "flex",
  //justifyContent: "space-around",
  marginTop: 5,
  marginBottom: 10
};
export const actionBtn = {
  backgroundColor: "transparent",
  color: COLORS.juma_theme_clr,
  textTransform: "none",
  cursor: "pointer",
  fontSize: FONTS.size_minimum,
};
export const discount_image = {
  maxHeight: "145px",
  width: "100%",
};

export const loyaltyAdd = {
  backgroungColor: COLORS.default_white,
  color: COLORS.juma_theme_clr,
  float: "right",
};
export const loyaltyWrapper = {
  padding: "30px",
  boxSizing: "border-box",
};
export const loyaltyBtn = {
  color: COLORS.default_white,
  backgroundColor: COLORS.juma_theme_clr,
  minWidth: "150px",
  padding: "8px 20px",
  marginTop: "50px",
  cursor: "pointer",
};
