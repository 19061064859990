import { Box, Button, Grid, Link, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import Footer from "./footer";
import HeaderLanding from "./headerLanding";
import BookADemoDialog from "./BookADemoDialog";
import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

const KEEP_ME_INFORMED = "Keep me informed";
const REQUEST_DEMO = "Request Demo";
const KNOW_MORE = "Talk to our team";

const SUPPORT_BASE_URL = process.env.REACT_APP_SUPPORT_BASE_URL || "https://support-dev.jumna.com"

export default function HomeScreen() {
  const { scrollInto } = useSelector((store) => store.scroll);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [modalName, setModalName] = useState("");
  const signUpRef = useRef();
  const homeRef = useRef();
  const footerRef = useRef();

  const keepMeInformed = (name) => {
    setOpen(true);
    setModalName(name);
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleOnLoad = () => {
    // if (scrollInto) {
    //   signUpRef.current?.scrollIntoView({ behavior: "smooth" });
    //   return;
    // }
    homeRef.current?.scrollIntoView();
  };

  return (
    <>
      <BookADemoDialog
        open={open}
        closeVideoPopup={() => setOpen(false)}
        header={modalName}
        subject={
          modalName === KEEP_ME_INFORMED
            ? "keep-me-informed"
            : modalName === REQUEST_DEMO
            ? "book-demo"
            : modalName === KNOW_MORE
            ? "know-more"
            : null
        }
      />
      <HeaderLanding
        handleAboutUs={() =>
          footerRef.current?.scrollIntoView({
            behavior: "smooth",
          })
        }
      />
      <div style={{ paddingTop: "56px" }} className="homeMain" ref={homeRef}>
        <div className="banner_image">
          <div className="home_banner">
            <img src="/images/home_banner_image.png" alt="home banner" />
            <div className="banner_signup">
              <h2>Reach your <br className="desktop_none"/> customer <br  className="desktop_none"/>direct</h2>
              <h4>Attract. Interact. Transact.</h4>
              <p className="bannerSubText">
                0% service fee
                <br />
                0% payment processing fee
              </p>
              <Button
                variant="contained"
                className="joinPilotBtn"
                onClick={() => {
                  history.push("/merchant-signup");
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <h3>Create free account</h3>
                  {/* <Typography>with 500 other NYC businesses</Typography> */}
                </Box>
              </Button>
              <Link className="faqLink" href={SUPPORT_BASE_URL} target="_blank">Learn more about using Unplank</Link>
              {/* <Button
                variant="outlined"
                className="btnText"
                style={{ marginBottom: 9 }}
                onClick={() => keepMeInformed(`${KEEP_ME_INFORMED}`)}
              >
                {KEEP_ME_INFORMED}
              </Button> */}
            </div>
          </div>
        </div>
        <div className="app_to_app_sec" ref={signUpRef}>
          <div className="max-width">
            <Grid container>
              <Grid xs={12} sm={12} md={6} lg={6} className="grid_top">
                <div className="app_to_app_content">
                  <h2>
                    Engage your customer
                    <br />
                    and transact via our app
                  </h2>
                  <h4>In-store or at a distance</h4>
                  <p>
                    No QR code or change to your point-of-sale solution
                    required.
                    <br className="mobile_none" />
                    Simply, select cash at checkout when accepting Unplank.
                  </p>
                  <Button
                    variant="contained"
                    onClick={() => keepMeInformed(`${REQUEST_DEMO}`)}
                  >
                    {REQUEST_DEMO}
                  </Button>
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6}>
                <img src="/images/paystore.gif" alt="app to app" />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="payment_sec">
          <div className="max-width">
            <div className="payment_method_sec">
              <img
                src="/images/payment_method.svg"
                alt="payment"
                className="mob_image"
              />
              <div className="payment_method_content">
                <h2>
                  Don’t pay others for <br />
                  your hard work
                </h2>
                <h4>Unplank to connect direct </h4>
                <p>
                  We provide customers access to their own funds or{" "}
                  <br className="mobile_none" />
                  credit at great rates so you can transact for free. Just{" "}
                  <br className="mobile_none" /> let your customers know that
                  you <span>also accept</span> Unplank.
                </p>
                {/* <p>
                  Just let your customers know that you <span>also accept</span>{" "}
                  Unplank.
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="gradientMain">
          <div className="max-width">
            <div className="gradient_sec">
              <h2>
                Create promo to
                <br />
                become hyper visible
              </h2>
              <Grid
                container
                style={{
                  paddingTop: 50,
                  paddingBottom: 50,
                  alignItems: "center",
                }}
              >
                <Grid xs={12} sm={12} md={6} lg={6}>
                  <img src="/images/coffee_cups.svg" alt="Buy 9 get 1 free" />
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6}>
                  <div className="gradient_content">
                    <p className="beatText">
                      Switch your loyalties
                      <br /> to conditional cashbacks
                    </p>
                    <p>
                      Offer volume dependent cashbacks instead of heavy
                      <br className="mobile_none" /> seasonal discounting to
                      increase sales year-round
                      <br className="mobile_none" /> while keeping your margin
                      intact.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 50, paddingBottom: 24 }}>
          <div className="max-width">
            <div className="chat_with_cust_sec">
              <div className="chat_text">
                <h2>
                  Spoil your customer.
                  <br />
                  Don’t keep them standing <br />
                  in queue.
                </h2>
                <h4>Accept orders via chat. Push payment. </h4>
                <p>
                  0% service fee
                  <br />
                  0% payment processing fee
                </p>
              </div>
              <img
                src="/images/chat_apps.gif"
                alt="payment"
                className="mobile_none attract_img"
              />
              <img
                src="/images/chat_mobile.gif"
                alt="payment"
                className="mob_image desktop_none"
              />
            </div>
          </div>
        </div>
        <div className="nochangeSec">
          <div className="max-width">
            <Grid container>
              <Grid xs={12} sm={12} md={6} lg={6} className="noChangeImg">
                <img
                  src="/images/benefits_image.png"
                  alt="business"
                  className="mob_image"
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6} className="business_content">
                <div className="tick_sec">
                  <div className="tick_spacing">
                    <div className="flex_row alignItem_center tick_content">
                      <img
                        src="/images/blue_tick.png"
                        alt="icon"
                        className="blue_tick_img"
                      />
                      <span>No change to your order management system</span>
                    </div>
                    <p>
                      Unplank is your alternative to cash with an in-built
                      register to record all payments received. Co-exists with
                      other payment solutions.
                    </p>
                  </div>
                  <div className="tick_spacing">
                    <div className="flex_row alignItem_center tick_content">
                      <img
                        src="/images/blue_tick.png"
                        alt="icon"
                        className="blue_tick_img"
                      />
                      <span>Receive your money within 2 business days</span>
                    </div>
                    <p>Verify your bank via our open–banking secure link. </p>
                  </div>

                  <div className="flex_row alignItem_center tick_content tick_spacing">
                    <img
                      src="/images/blue_tick.png"
                      alt="icon"
                      className="blue_tick_img"
                    />
                    <span>Anti-fraud protocols ensure no chargebacks</span>
                  </div>
                  <div className="flex_row alignItem_center tick_content tick_spacing">
                    <img
                      src="/images/blue_tick.png"
                      alt="icon"
                      className="blue_tick_img"
                    />
                    <span>Process refunds</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="five_easy_steps_sec">
          <div className="max-width">
            <div className="steps_sec">
              <img
                src="/images/five_steps_image.svg"
                alt="icon"
                className="mob_image"
              />
              <div className="five_easy_steps">
                <h2 className="mobile_none">
                  Set-up in
                  <br /> 5 easy steps!
                </h2>
                <h2 className="desktop_none">Set-up in 5 easy steps!</h2>
                <div className="flex_row setUpText">
                  <p>01</p>
                  <span>Create account</span>
                </div>
                <div className="flex_row setUpText">
                  <p>02</p>
                  <span>Add store to be visible to customers</span>
                </div>
                <div className="flex_row setUpText">
                  <p>03</p>
                  <span>Link bank account</span>
                </div>
                <div className="flex_row setUpText">
                  <p>04</p>
                  <span>Download app</span>
                </div>
                <div className="flex_row setUpText">
                  <p>05</p>
                  <span>Link app to device with token</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="key_sec">
          <div className="max-width">
            <div className="data_privacy_sec">
              <div className="data_privacy_text">
                <Typography variant="h1">Secure and encrypted</Typography>
                <h2>Built with data privacy at its core</h2>
                <p>
                  Transact with peace of mind in the knowledge that we{" "}
                  <br className="mobile_none" />
                  don’t sell your data or market to customers on the basis
                  <br className="mobile_none" /> of their shopping footprint.
                </p>
              </div>
              <img src="/images/privacy_lock.png" alt="icon" />
            </div>
          </div>
        </div>
        <div className="attract_sec">
          <div className="max-width">
            <Grid container>
              <Grid xs={12} sm={12} md={6} lg={6}>
                <img
                  src="/images/attract.png"
                  alt="business"
                  className="mob_image attract_last_img"
                />
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={6}>
                <div className="know_more_sec">
                  <h2>Increase sales. Cut costs.</h2>
                  <p className="attract_sub_text">
                    Works for every type of business
                  </p>
                  <p>
                    Small. Medium. Large.
                    <br />
                    Sole proprietor to stores with multiple checkouts.
                  </p>
                  <Button
                    variant="contained"
                    onClick={() => keepMeInformed(`${KNOW_MORE}`)}
                  >
                    {KNOW_MORE}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Footer footerRef={footerRef} />
    </>
  );
}
