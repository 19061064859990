import {
  Button,
  Checkbox,
  Paper,
  Tab,
  Tabs,
  Typography,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import {TabContext, TabList, TabPanel} from '@material-ui/lab'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BusinessDetails from './businessDetails';
import MyDetails from './myDetails';
//import LinkedBankAccount from './linkedBankAccount';
import PasswordReset from './passwordReset';
import StatusBar from '../../controllers/statusBar';
import LinkedBankAccounts from './LinkedBankAccounts';

const tabAdmin = [
  {key: 1, label: 'Business Details'},
  {key: 2, label: 'My Details'},
  {key: 3, label: 'Linked Bank Account'},
  {key: 4, label: 'Password Reset'},
]
export default function Admin() {

  const [activeTab, setActiveTab] = React.useState(1);

  const renderTab = () => {
      switch(activeTab){
          case 1:
            return <BusinessDetails />
          case 2:
            return <MyDetails />
          case 3:
            return <LinkedBankAccounts />
          default:
            return <PasswordReset />
      }
  }
  const isMobileView = useMediaQuery('(max-width: 767px)');
    return (
      <div className='admin_wrapper mob-bg'>
        <StatusBar />
        <div className="admin_wid_sec">
        <div className= "adminHeading">
          <Typography>ADMIN</Typography>
        </div>

        <Paper square elevation={0}>
          <Tabs
            value={activeTab}
            indicatorColor='primary'
            textColor='primary'
            onChange={(event, newValue) => setActiveTab(newValue)}
            variant= {isMobileView ? "scrollable" : "fullWidth"}
          >
            {tabAdmin.map((tab) => (
              <Tab value={tab.key} label={tab.label} key={tab.key}/>
            ))}
          </Tabs>
        </Paper>
        <Paper style={{ marginTop: 12, paddingBottom: 50 }} square elevation={0}>
          {renderTab()}
        </Paper>
        </div>
      </div>
    );
}
