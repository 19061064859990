import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    margin: '20px 30px',
  },
  containerMobile: {
    margin: 0,
    backgroundColor: '#eee'
  },
  formContainer: {
    width: '500px',
    height: '300px',
  },
  formContainerModal: {
    // width: '500px',
    // height: 'fit-content',
    width: '100%'
  },
  formContainerUpdate: {
    // width: '500px',
    // height: '220px',
    width: '100%'
  },
  formContainerTabletView: {
    //width: 'fit-content',
    //height: 'fit-content',
    width: '100%'
  },
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: '100px',
    paddingLeft: '100px',
  },
  fieldsContainerTabletView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  formDialog: {
    height: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogTitle: {
    fontFamily: 'PlusJakartaSans-Bold !important',
    fontSize: '20px important',
  },
  confirmation: {
    minWidth: '300px',
    height: '130px',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  surity: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  confirmButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  aisles: {
    marginLeft: '20px',
    marginRight: '20px',
    padding: '0 16px',
  },
  aislesMobile: {
    margin: 0
  },
  devices: {
    marginLeft: '20px',
    marginRight: '20px',
    padding: '0 16px 40px 16px',
  },
  devicesMobile: {
    margin: 0,
    paddingBottom: '40px',
  },
  divideMobileView: {
    margin: '0 20px',
    padding: '16px 0 30px 0',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem'
  },
  divideTabletView: {
    margin: '0 20px',
    padding: '30px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '2rem'
  },
  breakPoint: {
    backgroundColor: '#e9eee4ad',
    height: '4px',
    marginLeft: '10px',
    marginRight: '10px',
    // marginBottom: '20px',
  },
  imageContainer: {
    width: '70px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10
  },
  tableData: {
    fontFamily: 'PlusJakartaSans-Regular !important',
    fontSize: '15px important',
  },
  addStoreBtn: {
    height: '42px',
    width: '110px',
    textTransform: 'unset',
    fontSize: 14,
    borderRadius: '2px',
  },
  addStoreBtnMobile: {
    height: '40px',
    width: '100px',
    textTransform: 'unset',
    fontSize: 14,
    padding: 0
  },
  mainHeading: {
    // fontSize: '1.2rem !important',
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0.5,
  },
  mainHeadingMobile: {
    fontSize: '1rem !important',
  },
  storeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '100%',
    width: '80%'
  },
  storeContainerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%'
  },
  storeStatusMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  storeStatus: {
    // marginRight: '70px',
    // marginLeft: '5px',
    display: 'flex',
    justifyContent: 'center',
    // flexBasis: 0,
    // flexGrow: 0.15,
    width: '20%'
  },
  aislePartition: {
    display: 'flex',
    marginLeft: '40px',
    marginRight: '135px',
    marginBottom: '20px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  devicePartition: {
    display: 'flex',
    marginLeft: '40px',
    marginRight: '120px',
    marginBottom: '20px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  aislePartitionTabletView: {
    display: 'flex',
    marginLeft: '40px',
    marginRight: '115px',
    marginBottom: '20px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  devicePartitionTabletView: {
    display: 'flex',
    marginLeft: '40px',
    marginRight: '105px',
    marginBottom: '20px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  aisleUsers: {
    fontFamily: 'PlusJakartaSans-Bold !important',
    fontSize: '16px !important',
  },
  storeDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    flexBasis: 0,
    flexGrow: 1
  },
  marginRightTen: {
    marginRight: 10
  },
  serial: {
    width: '50px',
  },
  editStore: {
    marginLeft: '15px',
    width: '14px',
  },
  editStoreTabletView: {
    marginLeft: '10px',
    width: '15px',
  },
  addDevice: {
    width: '16px',
    marginRight: 7,
  },
  addAisleUser: {
    marginRight: '7px',
    width: '16px',
  },
  headTableCell: {
    borderTop: '0.5px solid #ccc',
    backgroundColor: '#f4f9ff',
  },
  tableHead: {
    fontFamily: 'PlusJakartaSans-Bold !important',
    fontSize: '14px !important',
    color: '#0E0E0E',
  },
  enableTxt: {
    width: '25%'
  },
  createStore: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '10px',
  },
  link: {
    textDecoration: 'none',
    fontFamily: 'PlusJakartaSans-Bold',
    fontSize: '16px',
    //width: '140px',
    color: '#1a7cfa',
    textTransform: 'none'
  },
  addIcon: {
    color: '#1a7cfa',
    height: 'inherit',
    marginRight: '5px',
  },
  createIcon: {
    height: 'inherit',
  },
  aisleDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  aisleDetailsTablet: {
    flexDirection: 'column',
  },
  textCaptilize : {
    textTransform: 'capitalize',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  outsideContent: {
    marginBottom: '20px',
    marginRight: '25px',
    marginLeft: '15px',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  outsideContentMobile: {
    margin: 0,
    padding: '1.5rem 1rem',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  deviceStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 200,
  },
  deviceStatusTablet: {
    flexDirection: 'column',
  },
  editDevice: {
    marginTop: 0,
  },
  editDeviceTablet: {
    marginTop: '10px',
  },
  linkStatus: {
    marginTop: 0,
  },
  linkStatusTablet: {
    marginTop: '10px',
  },
  storePaper: {
    margin: '0 10px 40px 10px',
  },
  storeName: {
    fontFamily: 'PlusJakartaSans-Bold !important',
    fontSize: '22px !important',
    textTransform: 'capitalize'
  },
  storeNameTabletView: {
    fontFamily: 'PlusJakartaSans-Bold !important',
    fontSize: '20px !important',
  },
  storeAddress: {
    fontFamily: 'PlusJakartaSans-Regular',
    fontSize: '12px',
    textTransform: 'capitalize'
  },
  fileUploadImg: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1rem",
  },
  fileUploadImgStore: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 8px",
  },
  uploadSec: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5rem",
  },
  changeButton: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0605059)',
    borderRadius: 2,
    fontSize: 16,
    textTransform: 'none'
  },
  jumnaBlue: {
    color: '#1A7CFA'
  },
  uploadText: {
    fontSize: 14,
    color: '#282828',
    opacity: 0.5,
    fontWeight: 400,
    fontFamily: "PlusJakartaSans-Regular"
  },
  imgPreviewText: {
    fontFamily: "PlusJakartaSans-Regular",
    fontSize: 16,
    marginLeft: 10,
    color: "#2F80ED",
  },
  uploadFileTxt: {
    fontFamily: 'PlusJakartaSans-Bold',
    fontSize: 11,
    color: '#656565',
    fontWeight: 400,
    letterSpacing: 0.5,
    fontStyle: 'italic',
    marginTop: 10
  },
  saveButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
  },
  saveButtonTxt: {
    width: 250,
    height: 42,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0605059)',
    borderRadius: 2,
    marginBottom: 30,
    textTransform: "none",
    fontSize: 16,
    fontFamily: "PlusJakartaSans-Regular",
    fontWeight: 500
  }
});
