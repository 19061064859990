import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getCreditNotes } from '../../store/actions/creditNotes';
import StatusBar from '../../controllers/statusBar';
import { formatDate, formatTime } from '../../utils/commonFunc';
import { useSelector } from 'react-redux';
import { Pagination } from "@material-ui/lab";
import { SET_PARAMETERS } from '../../store/types/creditNotes';

const headers = [
  'No.',
  'Store No.',
  'TRN No.',
  'Device',
  'App User',
  'Consumer',
  'City',
  'Date',
  'Time',
  'TRN Type',
  'Gross Amt.',
  'Tip',
  'Net Amt.',
  'Status'
];

function CreditNotes(props) {
  const dispatch = useDispatch();
  const parameters = useSelector((state) => state.parameters);

  const transactionList = useSelector((state) => state.transaction);

  useEffect(() => {
    const fetch = async () => {
      await props.getCreditNotes(parameters);
    };
    fetch();
  }, [parameters?.page]);
  return (
    <>
      <StatusBar />
      <div className="creditContainer">
        <div className='headerText'>TRANSACTION RECORD</div>
        <Paper sqare className='transactionTable' elevation={0}>
          <TableContainer component={Paper} className="tableWidth" style={{position: 'relative'}}>
            <Table>
              <TableHead style={{position: 'sticky', top: -1, zIndex: 99}}>
                <TableRow className="textBold">
                  {headers.map((header, index) => (
                    <TableCell>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{transactionList?.docs?.map((row, i) => _getTableBody(row, i, parameters))}</TableBody>
            </Table>
            {(transactionList?.docs?.length === 0) &&<div 
              className='noTransText'
            >
              No Transaction History
            </div>}
          </TableContainer>
        </Paper>
        <div className="paginationSec">
            <Pagination count={transactionList?.pages} shape="rounded" defaultPage={parameters?.page} onChange={(event, value) => {
                    dispatch({ type: SET_PARAMETERS, body: { page: value } });
                  }} />
        </div>
      </div>
    </>
  );
  function _getTableBody(row = {}, index, parameters = {}) {
    const {
      appUser = {}, date = '', device = '', grossAmount = '', netAmount = '', store = {}, tip = '', transactionNumber = '', transactionType = '', amountStatus = '',
      customerName = ''
    } = row;
    const { firstName = '', lastName = '' } = appUser;
    const { storeNo = '', address: { city = '' } } = store;
    return (
      <TableRow key={transactionNumber}>
        <TableCell component="th" scope="row">
           {parameters.limit * (parameters.page - 1) + (index + 1)}
        </TableCell>
        <TableCell>{storeNo}</TableCell>
        <TableCell>{transactionNumber}</TableCell>
        <TableCell>{device}</TableCell>
        <TableCell>{`${firstName} ${lastName}`}</TableCell>
        <TableCell>{customerName}</TableCell>
        <TableCell>{city}</TableCell>
        <TableCell>{formatDate(date)}</TableCell>
        <TableCell>{formatTime(date)}</TableCell>
        <TableCell>{transactionType}</TableCell>
        <TableCell>{grossAmount}</TableCell>
        <TableCell>{tip}</TableCell>
        <TableCell>{netAmount}</TableCell>
        <TableCell>{amountStatus}</TableCell>

      </TableRow>
    );
  }

}

export default connect(null, { getCreditNotes })(CreditNotes);
