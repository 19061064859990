import { AppBar, Button, Divider, Link, List, ListItem, Menu, MenuItem, Toolbar } from "@material-ui/core";
import React, { useState } from "react";
// import { BUTTON_GREEN_COLOR } from "../constants";
import { COLORS, FONTS } from "../../constants";
import "../../App.css";
import { connect } from "react-redux";
import {
    onLogout,
    scrollToView,
    removeScrollToView,
} from "../../store/actions/auth";
import { useHistory, useLocation } from "react-router-dom";

const navItem = {
    color: COLORS.juma_theme_clr,
    fontSize: FONTS.size_medium,
    margin: "0 15px",
    cursor: "pointer",
};

const menuItem = {
    color: '#1a7cfa',
    fontFamily: 'PlusJakartaSans-Bold',
    fontSize: '12px',
    padding: '8px 40px'
}

const SUPPORT_BASE_URL = process.env.REACT_APP_SUPPORT_BASE_URL || "https://support-dev.jumna.com"
const ADMIN_DASHBOARD_BASE_URL = process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL

function HeaderLanding({
    header = "Jumna",
    token,
    handleAboutUs = () => null,
    ...props
}) {
    const [show, showOverLay] = useState(false);
    const history = useHistory();
    const { pathname } = useLocation();
    const [openLoginMenu, setOpenLoginMenu] = useState(false)
    const isTabSelected = (path) => {
        return pathname === path ? "header_active" : "";
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl)
    return (
        <div>
            <Menu
                id="login-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": 'login-btn'
                }}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                style={{
                    marginTop: '10px',
                }}
            >
                <MenuItem style={menuItem} onClick={() => history.push("/merchant-signin")}>Merchant</MenuItem>
                <Divider style={{
                    width: '90%', margin: '0 auto', background: '#333F48',
                    opacity: 0.2
                }} />
                <Link href={`${ADMIN_DASHBOARD_BASE_URL}`} target="_blank" style={{
                    textDecoration: 'none',
                    color: 'unset'
                }}>
                    <MenuItem style={menuItem} onClick={handleClose}>Lender</MenuItem>
                </Link>
            </Menu>
            <AppBar position="fixed" elevation={0} className="headerPadding">
                <div className="max-width">
                    <Toolbar className="headerSec">
                        <img
                            src="/images/unplank.svg"
                            onClick={() => {
                                props.removeScrollToView();
                                history.push("/");
                            }}
                            alt="website logo"
                            className="cursor"
                        />
                        <div className="webHeader">
                            {token ? (
                                <p
                                    onClick={() => props.onLogout()}
                                    className="fontBold"
                                    style={navItem}
                                >
                                    Logout
                                </p>
                            ) : (
                                <div className="headerNav">
                                    <div className="headerLeft">
                                        <p
                                            className={isTabSelected("/")}
                                            onClick={() => {
                                                props.removeScrollToView();
                                                history.push("/");
                                            }}
                                        >
                                            Home
                                        </p>
                                        <p
                                            className={isTabSelected("/shop-with-unplank")}
                                            onClick={() =>
                                                history.push("/shop-with-unplank")
                                            }
                                        >
                                            Shop with Unplank
                                        </p>
                                        {/* <p
                                            className={isTabSelected(
                                                "/merchant"
                                            )}
                                            onClick={() =>
                                                history.push("/merchant")
                                            }
                                        >
                                            Merchants
                                        </p>
                                        <p
                                            className={isTabSelected(
                                                "/lenders"
                                            )}
                                            onClick={() =>
                                                history.push("lenders")
                                            }
                                        >
                                            Lenders
                                        </p> */}
                                        <p onClick={handleAboutUs}>About us</p>
                                        <p><a href={SUPPORT_BASE_URL} target="_blank">Support</a></p>
                                    </div>
                                    <div className="headerRight">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className="btnWidth"
                                            style={{ marginRight: 20 }}
                                            onClick={() => {
                                                props.scrollToView();
                                                history.push("/new-merchant-signup")
                                                // history.push("/merchant-signup");
                                            }}
                                        >
                                            Sign-up
                                        </Button>

                                        <Button
                                            id="login-btn"
                                            variant="contained"
                                            color="primary"
                                            className="btnWidth"
                                            // onClick={() =>
                                            //     history.push("/merchant-signin")
                                            // }
                                            onClick={handleClick}
                                            aria-controls={open ? 'login-menu' : undefined}
                                            aria-haspopup='true'
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            Sign-in
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="mobileHeader">
                            <img
                                alt="toggle menu"
                                onClick={() => showOverLay(!show)}
                                src={
                                    show
                                        ? "/images/close_menu.svg"
                                        : "/images/blue_menu_icon.png"
                                }
                                style={{ height: "25px" }}
                            />

                            {/* Show hide this section for mobile menu */}
                            {show && (
                                <div className="menuOverlay">
                                    <div className="menuNavItems">
                                        <div className="margin_bottom_40">
                                            <img
                                                onClick={() =>
                                                    showOverLay(!show)
                                                }
                                                src={
                                                    show
                                                        ? "/images/close_white_icon_small.svg"
                                                        : ""
                                                }
                                                alt="close"
                                                className="closeImg"
                                            />
                                        </div>
                                        <p
                                            className={`mobileMenuItem ${isTabSelected(
                                                "/"
                                            )}`}
                                            onClick={() => {
                                                props.removeScrollToView();
                                                showOverLay(!show);
                                                history.push("/");
                                            }}
                                        >
                                            Home
                                        </p>

                                        {/* <p
                                            className={`mobileMenuItem ${isTabSelected(
                                                "/merchant"
                                            )}`}
                                            onClick={() => {
                                                showOverLay(!show);
                                                history.push("merchant");
                                            }}
                                        >
                                            Merchant
                                        </p> */}
                                        <p
                                            className={`mobileMenuItem ${isTabSelected(
                                                "/shop-with-unplank"
                                            )}`}
                                            onClick={() => {
                                                showOverLay(!show);
                                                history.push("shop-with-unplank");
                                            }}
                                        >
                                            Shop with Unplank
                                        </p>
                                        {/* <p
                                            className={`mobileMenuItem ${isTabSelected(
                                                "/lenders"
                                            )}`}
                                            onClick={() => {
                                                showOverLay(!show);
                                                history.push("lenders");
                                            }}
                                        >
                                            Lenders
                                        </p> */}
                                        <p
                                            className="mobileMenuItem"
                                            onClick={() => {
                                                handleAboutUs();
                                                showOverLay(!show);
                                            }}
                                        >
                                            About us
                                        </p>

                                        <p
                                            className="mobileMenuItem"
                                            onClick={() => {
                                                props.scrollToView();
                                                showOverLay(!show);
                                                history.push("/new-merchant-signup");
                                            }}
                                        >
                                            Sign-up
                                        </p>
                                        <p
                                            className="mobileMenuItem"
                                            onClick={() =>
                                                setOpenLoginMenu(openLoginMenu => !openLoginMenu)
                                            }
                                        >
                                            Sign-in
                                            {openLoginMenu && <List>
                                                <ListItem onClick={() => history.push("/merchant-signin")}>Merchant</ListItem>
                                                <Link href={`${ADMIN_DASHBOARD_BASE_URL}`} target="_blank" style={{
                                                    textDecoration: 'none',
                                                    color: 'unset'
                                                }}>
                                                    <ListItem>Lender</ListItem>
                                                </Link>
                                            </List>}
                                        </p>
                                        <p className="mobileMenuItem">
                                            <a href={SUPPORT_BASE_URL} target="_blank">Support</a>
                                        </p>

                                        {/* {token ? (
                      SIDENAV_ITEMS.map(
                        ({ activeImage, label, keyRoute }) => (
                          <div
                            className="mobile_side_nav_item"
                            onClick={() => {
                              if (label === "Logout") {
                                props.onLogout();
                                return;
                              }
                              showOverLay(false);
                              props.history.push(`${path}${keyRoute}`);
                            }}
                            key={label}
                            style={{
                              ...eachNavItem,
                              ...activeNavItem,
                              borderColor: "white",
                              backgroundColor: "white",
                            }}
                          >
                            <img alt="navigation icon" style={{ width: 18 }} src={activeImage} />
                            <p style={navText}>{label}</p>
                          </div>
                        )
                      )
                    ) : (
                        <p
                            onClick={() => props.history.push("/login")}
                            className="fontBold mobileMenuItem"
                        >
                            Business Sign-In
                        </p>
                    )} */}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Toolbar>
                </div>
            </AppBar>
        </div>
    );
}

export default connect(
    ({ auth }) => {
        const { token } = auth?.userDetails || {};
        return { token };
    },
    { onLogout, scrollToView, removeScrollToView }
)(HeaderLanding);
