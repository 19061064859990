import React, { useEffect, useState } from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    IconButton,
} from "@material-ui/core";
import { FilePond, registerPlugin } from "react-filepond";
import { FileStatus } from 'filepond';
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { setDefaultBank, uploadBankDocument } from "../../store/actions/bankRegistration";
import { useDispatch, useSelector } from "react-redux";
import { getBusiness } from "../../store/actions/rewards";
import { uploadFile } from "../../store/actions/activateMerchantApp";
import { DOCUMENT_TYPES, DISABLE_LINK_ACCOUNT_BTN } from "../../constants";
import { formatDate } from "../../utils/commonFunc";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../store/api.middleware";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


const headers = [
    'Type',
    'Account Number',
    'Routing Number',
    'Linked Account',
  ];


const documentTableHeaders = [
    'No.',
    'Document Name',
    'Upload Date',
  ];




export default function LinkedBankAccounts() {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [files, setFiles] = useState([]);
    const [value, setValue] = useState([]);

    const { businessData, userDetails } = useSelector((state) => state.auth);
    const id = businessData?.business?.id
    const { accounts = {}, isDocumentsRequested = false, status = '', bankDocuments = [], primaryAccount = null  } = businessData?.bankRegistration
    const {providerName = ''} = accounts
    const [childWindow, setChildWindow] = useState(null)
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

    const openDocumentUploadDialog = () => {
        setOpen(true);
    };

    const closeDocumentUploadDialog = () => {
        setFiles([])
        setValue([])
        setOpen(false);
    };

    const [openLink, setOpenLink] = React.useState(false);

    const handleClickOpen = () => {
        setOpenLink(true);
    };

    const handleClose = () => {
        setOpenLink(false);
    };
    const handleClickDirectIdOpen = (e) => {
        let h = 600;
        let w = 600;
        const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
        const childWindow = window.open(
            `https://connect.direct.id/?client_id=${CLIENT_ID}&config_id=merchants&customer_ref=${id}`,
            'popup', `width=${w},height=${h},top=${y},left=${x}`
        );
        handleClose()
        setChildWindow(childWindow)
    };


    useEffect(() => {
        const intervalId = setInterval(async function () {
            if (childWindow?.closed) {
                await dispatch(getBusiness())
                setChildWindow(null)
                clearInterval(intervalId)
            }
        }, 1000);
        return () => {
            if (intervalId) { clearInterval(intervalId) }
        }
    }, [childWindow]);



    useEffect(() => {
        setValue([
            ...value,
            ...files
                .filter((file) => file.status === FileStatus.PROCESSING_COMPLETE)
                .map((file) => file.serverId),
        ]);
        if (files.filter((file) => file.status === FileStatus.PROCESSING_COMPLETE).length) {
            setFiles(files.filter((file) => file.status !== FileStatus.PROCESSING_COMPLETE));
        }
    }, [files]);

    const uploadDocument = async (e) => {
      e.preventDefault()
      if(files.length === 0 && value.length !== 0){
      await dispatch(uploadBankDocument(value))
      setValue([])
      await dispatch(getBusiness())
      closeDocumentUploadDialog()
      }
    }

    return (
        <div>
            {/* image for linked bank account */}
            {/* <div className="linkedAccountSec">
                <img src="/images/declined_user.svg" alt="img"/>
            </div>
            <div className="accountTextWrapper margin_bottom_40">
                <img src="/images/info_icon.png" alt="info icon" className="infoImgWidth"/>
                <p className="declineTxt">No Bank Linked to this Account.</p>
            </div> */}
            <div className="linkedMultiAccountMain">
                <div className="linkedAccountHeader">
                    <Typography>Linked Bank: {providerName}</Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleClickOpen}
                        disabled={(DISABLE_LINK_ACCOUNT_BTN || businessData?.status === 'verification-under-way') ? true : false}
                    >
                        Link Bank Account
                    </Button>
                </div>
                <div>
                    <TableContainer className="multiAccountTable">
                        <Table>
                            <TableHead>
                                <TableRow className="textBold">
                                    {headers.map((header, index) => (
                                        <TableCell>
                                            {header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {(status === 'approved') && <TableBody>{accounts?.accounts?.map((row, i) => _getAccountTableBody(row, i, providerName, primaryAccount))}</TableBody>}
                        </Table>
                        {(accounts?.accounts?.length === 0 || Object.keys(accounts).length === 0) && <div
                            className='noTransText'
                        >
                            No Linked Bank Account Details.
                        </div>}
                    </TableContainer>
                </div>
            </div>
            <div className="linkedMultiAccountMain">
                <div className="linkedAccountHeader">
                    <Typography>Documents</Typography>
                    <Button
                        color= "primary"
                        variant= "outlined"
                        onClick={openDocumentUploadDialog}
                        // disabled={isDocumentsRequested ? (status === 'approved' || status === 'rejected') ? true : false : true}
                        // disabled={businessData?.status === 'verification-under-way' ? false : true}
                    >
                        Upload Documents
                    </Button>
                </div>
                <div>
                    <TableContainer className="multiAccountTable">
                        <Table>
                            <TableHead>
                                <TableRow className="textBold">
                                    {documentTableHeaders.map((header, index) => (
                                        <TableCell>
                                            {header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>{bankDocuments?.map((row, i) => _getTableBody(row, i))}</TableBody>
                        </Table>
                        {(bankDocuments?.length === 0) && <div
                            className='noTransText'
                        >
                            No Uploaded Documents.
                        </div>}
                    </TableContainer>
                </div>
            </div>

            {/* Dialog for Link Account Direct ID */}
            <Dialog
                open={openLink}
                onClose={handleClose}
                className="linkDirectIDPopUp"
                fullWidth={true}
                maxWidth={"sm"}
            >
                <Box className="linkPopUpHeader">
                    <img 
                        src="/images/unplank_logo_black.png"
                        alt="unplank logo"
                        className="unplankLogoPopup"
                    />
                    <img
                        src="/images/cross_icon.svg"
                        alt="close"
                        onClick={handleClose}
                        className="cursor margin_rigth_20"
                        style={{width: 15}}
                    />
                </Box>
                <DialogTitle>
                    Link Bank Account
                </DialogTitle>
                <DialogContent>
                    <img 
                        src="/images/link_grey.svg" 
                        alt="link account"
                        className="linkImg"
                    />
                    <Typography>We use DirectID to connect with your bank to:</Typography>
                    <Box className="linkTypes">
                        <Box className="linkSec">
                            <img src="/images/link.svg" alt="link"/>
                            <Typography>Secure Account Details</Typography>
                        </Box>
                        <Box className="linkSec">
                            <img src="/images/link.svg" alt="link"/>
                            <Typography>Authenticate identity</Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleClickDirectIdOpen}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dailog for Upload Documents */}
            <Dialog
                open={open}
                onClose={closeDocumentUploadDialog}
                className="linkBankPopUp"
                fullWidth={true}
                maxWidth={"sm"}
            >
                <Box className="close_img">
                    <img
                        src="/images/close_menu.svg"
                        alt="close"
                        onClick={closeDocumentUploadDialog}
                    />
                </Box>
                <DialogTitle>
                    UPLOAD FILES TO ATTACH
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Box className="browseSec">
                            <Box className="browseWrapper">
                                <FilePond
                                    credits={false}
                                    storeAsFile={false}

                                    files={files}
                                    allowMultiple={true}
                                    onupdatefiles={setFiles}
                                    name="file"
                                    onprocessfile={(error, file) => {
                                        if (error) {
                                            console.log('error', error);
                                        }
                                        setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
                                    }}
                                    server={{
                                        url: BASE_URL,
                                        process: {
                                            url: `/files/files`,
                                            headers: {
                                                Authorization: `Bearer ${userDetails.token}`,
                                            },
                                            onload: (response) => {
                                                const { id, name, url } = JSON.parse(response);
                                                return { id, name, url };
                                            },
                                        },

                                        revert: null,
                                        restore: null,
                                        fetch: null,
                                    }}
                                    allowFileTypeValidation={true}
                                    acceptedFileTypes={DOCUMENT_TYPES}
                                    labelFileTypeNotAllowed={'File of invalid type'}
                                    allowFileSizeValidation={true}
                                    maxFileSize={'2MB'}
                                />

                                {value.map((fileObject) => (
                                    <Box
                                        key={fileObject.id}
                                        direction="row"
                                        spacing={1}
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                        className="browseTxt"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >

                                        <img
                                            src="/images/document_icon.svg"
                                            alt="doc"
                                            style={{width: 14}}
                                        />
                                        <Link
                                            href={fileObject.url}
                                        >
                                            {fileObject.name}
                                        </Link>
                                        <IconButton
                                            onClick={() => {
                                                setValue(value.filter((file) => file.id !== fileObject.id));
                                            }}
                                        >
                                            <img src={'/images/delete_icon.svg'} alt="Delete File" />
                                        </IconButton>
                                    </Box>
                                ))}

                            </Box>
                        </Box>
                        <Typography>Click browse files to upload an image from computer here</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ marginRight: 10 }}
                        onClick={uploadDocument}
                    >
                        Submit
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={closeDocumentUploadDialog}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


        </div>
    );
}



function _getAccountTableBody(row = {}, index, providerName, primaryAccount) {
    const dispatch = useDispatch();
    const {accountId = '', displayName = ''} = row;
    const {
        accountNumber = '', bankCode = ''
    } = row?.identifiers;

    return (
      <TableRow 
        key={accountId}
        className={primaryAccount === accountNumber ? 'primaryTxt' : 'setAsPrimaryTxt'}
      >

        {/* <TableCell>{providerName}</TableCell> */}
        <TableCell>{displayName}</TableCell>
        <TableCell>{accountNumber}</TableCell>
        <TableCell>{bankCode || `---`}</TableCell>
        <TableCell component="th" scope="row">
          {primaryAccount === accountNumber ? <Typography>Primary</Typography> :
          <Button variant="text" onClick={async () => {
            await dispatch(setDefaultBank(accountNumber))
            await dispatch(getBusiness())
            }}>Set as Primary</Button>}
        </TableCell>

      </TableRow>
    );
  }


  function _getTableBody(row = {}, index) {
    const {
        name = '', id = ''
    } = row;

    return (
      <TableRow key={id} className="uploadDocTableTxt">
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{formatDate(row?.createdAt) || `---`}</TableCell>
      </TableRow>
    );
  }
