import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    Typography,
} from "@material-ui/core";
import React from "react";
import StatusBar from "../controllers/statusBar";

export default function Faq() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>  
        <StatusBar />
            <div className="faq_section">
                <div className="faq_detail_sec">
                    <h2>FAQ</h2>
                    <Paper className="faq_papper_sec" square elevation={0}>
                        <h3>Initial Set-up</h3>

                        <div className="faq_accordion_sec">
                            <Accordion
                                expanded={expanded === "panel1"}
                                onChange={handleChange("panel1")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        1. I’ve just created an account and
                                        landed on the dashboard. What should I
                                        do next?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Go to the Manage Storefront tab and
                                        follow the steps below: <br />
                                        i. Add a store by setting location and
                                        uploading a store image. <br />
                                        ii. Add device and label it with an
                                        appropriate title that you would like
                                        customers to see. For example, checkout
                                        or counter 1 etc.
                                        <br />
                                        iii. Authorise any other users to access
                                        the app. <br />
                                        iv. Download the Unplank app on device
                                        of choice, using your manager web login
                                        or credentials sent to authorised users.
                                        <br /> v. Link device at first login,
                                        using the token generated
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel2"}
                                onChange={handleChange("panel2")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        2. When will I start getting customers
                                        on Unplank?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        We are progressively launching across
                                        different cities in all 50 states
                                        starting mid-July and are focused on
                                        ramping merchants in the first 4-8
                                        weeks. You can expect to accept
                                        customers from just after Labor day.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel3"}
                                onChange={handleChange("panel3")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        3. Why does Unplank need to verify our
                                        bank account?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        To protect you from fraud, we need to
                                        ascertain that the bank account we are
                                        sending your funds to, is indeed in your
                                        name and not in the name of another
                                        business entity.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel4"}
                                onChange={handleChange("panel4")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        4. How does Unplank verify our bank
                                        account?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        We use a secure open-banking plug-in
                                        provided by our partner Direct ID to
                                        check the name of the account holder,
                                        account number and routing details.
                                        Neither we, nor Direct ID is able to see
                                        your login credentials even while we are
                                        able to electronically and securely
                                        verify your ownership of the bank
                                        account, we will be sending your funds
                                        to.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel5"}
                                onChange={handleChange("panel5")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel5bh-content"
                                    id="panel5bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        5. Do I have to set up rewards in order
                                        for Unplank to send me customers?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        It is not mandatory. You may, by all
                                        means, keep your Unplank savings to
                                        yourself.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel6"}
                                onChange={handleChange("panel6")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel6bh-content"
                                    id="panel6bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        6. How do you tell a customer that we
                                        are on Unplank?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Our algorithms will highlight your
                                        presence on Unplank to our users,
                                        in-store or within accessible range.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel7"}
                                onChange={handleChange("panel7")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel7bh-content"
                                    id="panel7bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        7. What is Unplank?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        It’s a frictionless point-of-sale app
                                        interface that allows customers,
                                        merchants and lenders to engage and
                                        transact.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel8"}
                                onChange={handleChange("panel8")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel8bh-content"
                                    id="panel8bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        8. Is Unplank a bank?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        No, it isn’t.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel9"}
                                onChange={handleChange("panel9")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel9bh-content"
                                    id="panel9bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        9. Is Unplank actually free to use for
                                        merchants?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Yes, it is. The intention of creating
                                        savings on interchange and platform fees
                                        is to free up margin so it can
                                        potentially be used to directly acquire
                                        customers via rewards.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel10"}
                                onChange={handleChange("panel10")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel10bh-content"
                                    id="panel10bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        10. Why would we need to set-up our
                                        location in-app?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        If you are a vendor on-the-move, you may
                                        want to change your location as you move
                                        from place to place. Your customer will
                                        however, always see your registered
                                        business address.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel11"}
                                onChange={handleChange("panel11")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel11bh-content"
                                    id="panel11bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        11. Should we let our customers know
                                        that we are on Unplank?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Yes, please.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel12"}
                                onChange={handleChange("panel12")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel12bh-content"
                                    id="panel12bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        12. How can we let customers know that
                                        we are on Unplank?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        We will shortly make stickers available
                                        that you can stick on your door or at
                                        point-of-sale.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel13"}
                                onChange={handleChange("panel13")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel13bh-content"
                                    id="panel13bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        13. We are doing business in a name
                                        other than that of our legal entity. Is
                                        that okay?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Yes, it is. We have means of checking
                                        alternate names under which you may be
                                        doing business.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">Chat</h3>
                            <Accordion
                                expanded={expanded === "panel14"}
                                onChange={handleChange("panel14")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel14bh-content"
                                    id="panel14bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        14. What is Unplank chat?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        It’s an encrypted channel through which
                                        you may communicate with customers and
                                        push payment requests to them to
                                        complete your commitment for sale.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel15"}
                                onChange={handleChange("panel15")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel15bh-content"
                                    id="panel15bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        15. Why is it restricted to only one
                                        device?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        At first instance, we want to ensure
                                        that all chats are picked up by our
                                        merchants. Restricting it to one device,
                                        makes it easier for you to dedicate a
                                        specific resource to order-taking and
                                        responding to customer requests.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel16"}
                                onChange={handleChange("panel16")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel16bh-content"
                                    id="panel16bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        16. Why can I only respond in a 24-hour
                                        window?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        This is to ensure that chat is used by
                                        customers for queries and orders as
                                        opposed to delayed marketing by
                                        merchants.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel17"}
                                onChange={handleChange("panel17")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel17bh-content"
                                    id="panel17bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        17. What happens to chats after the
                                        24-hour window?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        The chats are archived. You may see them
                                        but can no longer actively communicate
                                        with the customer.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel18"}
                                onChange={handleChange("panel18")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel18bh-content"
                                    id="panel18bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        18. Can I message Unplank users?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        No, not as of now. You may respond only
                                        to chats initiated by customers.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel19"}
                                onChange={handleChange("panel19")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel19bh-content"
                                    id="panel19bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        19. Can I push payment requests to
                                        Unplank users?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Yes, you may push payment requests via
                                        chat.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">
                                Customer Acquisition
                            </h3>
                            <Accordion
                                expanded={expanded === "panel20"}
                                onChange={handleChange("panel20")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel20bh-content"
                                    id="panel20bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        20. What is a customer acquisition
                                        strategy on Unplank?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Merchants may acquire customers by
                                        setting different incentives that
                                        kick-in at certain spend thresholds.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel21"}
                                onChange={handleChange("panel21")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel21bh-content"
                                    id="panel21bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        21. How can I tailor a customer
                                        acquisition strategy to suit my
                                        business?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        One-off time-limited, exclusive
                                        discounts work well to get a new
                                        customer who has not shopped at your
                                        store before. A discount that kicks-in
                                        at a certain spend threshold within a
                                        certain time window, encourages
                                        customers to give you a larger share of
                                        their wallet, over one or several
                                        visits, cumulatively.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel22"}
                                onChange={handleChange("panel22")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel22bh-content"
                                    id="panel22bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        22. How do I decide which customer
                                        acquisition strategy to use?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Feel free to experiment. If you already
                                        have a good customer base, then try to
                                        set a reward that aims at getting a
                                        larger share of wallet. If you don’t
                                        have enough footfall, then offer one-off
                                        rebates or set an attractive reward for
                                        significant cumulative spend over a
                                        longer time window. This will encourage
                                        the customer to come repeatedly to your
                                        store, with small spend amounts adding
                                        up over several months to the
                                        significant reward worth having.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">
                                Data Privacy
                            </h3>
                            <Accordion
                                expanded={expanded === "panel23"}
                                onChange={handleChange("panel23")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel23bh-content"
                                    id="panel23bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        23. Are you selling our customer
                                        transaction data to a third party?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        No, we are not.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel24"}
                                onChange={handleChange("panel24")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel24bh-content"
                                    id="panel24bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        24. Are you using our transaction data
                                        to send our customers offers from
                                        competitor firms?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        No, we are not.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">
                                Device Set-up
                            </h3>
                            <Accordion
                                expanded={expanded === "panel25"}
                                onChange={handleChange("panel25")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel25bh-content"
                                    id="panel25bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        25. Can we use multiple devices in one
                                        store to accept payment?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Yes, you can set up multiple devices in
                                        a store.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel26"}
                                onChange={handleChange("panel26")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel26bh-content"
                                    id="panel26bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        26. How do we set-up multiple devices in
                                        a store?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        You can add devices from the Manage
                                        Storefront tab on your dashboard.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel27"}
                                onChange={handleChange("panel27")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel27bh-content"
                                    id="panel27bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        27. Why would we need multiple devices
                                        in a store?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        If you have more than one point-of-sale
                                        within a store, you may need more than
                                        one device. Or you may use one device
                                        for chat/mobile ordering and use another
                                        for accepting payment.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">Disputes</h3>
                            <Accordion
                                expanded={expanded === "panel28"}
                                onChange={handleChange("panel28")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel28bh-content"
                                    id="panel28bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        28. Are they any chargebacks?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        All payments are final. There are no
                                        chargebacks. However, we do reserve the
                                        right to reverse a transaction if we
                                        find evidence or suspect an attempt to
                                        defraud a customer via our platform.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel29"}
                                onChange={handleChange("panel29")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel29bh-content"
                                    id="panel29bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        29. What happens if a customer disputes
                                        a payment?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        All disputes must be resolved between a
                                        customer and merchant, in person or via
                                        chat.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel30"}
                                onChange={handleChange("panel30")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel30bh-content"
                                    id="panel30bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        30. How will Unplank resolve a customer
                                        complaint or dispute between a merchant
                                        and a customer?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        All payments are possible only upon
                                        explicit approval by use of password by
                                        a customer from their registered phone
                                        device and are therefore final. All
                                        disputes must be resolved between
                                        merchants and customers.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">
                                Merchant App
                            </h3>
                            <Accordion
                                expanded={expanded === "panel31"}
                                onChange={handleChange("panel31")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel31bh-content"
                                    id="panel31bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        31. Can I authorise other employees to
                                        use the Unplank app at point-of-sale?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Yes, you may add users under the Manage
                                        Storefront tab on the dashboard.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel32"}
                                onChange={handleChange("panel32")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel32bh-content"
                                    id="panel32bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        32. Can I close my account on the web
                                        and just download the app instead?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        No, you cannot. You must have both.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel33"}
                                onChange={handleChange("panel33")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel33bh-content"
                                    id="panel33bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        33. What is auto-accept payment?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        You may opt to receive payments from the
                                        customer without pre-verifying that they
                                        have input the amount you asked for. You
                                        may do so, at your own risk.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel34"}
                                onChange={handleChange("panel34")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel34bh-content"
                                    id="panel34bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        34. How can I set my store location
                                        in-app?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Go to the Set Location menu option
                                        in-app. You can set the location using
                                        the pin on the map or type your address
                                        in the search bar.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">
                                Premium Enablement
                            </h3>
                            <Accordion
                                expanded={expanded === "panel35"}
                                onChange={handleChange("panel35")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel35bh-content"
                                    id="panel35bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        35. What is premium enablement?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Our intelligent store display algorithms
                                        will normally flag your store to
                                        customers when they are in your
                                        vicinity. With premium enablement, we
                                        make your store or promotion, slightly
                                        more prominent versus competing options,
                                        without breaching our privacy guidelines
                                        or risking customer fatigue.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel36"}
                                onChange={handleChange("panel36")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel36bh-content"
                                    id="panel36bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        36. Is there is a cost to premium
                                        enablement? If so, then Unplank is not
                                        free, is it?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Yes, there is a cost to premium
                                        enablement. We charge $.5 per
                                        transaction once you’ve opted for
                                        premium enablement. Think of it is as an
                                        advertising charge upon conversion. It
                                        is optional and you may use Unplank
                                        effectively, free of cost even without
                                        it.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">Refunds</h3>
                            <Accordion
                                expanded={expanded === "panel37"}
                                onChange={handleChange("panel37")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel37bh-content"
                                    id="panel37bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        37. How can I process refunds?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        You can process refunds via the app
                                        using a merchant transaction ID number
                                        that the customer communicates to you
                                        via chat.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel38"}
                                onChange={handleChange("panel38")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel38bh-content"
                                    id="panel38bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        38. What are the charges associated to
                                        processing refunds?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        You will be charged the interest accrued
                                        by the customer, should they have paid
                                        you with the use of credit from a
                                        lender. The charge will be processed as
                                        a refund fee.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">Settings</h3>
                            <Accordion
                                expanded={expanded === "panel39"}
                                onChange={handleChange("panel39")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel39bh-content"
                                    id="panel39bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        39. How can I change my password?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        You may change your password in the
                                        Admin tab on the dashboard. Or you may
                                        use the forgot password option at
                                        sign-in.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel40"}
                                onChange={handleChange("panel40")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel40bh-content"
                                    id="panel40bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        40. How do I change my linked bank
                                        account?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        You will find the requisite open-banking
                                        link in the Admin tab on the dashboard.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <h3 className="faq_detail_headings">
                                Store Set-up
                            </h3>
                            <Accordion
                                expanded={expanded === "panel41"}
                                onChange={handleChange("panel41")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel41bh-content"
                                    id="panel41bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        41. How do I create a store front on
                                        Unplank?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Go to the Manage Storefront tab and add
                                        store to set location and upload a store
                                        image.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel42"}
                                onChange={handleChange("panel42")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel42bh-content"
                                    id="panel42bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        42. What image should I use?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        Use an image that shows your store or
                                        flagship product in best light.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel43"}
                                onChange={handleChange("panel43")}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <img
                                            src="../../images/down_arrow.svg"
                                            alt="icon"
                                        />
                                    }
                                    aria-controls="panel43bh-content"
                                    id="panel43bh-header"
                                    className="pannel_header"
                                >
                                    <Typography className="faq_question_title">
                                        43. Why has my image been rejected?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="faq_answer">
                                        We reserve the right to reject images
                                        that are of poor quality or are socially
                                        inappropriate in any way.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Paper>
                </div>
            </div>
        </>
    );
}
