import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStore } from "../../store/actions/activateMerchantApp";
import { getBusiness } from "../../store/actions/rewards";
import { StoreFormHeader } from "./Common";
import StoreForm from "./store-form"

const initialValues = {
  storeType: 'multi-device',
  name: '',
  storeNo: '',
  logo: null,
  address: null,
  location: null
}

export default function CreateStore() {
  const dispatch = useDispatch();
  const history = useHistory();


  return (<><StoreFormHeader title="ADD STORE"></StoreFormHeader>
    <StoreForm initialValues={initialValues} type={"CREATE_STORE"} onSubmit={async (values, address) => {
      try {
        await dispatch(createStore({ ...values, address }));
        await dispatch(getBusiness())
        history.push("/portal/stores");
      } catch (error) {
        console.error(`${error}`);
      }
    }}></StoreForm></>)
}