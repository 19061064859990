import { LinearProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getStore, updateStore } from "../../store/actions/activateMerchantApp";
import { StoreFormHeader } from "./Common";
import StoreForm from "./store-form";

export default function UpdateStore() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const storeFromRedux = useSelector((state) => state.stores.find(store => store.id === id));
  const [store, setStore] = useState(storeFromRedux)
  const [showProgress, setShowProgress] = useState(!store);
  console.log(storeFromRedux, "redux")
  console.log(store, "react state")
  useEffect(() => {
    const fetch = async () => {
      try {
        setShowProgress(true);
        const s = await dispatch(getStore(id));
        setStore(s);
      } catch (error) {
        console.error("Could not load store details.", error);
      } finally {
        setShowProgress(false);
      }
    };
    if (!store) {
      fetch();
    }
  }, [ id, dispatch, store ]);

  return (<>{showProgress && <LinearProgress />}
    <StoreFormHeader title="EDIT STORE" />
    {store && <StoreForm initialValues={store} type={"UPDATE_STORE"} onSubmit={async (values, address) => {
      try {
        await dispatch(updateStore(id, { ...values, address }));
        history.push("/portal/stores");
      } catch (error) {
        console.error(`${error}`);
      }
    }}></StoreForm>}</>)
}