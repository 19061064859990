import { Dialog, DialogTitle, DialogActions, Button, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";

export default function DeleteDialog({
  id,
  closeDialog,
  deleteDiscount
}) {
  return (
    <>
      <Dialog
        open={!!id}
        onClose={closeDialog}
        className="conformPopUp"
      >
        <Box className="close_img">
          <img
            src="/images/close_menu.svg"
            alt="close"
            onClick={closeDialog}
          />
        </Box>
        <DialogTitle style={{padding: '40px 60px'}}>Are you sure you want to delete?</DialogTitle>
        <DialogActions style={{padding : '10px 60px 40px'}}>
          <Button 
            color="primary" 
            variant="contained" 
            fullWidth 
            onClick={() => {
                deleteDiscount();
                closeDialog();
            }}
            style={{marginRight: 10}}
          >
            Confirm
          </Button>
          <Button
            color="primary" 
            variant="outlined" 
            fullWidth
            onClick={closeDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
